import {
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Pause, Play } from 'iconsax-react';
import { useCallback } from 'react';
import { usePauseFlowAlertStateModal } from '~/lib/hooks/useModals/useComplexModals';
import { FCTVirtualPauseButton } from '../flow/FlowAlerts/FCTVirtualPauseButton';
import { useTranslations } from 'next-intl';

export const PauseFlowAlertModal = () => {
  const t = useTranslations();
  const {
    id,
    isOpen,
    close,
    props: { pauseState, isVirtualPausedExist },
  } = usePauseFlowAlertStateModal();

  const handleClose = useCallback(() => {
    close({});
  }, [close]);

  return (
    <Modal size="2xl" isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay backdropFilter="blur(4px)" bg="whiteAlpha.200" />
      <ModalContent
        rounded="24px"
        bg="#F9FAFB"
        _dark={{ bg: '#0F151A' }}
        p="60px"
        gap="24px"
        w="495px"
      >
        <ModalHeader p="0">
          <HStack justify="center">
            <Text fontSize="16px">{t('control-options')}</Text>
          </HStack>
        </ModalHeader>
        <ModalCloseButton m="16px" />
        <ModalBody fontSize="sm" px="0" color="gray.500">
          <Stack>
            {isVirtualPausedExist && (
              <FCTVirtualPauseButton
                id={id}
                state={pauseState.virtual}
                pauseIcon={
                  <Icon
                    as={Pause}
                    variant="Bulk"
                    boxSize="22px"
                    color="blue.500"
                  />
                }
                playIcon={
                  <Icon
                    as={Play}
                    variant="Bulk"
                    boxSize="22px"
                    color="blue.500"
                  />
                }
                onCloseModal={handleClose}
              />
            )}
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
