/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable complexity */
import {
  Button,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Tag,
  Text,
  Tooltip,
  useMergeRefs,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  BigIntOr0,
  useFlowPower,
  useTokenItem,
  useVaultActions,
} from '@kiroboio/fct-sdk';
import { BoxAdd, EmptyWalletAdd } from 'iconsax-react';
import type { FC, FormEvent } from 'react';
import { useCallback, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { object as yupObject, string as yupString } from 'yup';
// import { mixpanelAnalytics } from 'src/kirobo/utils/mixpanelAnalytics';

import { useTranslations } from 'next-intl';
import { FaGasPump } from 'react-icons/fa6';
import {
  flowPowerId,
  useAddFlowPowerStateModal,
  useFlowPowerModalActions,
} from '~/lib/hooks/useModals/useComplexModals';
import { etherToWei, weiToEther } from '~/lib/utils/number';

export const AddFlowPowerModal: FC = () => {
  const t = useTranslations();
  const {
    isOpen,
    close,
    props: { account },
  } = useAddFlowPowerStateModal();
  const { close: closeFlowPowerModal } = useFlowPowerModalActions(flowPowerId);
  const {
    raw: {
      // balance: { native: powerBalanceWei },
      missing: { native: powerMissingWei }, // gas will be replaced with native tomorrow
    },
    fmt: {
      // balance: { native: powerBalanceEtherFmt },
      missing: { native: powerMissingEtherFmt }, // gas will be replaced with native tomorrow
    },
  } = useFlowPower();
  const {
    flow: { addPower },
  } = useVaultActions();
  const { item: walletNative } = useTokenItem({ account: 'wallet' });
  const { item: vaultNative } = useTokenItem({ account: 'vault' });

  const symbol = walletNative?.raw.symbol || 'ETH';

  const powerMissingEther = weiToEther(powerMissingWei || '0');

  const walletBalanceWei = walletNative?.raw.balance || '0';
  const vaultBalanceWei = vaultNative?.raw.balance || '0';
  const walletBalanceEther = weiToEther(walletBalanceWei || '0');
  const vaultBalanceEther = weiToEther(vaultBalanceWei || '0');
  const walletBalanceEtherFmt = walletNative?.fmt.balance || '0';
  const vaultBalanceEtherFmt = vaultNative?.fmt.balance || '0';

  const validationSchema = yupObject().shape({
    amount: yupString()
      .required()
      .test(
        'is greater than zero',
        t('amount-must-be-a-number-greater-than-zero'),
        (value) => {
          return Number(value) > 0;
        }
      )
      .test(
        'is less than or equal vault balance',
        t('amount-cant-be-greater-than-balance'),
        (value) => {
          if (account !== 'vault') return true;
          return Number(vaultBalanceEther) >= Number(value);
        }
      )
      .test(
        'is less than or equal wallet balance',
        t('amount-cant-be-greater-than-balance'),
        (value) => {
          if (account !== 'wallet') return true;
          return Number(walletBalanceEther) >= Number(value);
        }
      ),
  });

  const {
    register,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      amount: '',
    },
  });

  const amount = watch('amount');

  const disabledSubmit = Boolean(errors && errors.amount) || amount === '';

  const handleClose = useCallback(() => {
    close({});
  }, [close]);

  const handleCloseFlowPowerModal = useCallback(() => {
    closeFlowPowerModal({});
  }, [closeFlowPowerModal]);

  function handleAddPower(e: FormEvent<HTMLFormElement>) {
    // mixpanelAnalytics.track({
    //   label: 'Add_FCT_Power',
    //   payload: {
    //     token_amount: `$${weiToUSD(amountInWei)}`,
    //     from_wallet: account,
    //   },
    // });
    e.preventDefault();
    const amountInWei = BigIntOr0(etherToWei(amount, 18));
    if (account === 'vault') {
      addPower.execute([
        { valueIn: BigIntOr0(0), value: amountInWei, inputs: {} },
      ]);
    } else {
      addPower.execute([
        { valueIn: amountInWei, value: amountInWei, inputs: {} },
      ]);
    }
    setValue('amount', '');
    handleClose();
    handleCloseFlowPowerModal();
  }

  const { ref: inputRef, ...registeredAmount } = register('amount');
  const initialFocusRef = useRef(null);
  const mergedRefs = useMergeRefs(initialFocusRef, inputRef);

  const balanceAmount =
    account === 'vault' ? vaultBalanceEther : walletBalanceEther;

  const minAmount = Math.min(
    parseInt(powerMissingEther || '0', 10),
    parseFloat(balanceAmount || '0')
  );

  const initialAmount = minAmount === 0 ? '' : minAmount.toString();

  useEffect(() => {
    setValue('amount', initialAmount);
    clearErrors('amount');
  }, [clearErrors, initialAmount, isOpen, setValue]);

  const showNeededTokenAlert = BigIntOr0(powerMissingWei) > BigIntOr0(0);

  return (
    <Modal
      initialFocusRef={initialFocusRef}
      size="2xl"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <ModalOverlay backdropFilter="blur(4px)" bg="whiteAlpha.200" />
      <ModalContent
        rounded="24px"
        bg="#F9FAFB"
        _dark={{ bg: '#0F151A' }}
        p="60px"
        gap="24px"
        w="495px"
      >
        <ModalHeader p="0">
          <HStack justify="center">
            {/* <Icon
              as={account === 'vault' ? BoxAdd : EmptyWalletAdd}
              variant="Linear"
              color={account === 'vault' ? 'purple.500' : 'blue.500'}
              boxSize="24px"
            /> */}
            <Text fontSize="16px">{t('adding-gas')}</Text>
          </HStack>
        </ModalHeader>
        <ModalCloseButton m="16px" />
        <ModalBody fontSize="sm" px="0" color="gray.500">
          <form onSubmit={handleAddPower}>
            <Stack gap="24px">
              {showNeededTokenAlert && (
                // <HStack gap="8px" spacing="0" justify="center">
                //   <Text fontSize="14px">You Need</Text>
                //   <Image alt="Add" src={FCTPowerIcon} width="14" height="14" />
                //   <Text fontSize="14px" fontWeight="700">
                //     +{powerMissingEtherFmt}
                //   </Text>
                // </HStack>
                <Stack gap="0" spacing="0" align="center">
                  <Text fontSize="14px">{t('you-need')}</Text>
                  <HStack gap="3px" spacing="0">
                    <Icon
                      as={FaGasPump}
                      color="black"
                      _dark={{ color: 'white' }}
                      boxSize="25px"
                    />
                    <Text color="red.400" fontSize="24px" fontWeight="700">
                      +{powerMissingEtherFmt}
                    </Text>
                    <Text
                      color="black"
                      _dark={{ color: 'white' }}
                      fontSize="24px"
                      fontWeight="700"
                    >
                      {symbol}
                    </Text>
                  </HStack>
                </Stack>
              )}

              <Stack gap="5px">
                {/* <HStack justify="flex-end" spacing="2px">
                  <Text color="gray.500" fontSize="14px">
                    Balance:
                  </Text>
                  <Tooltip
                    label={`Unrounded value is ${
                      account === 'vault'
                        ? vaultBalanceEther
                        : walletBalanceEther || '0'
                    }`}
                  >
                    <Text
                      color="black"
                      _dark={{ color: 'white' }}
                      fontSize="14px"
                    >
                      {account === 'vault'
                        ? vaultBalanceEtherFmt
                        : walletBalanceEtherFmt || '0'}
                    </Text>
                  </Tooltip>
                </HStack> */}
                <HStack justify="flex-end" spacing="5px">
                  <Icon
                    as={account === 'vault' ? BoxAdd : EmptyWalletAdd}
                    variant="Linear"
                    color={account === 'vault' ? 'purple.500' : 'blue.500'}
                    boxSize="20px"
                  />
                  <Text color="gray.500" fontSize="14px">
                    {t('from')}{' '}
                    {account === 'vault' ? t('runner') : t('wallet')}
                  </Text>
                  <Spacer />
                  <Tooltip
                    label={`${t('unrounded-value-is')} ${
                      account === 'vault'
                        ? vaultBalanceEther
                        : walletBalanceEther || '0'
                    }`}
                  >
                    <Text
                      color="black"
                      _dark={{ color: 'white' }}
                      fontSize="14px"
                    >
                      {account === 'vault'
                        ? vaultBalanceEtherFmt
                        : walletBalanceEtherFmt || '0'}{' '}
                      {symbol}
                    </Text>
                  </Tooltip>
                </HStack>
                <InputGroup>
                  <Input
                    data-cy="add-power-input"
                    ref={mergedRefs}
                    {...registeredAmount}
                    variant="filled"
                    bg="#E2E8F0"
                    color="black"
                    _dark={{ bg: '#161C23', color: 'white' }}
                    rounded="18px"
                    h="48px"
                  />
                  <InputRightElement h="full" minW="136px">
                    <HStack rounded="full" ml="12">
                      <Tag
                        ml="2"
                        rounded="full"
                        onClick={() => {
                          setValue('amount', balanceAmount || '0');
                          clearErrors('amount');
                        }}
                        cursor="pointer"
                      >
                        {t('max-0')}
                      </Tag>
                    </HStack>
                  </InputRightElement>
                </InputGroup>
                {errors.amount && (
                  <Text color="orange.500" fontSize="sm">
                    {errors.amount.message}
                  </Text>
                )}
              </Stack>

              <Button
                data-cy="add-power-submit"
                isDisabled={disabledSubmit}
                type="submit"
                h="48px"
                w="full"
                color="white"
                bg="primary.500"
                fontSize="18px"
              >
                {t('add')}
              </Button>
            </Stack>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
