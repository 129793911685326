'use client';

import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
import { GTM_ID } from '../helpers/flags';
import { analyticsInstance } from './Analytics';

export default function GTMAnalytics() {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    if (pathname) {
      analyticsInstance.page();
      // analyticsInstance.page({ url: window?.location?.href });
      // analyticsInstance.page({ url: pathname });
      // analyticsInstance.track('full_path', {
      //   path: window?.location?.href,
      // });
    }
    // console.log('GTMAnalytics: ', pathname, searchParams);
    // console.log('GTMAnalytics href: ', window?.location?.href);
  }, [pathname, searchParams]);

  if (!GTM_ID) {
    return null;
  }

  return null;
}
