/* eslint-disable @typescript-eslint/naming-convention */
import {
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useFlowAlertActions } from '@kiroboio/fct-sdk';
import { Edit } from 'iconsax-react';
import { useTranslations } from 'next-intl';
import { useCallback, useMemo, type FC } from 'react';
import type { AlertSocialType } from '~/lib/hooks/useModals/TypesAndInitials/EditAlert';
import { useEditAlertStateModal } from '~/lib/hooks/useModals/useComplexModals';
import {
  InfoPopover,
  alertsManagerFuncs,
  useNotifyForm,
} from '../builder/components/FCTSettings';

export const EditAlertModal: FC = () => {
  const t = useTranslations();
  const { setErrorAlertType } = alertsManagerFuncs;
  const {
    id: alertId,
    isOpen,
    close,
    props: { description, social, success_notice, fail_notice },
  } = useEditAlertStateModal();
  const { patch } = useFlowAlertActions({ id: alertId });
  const toast = useToast();

  const { isRunning } = patch.state;

  const handleClose = useCallback(() => {
    close({});
  }, [close]);

  const socialSafe = (social && social.length && social) || [];
  let telegram_id = '';
  let webhook = '';
  socialSafe.forEach((item: AlertSocialType) => {
    if (item.type === 'telegram') {
      telegram_id = item.value;
    }
    if (item.type === 'webhook') {
      webhook = item.value.trim();
    }
  });

  const { register, formState, getValues, handleSubmit, reset, setValue } =
    useNotifyForm();

  const isTelegramOrWebhookMissingError = formState.errors.obj as unknown as {
    message: string;
  };

  const hasErrors = Object.keys(formState.errors).length > 0;

  const editAlert = useCallback(async () => {
    // console.log('editAlert');
    const data = getValues().obj;
    setErrorAlertType('');

    const social = [
      {
        type: 'telegram' as const,
        value: data.telegram_id,
        active: true,
      },
      {
        type: 'webhook' as const,
        value: data.webhook,
        active: true,
      },
    ].filter((s) => s.value !== '');

    const alert_meta = {
      description: data.description || '',
      social: social || [],
      success_notice: data.success_notice || '',
      fail_notice: data.fail_notice || '',
    };

    const res = await patch.execute({ alert_meta });
    // console.log('qwe ', { res });

    const error = res.error as any;
    if (error?.data?.message && error?.data?.type) {
      // TODO: TAL: make create function error for this block
      console.error('qwe ', { error });
      const errorType = error.data?.type || 'N/A';
      const errorAtProp = errorType === 'telegram' ? 'telegram_id' : errorType;
      setErrorAlertType(errorAtProp);

      toast({
        title: t('ops-something-went-wrong'),
        description: error.data.message,
        isClosable: true,
        duration: 3000,
        status: 'error',
      });
    } else if (error) {
      console.error('qwe ', { unknown: error });
    } else {
      // console.log('Success edit alert ', data);
      handleClose();
    }
  }, [getValues, setErrorAlertType, patch, toast, t, handleClose]);

  useMemo(() => {
    if (isOpen) {
      setErrorAlertType('');
      reset();
      setValue('obj', {
        telegram_id: telegram_id || '',
        webhook: webhook || '',
        description: description || '',
        success_notice: success_notice || '',
        fail_notice: fail_notice || '',
      });
    }
  }, [
    description,
    fail_notice,
    isOpen,
    reset,
    setErrorAlertType,
    setValue,
    success_notice,
    telegram_id,
    webhook,
  ]);

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay bg="rgba(51,57,66,0.5)" opacity="0.5" />

      <ModalContent
        rounded="3xl"
        bg="#F9FAFB"
        _dark={{ bg: '#0F151A' }}
        p="45px"
      >
        <ModalHeader p="0">
          <HStack w="full" justify="space-between">
            <Text align="left" fontWeight="semibold" fontSize="2xl">
              {t('edit-alert-info')}
            </Text>
            <ModalCloseButton pos="initial" />
          </HStack>
        </ModalHeader>
        <ModalBody px="0" py="4">
          {/* <form onSubmit={handleSubmit(editAlert)}> */}
          <Stack>
            <HStack spacing="0" gap="10px">
              <Text fontWeight="semibold">{t('description')}</Text>
              <InfoPopover
                description={t('briefly-describe-the-purpose-of-this-alert')}
              />
            </HStack>
            <Input
              {...register('obj.description')}
              variant="filled"
              rounded="18px"
              h="55px"
            />
            {formState.errors?.obj?.description && (
              <Text color="orange.500" fontSize="sm">
                {formState.errors?.obj?.description.message}
              </Text>
            )}
          </Stack>
          <Stack>
            <HStack spacing="0" gap="10px">
              <Text fontWeight="semibold">{t('telegram-id')}</Text>
              <InfoPopover
                description={t('enter-your-telegram-user-id-to-get-alerts')}
              />
            </HStack>
            <FormControl
              isInvalid={Boolean(
                formState.errors?.obj?.telegram_id ||
                  isTelegramOrWebhookMissingError
              )}
            >
              <Input
                {...register('obj.telegram_id')}
                variant="filled"
                rounded="18px"
                h="55px"
              />
              <FormErrorMessage>
                {formState.errors?.obj?.telegram_id?.message}
              </FormErrorMessage>
            </FormControl>
          </Stack>
          {isTelegramOrWebhookMissingError && (
            <Text color="red.400">
              {isTelegramOrWebhookMissingError?.message}
            </Text>
          )}
          <Stack>
            <HStack spacing="0" gap="10px">
              <Text fontWeight="semibold">{t('webhook')}</Text>
              <InfoPopover
                description={t('provide-url-for-alerts-webhook-endpoint')}
              />
            </HStack>
            <FormControl
              isInvalid={Boolean(
                formState.errors?.obj?.webhook ||
                  isTelegramOrWebhookMissingError
              )}
            >
              <Input
                {...register('obj.webhook')}
                variant="filled"
                rounded="18px"
                h="55px"
              />
              <FormErrorMessage>
                {formState.errors?.obj?.webhook?.message}
              </FormErrorMessage>
            </FormControl>
          </Stack>

          <Stack>
            <HStack spacing="0" gap="10px">
              <Text fontWeight="semibold">{t('success-notice')}</Text>
              <InfoPopover
                description={t(
                  'opt-for-a-confirmation-when-alert-runs-successfully'
                )}
              />
            </HStack>
            <Input
              {...register('obj.success_notice')}
              variant="filled"
              rounded="18px"
              h="55px"
            />
            {formState.errors?.obj?.success_notice && (
              <Text color="orange.500" fontSize="sm">
                {formState.errors?.obj?.success_notice.message}
              </Text>
            )}
          </Stack>
          <Stack>
            <HStack spacing="0" gap="10px">
              <Text fontWeight="semibold">{t('fail-notice')}</Text>
              <InfoPopover
                description={t(
                  'choose-to-be-notified-if-alert-execution-fails'
                )}
              />
            </HStack>
            <Input
              {...register('obj.fail_notice')}
              variant="filled"
              rounded="18px"
              h="55px"
            />
            {formState.errors?.obj?.fail_notice && (
              <Text color="orange.500" fontSize="sm">
                {formState.errors?.obj?.fail_notice.message}
              </Text>
            )}
          </Stack>
          {/* </form> */}
        </ModalBody>

        <ModalFooter px="0" pb="0">
          <Button
            isDisabled={hasErrors}
            isLoading={isRunning}
            leftIcon={<Edit />}
            onClick={handleSubmit(editAlert)}
            bg="blue.500"
            colorScheme="blue"
            color="white"
          >
            {hasErrors ? t('fix-errors') : t('save')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
