import { HStack, Progress, Stack, Text, Tooltip } from '@chakra-ui/react';
import type { FC, ReactNode } from 'react';
import type { Segment } from './PieChart';

interface IBarChart {
  network: string;
  value: number;
  valuePercentage: number;
  color: string;
  icon: ReactNode;
}

const BarChart: FC<IBarChart> = ({
  network,
  value,
  valuePercentage,
  color,
  icon,
}) => {
  const displayablePercentage =
    valuePercentage.toFixed(2) === '100.00'
      ? '100'
      : valuePercentage.toFixed(2);

  return (
    <Tooltip
      label={
        <Stack>
          <Text color="black" _dark={{ color: 'white' }}>
            {network} Popularity
          </Text>
          <Text color={color}>{value.toLocaleString()}</Text>
        </Stack>
      }
      placement="bottom-start"
      aria-label="A tooltip"
      bg="blackAlpha.900"
      hasArrow
    >
      <HStack>
        {icon}

        <Progress
          w="200px"
          colorScheme="blue"
          // bg="none"
          sx={{
            '& > div': {
              background: color,
            },
          }}
          size="xs"
          value={valuePercentage}
        />

        <Text color={color}>{displayablePercentage}%</Text>
      </HStack>
    </Tooltip>
  );
};

interface IListBarChart {
  networks: (Segment & { icon: ReactNode })[];
}

const ListBarChart: FC<IListBarChart> = ({ networks }) => {
  const total = networks.reduce((acc, { value }) => acc + value, 0);
  const totalSafe = total === 0 ? 1 : total;
  const sortedNetworksWithPercentage = networks.map((network) => ({
    ...network,
    value: (network.value / totalSafe) * 100,
  }));
  return (
    <HStack h="240px">
      <Stack spacing="12px" w="full">
        {networks.map((network, i) => (
          <BarChart
            key={network.label}
            network={network.label}
            value={network.value}
            valuePercentage={sortedNetworksWithPercentage[i].value}
            color={network.color}
            icon={network.icon}
          />
        ))}
      </Stack>
    </HStack>
  );
};

export default ListBarChart;
