'use client';

import { Text } from '@chakra-ui/react';
import { useAppDraftsActions, useAppDraftsItem } from '@kiroboio/fct-sdk';
import { useTranslations } from 'next-intl';
import type { FC } from 'react';
import { memo, useCallback } from 'react';

import { DeleteAlert } from '~/lib/components/general/DeleteAlert';
import { useDeleteAppDraftStateModal } from '~/lib/hooks/useModals/useComplexModals';

const DeleteAppDraftModal2: FC = () => {
  const t = useTranslations();
  const { isOpen, id, close } = useDeleteAppDraftStateModal();
  const { item: appDraft } = useAppDraftsItem({ id });
  const { name } = appDraft?.raw || { name: '' };

  const { remove } = useAppDraftsActions({
    id,
  });

  const handleRemove = useCallback(() => {
    remove.execute({});
  }, [remove]);

  const handleClose = useCallback(() => {
    close({ clear: true });
  }, [close]);

  return (
    <DeleteAlert
      isOpen={isOpen}
      onClose={handleClose}
      header={t('delete-intent')}
      body={
        <Text>
          {t('are-you-sure-you-want-to-delete')}{' '}
          <Text as="span" fontWeight="semibold">
            {name}
          </Text>
        </Text>
      }
      footer={{
        confirm: {
          onClick: handleRemove,
        },
      }}
    />
  );
};

export const DeleteAppDraftModal = memo(DeleteAppDraftModal2);
