import {
  Button,
  Collapse,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDraftsActions } from '@kiroboio/fct-sdk';
import { AddSquare } from 'iconsax-react';
import { useTranslations } from 'next-intl';
import { useCallback, useMemo, type FC } from 'react';
import { useForm } from 'react-hook-form';
import { object as yupObject, string as yupString } from 'yup';

import { useCreateAppStateModal } from '~/lib/hooks/useModals/useComplexModals';

export const CreateAppModal: FC = () => {
  const t = useTranslations();
  const { isOpen, close } = useCreateAppStateModal();
  const toast = useToast();

  const { create } = useAppDraftsActions({
    id: '',
  });

  const { isRunning, stage } = create.state;
  const isDone = stage === 'done';

  const {
    register,
    reset,
    getValues,
    formState: {
      errors: { name: nameError },
    },
    handleSubmit,
  } = useForm({
    defaultValues: {
      name: '',
    },
    resolver: yupResolver(
      yupObject().shape({
        name: yupString().required(),
      })
    ),
  });

  useMemo(() => {
    if (isDone) {
      create.reset();
      close({ clear: true });
    }
  }, [isDone, close, create]);

  const cleanup = !isOpen;
  useMemo(() => {
    if (cleanup) {
      reset();
    }
  }, [cleanup, reset]);

  const createApp = useCallback(
    async (name: string) => {
      const returnedData = await create.execute({
        name,
      });
      // console.log('yui returnedData', returnedData);
      if (returnedData.error) {
        // console.log('returnedData.error', returnedData.error);
        if ((returnedData.error as any).code === 11000) {
          toast({
            position: 'bottom',
            status: 'error',
            title: t('error'),
            description: t('intent-name-already-exists'),
            duration: 9000,
            isClosable: true,
          });
        } else {
          toast({
            position: 'bottom',
            status: 'error',
            title: t('error'),
            description: t('something-went-wrong-please-try-again-later'),
            duration: 9000,
            isClosable: true,
          });
        }
      } else {
        // console.log('success');
        // if (returnedData.results?.id) {
        toast({
          position: 'bottom',
          status: 'success',
          title: t('success'),
          description: t('intent-added-successfully'),
          duration: 9000,
          isClosable: true,
        });
        // }
      }
    },
    [create, t, toast]
  );

  const handleAddApp = useCallback(async () => {
    const { name } = getValues();
    // console.log(name);
    createApp(name);
  }, [createApp, getValues]);

  const nameInput = register('name');

  const handleClose = useCallback(() => {
    close({ clear: true });
  }, [close]);

  return (
    <Modal
      id="change-draft-name"
      motionPreset="slideInBottom"
      size="md"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <ModalOverlay bg="rgba(51,57,66,0.5)" opacity="0.5" />

      <ModalContent
        rounded="3xl"
        bg="#F9FAFB"
        _dark={{ bg: '#0F151A' }}
        p="45px"
      >
        <ModalHeader p="0">
          <HStack w="full" justify="flex-end">
            <ModalCloseButton pos="initial" />
          </HStack>
        </ModalHeader>
        <ModalBody px="0" py="4">
          <Stack
            as="form"
            onSubmit={handleSubmit(handleAddApp)}
            align="center"
            spacing="10"
          >
            <Stack align="center" textAlign="center">
              <Icon
                as={AddSquare}
                variant="Bulk"
                color="primary.500"
                boxSize="68px"
                transition="all 120ms"
              />
              <Stack>
                <Text fontSize="md">{t('add-intent')}</Text>
              </Stack>
            </Stack>
            <Stack spacing={4}>
              <FormControl>
                <FormLabel fontSize="sm" fontWeight="normal" color="gray.400">
                  {t('name')}
                </FormLabel>
                <Input
                  variant="flushed"
                  size="lg"
                  fontWeight="bold"
                  fontSize="2xl"
                  {...nameInput}
                />
                <Collapse in={Boolean(nameError)}>
                  <FormHelperText color="orange.500" fontSize="sm" mb="4">
                    {nameError?.message}
                  </FormHelperText>
                </Collapse>
              </FormControl>

              <Button
                size="lg"
                isLoading={isRunning}
                leftIcon={<AddSquare />}
                type="submit"
                isDisabled={Boolean(nameError)}
                bg="blue.500"
                colorScheme="blue"
                color="white"
                rounded="18px"
              >
                {nameError ? t('fix-errors') : t('add-intent')}
              </Button>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
