'use client';

import { Text } from '@chakra-ui/react';
import { useExpiredAppsActions, useExpiredAppsItem } from '@kiroboio/fct-sdk';
import { useTranslations } from 'next-intl';
import type { FC } from 'react';
import { memo, useCallback } from 'react';

import { DeleteAlert } from '~/lib/components/general/DeleteAlert';
import { useDeleteExpiredAppStateModal } from '~/lib/hooks/useModals/useComplexModals';

const DeleteExpiredAppModal2: FC = () => {
  const t = useTranslations();
  const { isOpen, id, close } = useDeleteExpiredAppStateModal();

  const { item: expiredApp } = useExpiredAppsItem({ id });

  const expiredAppName: string =
    expiredApp?.raw?.data?.typedData?.message?.meta?.name?.toString() || '';

  const { remove } = useExpiredAppsActions({
    id,
  });

  const handleRemove = useCallback(() => {
    remove.execute({});
  }, [remove]);

  const handleClose = useCallback(() => {
    close({ clear: true });
  }, [close]);

  return (
    <DeleteAlert
      isOpen={isOpen}
      onClose={handleClose}
      header={t('delete-expired-intent')}
      body={
        <Text>
          {t('are-you-sure-you-want-to-delete')}{' '}
          <Text as="span" fontWeight="semibold">
            {expiredAppName}
          </Text>
        </Text>
      }
      footer={{
        confirm: {
          onClick: handleRemove,
        },
      }}
    />
  );
};

export const DeleteExpiredAppModal = memo(DeleteExpiredAppModal2);
