import type { ThemeConfig } from '@chakra-ui/react';
import { INIT_COLOR_MODE } from '~/lib/helpers/flags';

type ColorMode = 'light' | 'dark' | 'system' | undefined;
const isValidColorMode =
  INIT_COLOR_MODE === 'light' ||
  INIT_COLOR_MODE === 'dark' ||
  INIT_COLOR_MODE === 'system';

const initialColorMode = isValidColorMode
  ? (INIT_COLOR_MODE as ColorMode)
  : 'dark';

export const config: ThemeConfig = {
  initialColorMode,
  disableTransitionOnChange: false,
};
