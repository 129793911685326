import type { DeepPartial, Theme } from '@chakra-ui/react';

/** extend additional color here */
const extendedColors: DeepPartial<
  Record<string, Theme['colors']['blackAlpha']>
> = {
  gray: {
    '50': '#F1F2F4',
    '100': '#D7DBE0',
    '200': '#BDC4CC',
    '300': '#A3ADB8',
    '400': '#8A95A3',
    '500': '#707E8F',
    '600': '#596573',
    '700': '#434C56',
    '800': '#2D3339',
    '900': '#1c2024',
  },
  // "gray": {
  //   "50": "#EFF3F6",
  //   "100": "#D2DCE5",
  //   "200": "#B5C6D4",
  //   "300": "#98AFC3",
  //   "400": "#7A99B3",
  //   "500": "#5D83A2",
  //   "600": "#4B6881",
  //   "700": "#384E61",
  //   "800": "#253441",
  //   "900": "#131A20"
  // },
  // fct: {
  //   bg: '#0F151A',
  // },
  // background: '#F2F5F8',
  // black: '#262F38',
  primary: {
    '50': '#E5F4FF',
    '100': '#B8E1FF',
    '200': '#8ACEFF',
    '300': '#5CBBFF',
    '400': '#2EA8FF',
    '500': '#0095FF',
    '600': '#0077CC',
    '700': '#005999',
    '800': '#003C66',
    '900': '#001E33',
  },
  secondary_bg: {
    '50': '#E5F4FF',
    '100': '#B8E1FF',
    '200': '#8ACEFF',
    '300': '#5CBBFF',
    '400': '#2EA8FF',
    '500': '#0095FF',
    '600': '#0077CC',
    '700': '#005999',
    '800': '#003C66',
    '900': '#001E33',
  },
};

/** override chakra colors here */
const overriddenChakraColors: DeepPartial<Theme['colors']> = {};

export const colors = {
  ...overriddenChakraColors,
  ...extendedColors,
};
