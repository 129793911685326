import Analytics from 'analytics';
import googleTagManager from '@analytics/google-tag-manager';
import { GTM_ID } from '../helpers/flags';

export const analyticsInstance = Analytics({
  app: 'Intentable',
  plugins: !GTM_ID
    ? undefined
    : [
        googleTagManager({
          containerId: GTM_ID,
        }),
      ],
});

/* Track a page view */
// analytics.page();

/* Track a custom event */
// analytics.track('cartCheckout', {
//   item: 'pink socks',
//   price: 20,
// });
