'use client';

import { Text } from '@chakra-ui/react';
import { useVersionDraftsActions } from '@kiroboio/fct-sdk';
import { useTranslations } from 'next-intl';
import type { FC } from 'react';
import { memo, useCallback } from 'react';

import { DeleteAlert } from '~/lib/components/general/DeleteAlert';
import { useSetDefaultVersionModeStateModal } from '~/lib/hooks/useModals/useComplexModals';

const SetDefaultVersionModeModal2: FC = () => {
  const t = useTranslations();
  const {
    isOpen,
    id,
    close,
    props: { index },
  } = useSetDefaultVersionModeStateModal();
  const { patch } = useVersionDraftsActions({
    id,
  });

  // console.log('i', index);

  // const handleSetDefault = useCallback(async () => {
  //   const res = await patch.execute({ defaultmode: index });
  //   console.log(res);
  // }, [index, patch]);
  // TODO: Tal need to fix this - it's not working
  const handleSetDefault = useCallback(() => {
    patch.execute({ defaultmode: index });
    // console.log(res);
  }, [index, patch]);

  const handleClose = useCallback(() => {
    close({ clear: true });
  }, [close]);

  return (
    <DeleteAlert
      isOpen={isOpen}
      onClose={handleClose}
      header="Default Version Mode"
      body={<Text>{t('are-you-sure-you-want-to-set-default')}</Text>}
      footer={{
        confirm: {
          onClick: handleSetDefault,
        },
      }}
    />
  );
};

export const SetDefaultVersionModeModal = memo(SetDefaultVersionModeModal2);
