import type { ImageProps } from '@chakra-ui/react';
import { Flex, Image, Spinner } from '@chakra-ui/react';
import type { NFTItemType } from '@kiroboio/fct-sdk';
import type { FC } from 'react';
import { useState } from 'react';

import noNft from '~/assets/svg/broken-image.svg';

interface NFTImageProps extends ImageProps {
  nft: NFTItemType | null;
}

export const NFTImage: FC<NFTImageProps> = ({
  nft,
  boxSize = '40px',
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(true);
  return (
    <>
      <Image
        position={isLoading ? 'absolute' : undefined}
        opacity={isLoading ? 0 : 1}
        borderRadius="4px"
        boxSize={boxSize}
        src={nft?.fmt.iconUrl || noNft}
        alt="NFT image"
        onLoad={() => setIsLoading(false)}
        h={[150, 200]}
        {...props}
      />
      <Flex
        alignItems="center"
        justifyContent="center"
        hidden={!isLoading}
        boxSize={boxSize}
        w={props.w}
        h={props.h}
        borderRadius="4px"
      >
        <Spinner speed="1s" />
      </Flex>
    </>
  );
};
