import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

const baseStyle = definePartsStyle({
  track: {
    bg: 'rgba(245, 101, 101, 0.5)',
    px: '3px',
    _checked: {
      bg: 'green.400',
    },
  },
});

export const Switch = defineMultiStyleConfig({ baseStyle });
