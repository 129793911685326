/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable react/no-unescaped-entities */
import {
  Box,
  Button,
  Center,
  Circle,
  HStack,
  Icon,
  IconButton,
  Link,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { BigIntOr0, useFlowPower } from '@kiroboio/fct-sdk';
import { useTranslations } from 'next-intl';
import { useCallback, useEffect, useState } from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { MdRocketLaunch } from 'react-icons/md';
import { useLocalStorage } from 'react-use';
import { isOnBoardingOff } from '../helpers/flags';
import { useConnectHandler } from '../hooks/general/useConnectHandler';
import {
  flowPowerId,
  missingOnChainWalletId,
  useFlowPowerModalActions,
  useMissingOnChainWalletModalActions,
} from '../hooks/useModals/useComplexModals';
import { useUser } from '../hooks/useUser/useUser';

export function OnBoarding() {
  const t = useTranslations();
  const [showOnBoarding, setShowOnBoarding] = useLocalStorage(
    'showOnBoarding',
    true
  );
  const { isUsingVault, isSignedIn } = useUser();
  const { connectHandler } = useConnectHandler();
  const {
    raw: {
      balance: { native: powerBalanceWei },
    },
  } = useFlowPower();

  const isPowerMissing = BigIntOr0(powerBalanceWei) === BigIntOr0(0);

  const { open: openFlowPower } = useFlowPowerModalActions(flowPowerId);

  const handleOpenFlowPower = useCallback(() => {
    openFlowPower({});
  }, [openFlowPower]);

  const { open: openMissingOnChainWallet } =
    useMissingOnChainWalletModalActions(missingOnChainWalletId);

  const handleOpenMissingOnChainWallet = useCallback(() => {
    openMissingOnChainWallet({});
  }, [openMissingOnChainWallet]);

  const [step, setStep] = useState(0);
  const steps = [
    {
      title: t('connect-wallet'),
      description: t('connect-your-wallet-to-execute-your-preferred-intent'),
      btnText: t('connect-wallet'),
      btnAction: connectHandler,
      isDisabled: isSignedIn,
      isDisabledText: t('connected'),
      isLink: false,
      href: '/',
    },
    {
      title: t('create-a-runner'),
      description: t(
        'after-connecting-your-wallet-create-a-runner-by-clicking-create-runner-and-signing-the-transaction'
      ),
      btnText: t('create-runner'),
      btnAction: handleOpenMissingOnChainWallet,
      isDisabled: isUsingVault,
      isDisabledText: t('created'),
      isLink: false,
      href: '/',
    },
    {
      title: t('add-gas'),
      description: t(
        'click-add-now-and-enter-the-amount-you-wish-to-deposit-into-the-gas-station-this-deposit-will-cover-future-gas-fees-and-any-unused-funds-can-be-withdrawn-at-any-time'
      ),
      btnText: t('add-now'),
      btnAction: handleOpenFlowPower,
      isDisabled: false,
      isDisabledText: t('added'),
      isLink: false,
      href: '/',
    },
    {
      title: t('start-trading'),
      description: t(
        'you-are-now-ready-to-use-the-marketplace-select-your-desired-intent-fill-in-the-required-data-and-sign-the-transaction-please-note-you-can-cancel-the-intent-as-long-as-it-has-not-been-executed'
      ),
      btnText: t('learn-more'),
      btnAction: () => console.log('Start Trading'),
      isDisabled: false,
      isDisabledText: t('started'),
      isLink: true,
      href: 'https://www.intentable.io/',
    },
    // {
    //   title: t('set-notifications'),
    //   description: t('enable-notifications-to-receive-real-time-alerts'),
    //   btnText: t('turn-on'),
    //   btnAction: () => console.log('Set Notifications'),
    //   isDisabled: false,
    //   isDisabledText: t('set'),
    //   isLink: false,
    //   href: '/',
    // },
  ];

  useEffect(() => {
    if (!isSignedIn) {
      setStep(0);
    } else if (!isUsingVault) {
      setStep(1);
    } else if (isPowerMissing) {
      setStep(2);
    } else {
      setStep(3);
    }
  }, [isPowerMissing, isSignedIn, isUsingVault]);

  if (isOnBoardingOff || !showOnBoarding) return null;

  return (
    <Box position="fixed" zIndex={4} right="20px" bottom="20px">
      <Popover placement="left-start">
        <PopoverTrigger>
          <IconButton rounded="3xl" boxSize="68px" aria-label="steps">
            <Tooltip
              placement="top"
              offset={[0, 15]}
              label={t('learn')}
              aria-label="A tooltip"
            >
              <Center
                rounded="2xl"
                boxSize="48px"
                bg="blackAlpha.100"
                _dark={{ bg: 'whiteAlpha.100' }}
              >
                <Icon color="blue.400" boxSize="28px" as={MdRocketLaunch} />
              </Center>
            </Tooltip>
          </IconButton>
        </PopoverTrigger>
        <PopoverContent
          p="18px"
          w="330px"
          rounded="2xl"
          bg="#F9FAFB"
          _dark={{ bg: '#0F151A' }}
        >
          <Stack spacing="18px" justifyContent="center" alignItems="center">
            <HStack w="full" justifyContent="space-between">
              <HStack spacing="5px">
                <Icon color="blue.400" boxSize="18px" as={MdRocketLaunch} />
                <Text fontSize="14px" fontWeight="700">
                  {t('getting-started')}
                </Text>
              </HStack>
              <HStack spacing="0">
                <IconButton
                  onClick={() => {
                    if (step === 0) return;
                    setStep(step - 1);
                  }}
                  isDisabled={step === 0}
                  variant="ghost"
                  size="xs"
                  aria-label="back"
                  icon={<Icon boxSize="18px" as={ChevronLeft} />}
                />
                <IconButton
                  onClick={() => {
                    if (step === steps.length - 1) return;
                    setStep(step + 1);
                  }}
                  isDisabled={step === steps.length - 1}
                  variant="ghost"
                  size="xs"
                  aria-label="back"
                  icon={<Icon boxSize="18px" as={ChevronRight} />}
                />
              </HStack>
            </HStack>
            <HStack>
              <Text fontSize="14px" fontWeight="700">
                {steps[step].title}
              </Text>
            </HStack>
            <HStack h="105px" alignItems="flex-start">
              <Text fontSize="14px" textAlign="center">
                {steps[step].description}
              </Text>
            </HStack>
            <HStack>
              {steps[step].isLink ? (
                <Button
                  as={Link}
                  href={steps[step].href}
                  style={{ textDecoration: 'none' }}
                  isExternal
                >
                  {steps[step].btnText}
                </Button>
              ) : (
                <Button
                  isDisabled={steps[step].isDisabled}
                  onClick={steps[step].btnAction}
                >
                  {steps[step].isDisabled
                    ? steps[step].isDisabledText
                    : steps[step].btnText}
                </Button>
              )}
            </HStack>
            <HStack spacing="0">
              {steps.map((s, i) => (
                <IconButton
                  key={s.title}
                  onClick={() => setStep(i)}
                  variant="ghost"
                  size="xs"
                  aria-label="back"
                  icon={
                    <Circle
                      size={i === step ? '10px' : '5px'}
                      bg={i === step ? 'blue.400' : 'black'}
                      _dark={{ bg: i === step ? 'blue.400' : 'white' }}
                    />
                  }
                />
              ))}
            </HStack>
            <HStack>
              <Text
                fontSize="12px"
                cursor="pointer"
                onClick={() => setShowOnBoarding(false)}
              >
                {t('dont-show-me-this-again')}
              </Text>
            </HStack>
          </Stack>
        </PopoverContent>
      </Popover>
    </Box>
  );
}
