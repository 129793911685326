import {
  Button,
  Center,
  HStack,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Square,
  Stack,
  Text,
} from '@chakra-ui/react';
import type { AppsDefiDataItemType } from '@kiroboio/fct-sdk';
import { useTranslations } from 'next-intl';
import type { ReactNode } from 'react';
import { useCallback } from 'react';
import { FaRegSmile } from 'react-icons/fa';
import { TbMessage2 } from 'react-icons/tb';

import { Play } from 'iconsax-react';
import { BsQuestionCircle } from 'react-icons/bs';
import {
  FaNewspaper,
  FaReddit,
  FaTiktok,
  FaXTwitter,
  FaYoutube,
} from 'react-icons/fa6';
import { MdOutlinePeopleAlt } from 'react-icons/md';
import {
  useDefiAppsModalActions,
  useDefiAppsSocialStateModal,
} from '~/lib/hooks/useModals/useComplexModals';
import ListBarChart from '../Trading/Charts/ListBarChart';
import ListStackedBarChart from '../Trading/Charts/ListStackedBarChart';
import PieChart from '../Trading/Charts/PieChart';
import {
  getCalculatedSentimentPercent,
  getSentimentColor,
} from '../Trading/TradingTableList/config';

type SentimentDetail = {
  positive: number;
  negative: number;
  neutral: number;
};

type SocialSentimentDetails = Record<string, SentimentDetail>;

function getSentimentAmount(
  sentiment: 'positive' | 'negative' | 'neutral' | 'n/a',
  sentimentDetails: SocialSentimentDetails
) {
  if (sentiment === 'n/a' || !sentimentDetails) return 0;
  return Object.values(sentimentDetails).reduce(
    (acc, { [sentiment]: value }) => {
      return acc + value;
    },
    0
  );
}

const getNetworkSentiment = (
  typesSentimentDetail:
    | {
        positive: number;
        neutral: number;
        negative: number;
      }
    | undefined
) => {
  const networkSentiment = typesSentimentDetail;
  if (!networkSentiment) return undefined;

  const { positive, neutral, negative } = networkSentiment;

  // to get rid of the zeros case
  // if (positive === 0 && neutral === 0 && negative === 0) return undefined;

  return [
    {
      label: 'Positive',
      color: '#68D391',
      value: positive,
    },
    {
      label: 'Neutral',
      color: '#F6AD55',
      value: neutral,
    },
    {
      label: 'Negative',
      color: '#F56565',
      value: negative,
    },
  ];
};

function getHighestPositiveRatio(
  typesSentimentDetail: Record<
    string,
    Record<'positive' | 'negative' | 'neutral', number>
  >
) {
  if (!typesSentimentDetail) return { socialMedia: '', positiveRatio: 0 };

  return Object.entries(typesSentimentDetail).reduce(
    (acc, [socialMedia, sentiment]) => {
      const total = sentiment.positive + sentiment.negative + sentiment.neutral;
      if (total === 0) return acc;
      const positiveRatio = sentiment.positive / total;
      return positiveRatio > acc.positiveRatio
        ? { socialMedia, positiveRatio }
        : acc;
    },
    { socialMedia: '', positiveRatio: 0 }
  );
}

const displayableSocialMediaNames = {
  tweet: {
    label: 'X',
    icon: <Icon as={FaXTwitter} boxSize="20px" />,
  },
  'youtube-video': {
    label: 'Youtube',
    icon: <Icon as={FaYoutube} color="rgb(255, 0, 0)" boxSize="20px" />,
  },
  'reddit-post': {
    label: 'Reddit',
    icon: <Icon color="#ff4500" as={FaReddit} boxSize="20px" />,
  },
  'tiktok-video': {
    label: 'Tiktok',
    icon: <Icon as={FaTiktok} boxSize="20px" />,
  },
  news: {
    label: 'News',
    icon: <Icon color="blue.300" as={FaNewspaper} boxSize="20px" />,
  },
};

type SocialsRawKey = keyof typeof displayableSocialMediaNames;

function getSocialMediaName(socialMediaKey: SocialsRawKey) {
  return displayableSocialMediaNames[socialMediaKey]?.label || 'Unknown';
}

function getSocialMediaIcon(socialMediaKey: SocialsRawKey) {
  return (
    displayableSocialMediaNames[socialMediaKey]?.icon || (
      <Icon as={BsQuestionCircle} boxSize="20px" />
    )
  );
}

function generateNetworkEngagements(data: Record<string, number>) {
  if (!data) return [];
  return Object.entries(data)
    .map(([socialMediaKey, interactionCount]) => ({
      label: getSocialMediaName(socialMediaKey as SocialsRawKey),
      value: interactionCount,
      color: '#63b3ed',
      icon: getSocialMediaIcon(socialMediaKey as SocialsRawKey),
    }))
    .sort((a, b) => b.value - a.value);
}

interface ISection {
  category: string;
  categoryIcon: ReactNode;
  categoryNumber: string;
  title: string;
  description: ReactNode;
  graph: ReactNode;
}

const Section = ({
  category,
  categoryIcon,
  categoryNumber,
  title,
  description,
  graph,
}: ISection) => (
  <Stack w="305px" spacing="28px">
    <HStack h="70px" p="12px" rounded="lg" bg="#2E3338" justify="space-between">
      <HStack spacing="12px">
        <Center w="46px" h="46px" rounded="lg" bg="#56A6F8">
          {categoryIcon}
        </Center>
        <Text>{category}</Text>
      </HStack>

      <Text color="#56A6F8">{categoryNumber}</Text>
    </HStack>
    <Stack spacing="0">
      <Stack spacing="0">
        <Text fontSize="18px" fontWeight="600">
          {title}
        </Text>
        <Stack h="65px">{description}</Stack>
      </Stack>
      {graph}
    </Stack>
  </Stack>
);

export const DefiAppsSocialModal = () => {
  const t = useTranslations();

  const {
    isOpen,
    close,
    props: { dataItem, defiAppsRowId, view },
  } = useDefiAppsSocialStateModal();

  const { open: openDefiApps } = useDefiAppsModalActions(defiAppsRowId);

  const handleClose = useCallback(() => {
    close({});
  }, [close]);

  const handleOpenDefiApps = () => {
    openDefiApps({
      dataItem,
      defiAppsRowId,
    });
    handleClose();
  };

  const quoteTokenAddress = dataItem?.raw.pool.quoteToken;
  const quoteTokenSymbol = dataItem?.raw.underlyingTokens.find(
    (token: AppsDefiDataItemType['raw']['underlyingTokens'][0]) =>
      token.address === quoteTokenAddress
  )?.symbol;

  const investedInToken = dataItem?.fmt?.underlyingTokens?.find(
    (token: AppsDefiDataItemType['raw']['underlyingTokens'][0]) =>
      token?.symbol !== quoteTokenSymbol
  );

  const sentiment = dataItem?.fmt?.socials?.lunarCrush?.sentiment;
  const hasSentiment = sentiment !== undefined;
  const calculatedSentiment = getCalculatedSentimentPercent(sentiment);
  const sentimentDisplay = hasSentiment
    ? `${calculatedSentiment}%`
    : t('unavailable');
  const sentimentColor = hasSentiment
    ? getSentimentColor(sentiment)
    : 'gray.400';

  const creatorsNumber = dataItem?.fmt?.socials?.lunarCrush?.num_contributors;
  const postsNumber = dataItem?.fmt?.socials?.lunarCrush?.num_posts;
  const engagementsNumber =
    dataItem?.fmt?.socials?.lunarCrush?.interactions_24h;

  const typesInteractions =
    dataItem?.raw?.socials?.lunarCrush?.types_interactions;

  const typesSentimentDetail =
    dataItem?.raw?.socials?.lunarCrush?.types_sentiment_detail;

  const positiveAmount = getSentimentAmount('positive', typesSentimentDetail);
  const neutralAmount = getSentimentAmount('neutral', typesSentimentDetail);
  const negativeAmount = getSentimentAmount('negative', typesSentimentDetail);

  const sentimentDetails = {
    positive: positiveAmount,
    negative: negativeAmount,
    neutral: neutralAmount,
  };

  const largestSentiment = Object.entries(sentimentDetails).reduce(
    (max, [sentiment, value]) =>
      value > max.value ? { label: sentiment, value } : max,
    { label: '', value: -Infinity }
  );

  const renderNoData = () => {
    return (
      <Text fontSize="14px" color="gray.400">
        {t('currently-no-data-available-please-check-back-later')}
      </Text>
    );
  };

  const tokenName = investedInToken?.name;

  const creatorsDescription = () => {
    if (!typesSentimentDetail) return renderNoData();
    return (
      <Text fontSize="14px" color="gray.400">
        {tokenName} {t('sentiment-is')} {largestSentiment.label} {t('with')}{' '}
        {largestSentiment.value.toLocaleString()} {largestSentiment.label}{' '}
        {t('interactions-out-of')} {Number(engagementsNumber).toLocaleString()}{' '}
        {t('total-engagements')}.
      </Text>
    );
  };

  const creatorsSegments = [
    {
      label: 'Positive',
      color: '#68D391',
      value: positiveAmount,
    },
    {
      label: 'Neutral',
      color: '#F6AD55',
      value: neutralAmount,
    },
    {
      label: 'Negative',
      color: '#F56565',
      value: negativeAmount,
    },
  ];

  const networkSentiments = {
    twitter: getNetworkSentiment(typesSentimentDetail?.tweet),
    youtube: getNetworkSentiment(typesSentimentDetail?.['youtube-video']),
    reddit: getNetworkSentiment(typesSentimentDetail?.['reddit-post']),
    tiktok: getNetworkSentiment(typesSentimentDetail?.['tiktok-video']),
    news: getNetworkSentiment(typesSentimentDetail?.news),
  };

  const highestPositiveRatio = getHighestPositiveRatio(typesSentimentDetail);
  const highestPositivePercentage = (
    highestPositiveRatio.positiveRatio * 100
  ).toFixed(1);
  const highestPositiveSocialMedia = getSocialMediaName(
    highestPositiveRatio.socialMedia as SocialsRawKey
  );

  const postsDescription = () => {
    if (!typesSentimentDetail) return renderNoData();
    return (
      <Text fontSize="14px" color="gray.400">
        {highestPositiveSocialMedia} {t('has-the-most-positive-sentiment-for')}{' '}
        {tokenName} {t('with')} {highestPositivePercentage}%{' '}
        {t('positive-posts-by')} {t('engagements')}.
      </Text>
    );
  };

  const networkEngagements = generateNetworkEngagements(typesInteractions);
  const highestNetworkEngagements = networkEngagements[0];

  const engagementsDescription = () => {
    if (!typesInteractions || !highestNetworkEngagements) return renderNoData();
    return (
      <Text fontSize="14px" color="gray.400">
        {t('with-0')} {Number(engagementsNumber).toLocaleString()}{' '}
        {t('total-engagements')}, {highestNetworkEngagements.label}{' '}
        {t('has-the-lead-with')}{' '}
        {highestNetworkEngagements.value.toLocaleString()} {t('engagements')}.
      </Text>
    );
  };

  const getSquaredLegendColor = (label: string, color: string) => {
    return (
      <HStack spacing="4px">
        <Square size="10px" bg={color} />
        <Text color={color}>{label}</Text>
      </HStack>
    );
  };

  return (
    <Modal size="6xl" isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay backdropFilter="blur(4px)" bg="whiteAlpha.200" />
      <ModalContent
        rounded="24px"
        bg="#F9FAFB"
        _dark={{ bg: '#0F151A' }}
        p="60px"
        gap="24px"
      >
        <ModalHeader p="0">
          <Stack spacing="0" alignItems="center">
            <HStack spacing="4px">
              <Image
                src={investedInToken?.thumbnail || ''}
                width="24px"
                height="24px"
                rounded="full"
                alt={`${investedInToken?.symbol} / ${quoteTokenSymbol}`}
              />
              <Text>{`${investedInToken?.symbol || 'n/a'} / ${quoteTokenSymbol || 'n/a'}`}</Text>
            </HStack>
            <HStack spacing="4px">
              <Text textTransform="capitalize">{t('social-media')}: </Text>
              <Text fontSize="130%" color={sentimentColor}>
                {sentimentDisplay}
              </Text>
            </HStack>
          </Stack>
        </ModalHeader>
        <ModalCloseButton m="16px" />
        <ModalBody justifyContent="center" px="0">
          <HStack justifyContent="space-between" alignItems="flex-start">
            <Section
              category="Creators"
              categoryIcon={<Icon boxSize="24px" as={FaRegSmile} />}
              categoryNumber={Number(creatorsNumber || 0).toLocaleString()}
              title="Sentiment"
              description={creatorsDescription()}
              graph={<PieChart segments={creatorsSegments} />}
            />
            <Section
              category="Posts"
              categoryIcon={<Icon boxSize="24px" as={TbMessage2} />}
              categoryNumber={Number(postsNumber || 0).toLocaleString()}
              title="Sentiment by Network"
              description={postsDescription()}
              graph={<ListStackedBarChart networks={networkSentiments} />}
            />
            <Section
              category="Engagements"
              categoryIcon={<Icon boxSize="24px" as={MdOutlinePeopleAlt} />}
              categoryNumber={Number(engagementsNumber || 0).toLocaleString()}
              title="Network Popularity"
              description={engagementsDescription()}
              graph={<ListBarChart networks={networkEngagements} />}
            />
          </HStack>
        </ModalBody>
        <ModalFooter justifyContent="space-between" p="0">
          <HStack spacing="12px">
            {getSquaredLegendColor('Positive', '#68D391')}
            {getSquaredLegendColor('Neutral', '#F6AD55')}
            {getSquaredLegendColor('Negative', '#F56565')}
          </HStack>
          {view === 'table' && (
            <Button
              onClick={handleOpenDefiApps}
              colorScheme="blue"
              w="180px"
              leftIcon={<Icon as={Play} boxSize="18px" />}
            >
              {t('start-trading')}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
