'use client';

import { Text } from '@chakra-ui/react';
import { useVersionDraftsActions } from '@kiroboio/fct-sdk';
import { useTranslations } from 'next-intl';
import type { FC } from 'react';
import { memo, useCallback } from 'react';

import { DeleteAlert } from '~/lib/components/general/DeleteAlert';
import { useDeleteAppVersionStateModal } from '~/lib/hooks/useModals/useComplexModals';

const DeleteAppVersionModal2: FC = () => {
  const t = useTranslations();
  const {
    isOpen,
    id,
    close,
    props: { version },
  } = useDeleteAppVersionStateModal();
  const { remove } = useVersionDraftsActions({
    id,
  });

  const handleRemove = useCallback(() => {
    remove.execute({});
  }, [remove]);

  const handleClose = useCallback(() => {
    close({ clear: true });
  }, [close]);

  return (
    <DeleteAlert
      isOpen={isOpen}
      onClose={handleClose}
      header={t('delete-intent-version')}
      body={
        <Text>
          {t('are-you-sure-you-want-to-delete')}{' '}
          <Text as="span" fontWeight="semibold">
            {version}
          </Text>
        </Text>
      }
      footer={{
        confirm: {
          onClick: handleRemove,
        },
      }}
    />
  );
};

export const DeleteAppVersionModal = memo(DeleteAppVersionModal2);
