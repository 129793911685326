/* eslint-disable sonarjs/no-duplicate-string */
import { useToast } from '@chakra-ui/react';
import { useActiveFlowActions } from '@kiroboio/fct-sdk';
import { useTranslations } from 'next-intl';
import { useEffect } from 'react';
import { PauseButton } from './PauseButton';

export interface PauseButtonType {
  pauseIcon: JSX.Element;
  playIcon: JSX.Element;
  id: string;
  state: string;
  onCloseModal: () => void;
}

const userDeniedMsg = 'user rejected transaction';

export const FCTPauseButton = ({
  id,
  state,
  pauseIcon,
  playIcon,
  onCloseModal,
}: PauseButtonType) => {
  const toast = useToast();
  const { block, unblock } = useActiveFlowActions({ id });

  const blockRunning = block.state.isRunning;
  const blockIsDone = block.state.stage === 'done';
  const blockIsFailed = block.state.status === 'failed';
  const blockIsError = block.state.error;
  const blockUserDeniedError = (blockIsError as any)?.reason === userDeniedMsg;

  const unblockRunning = unblock.state.isRunning;
  const unblockIsDone = unblock.state.stage === 'done';
  const unblockIsFailed = unblock.state.status === 'failed';
  const unblockIsError = unblock.state.error;
  const unblockUserDeniedError =
    (unblockIsError as any)?.reason === userDeniedMsg;

  const t = useTranslations();

  useEffect(() => {
    if (blockIsDone && !blockIsFailed) {
      block.reset();
      onCloseModal();
      toast({
        position: 'bottom-right',
        status: 'info',
        title: t('intent-paused'),
        description: t('intent-was-paused-on-chain'),
        duration: 9000,
        isClosable: true,
      });
    }
    if (blockIsFailed && !blockUserDeniedError) {
      block.reset();
      toast({
        position: 'bottom-right',
        status: 'error',
        title: t('error-0'),
        description: t('error-occurred-while-pausing-0'),
        duration: 9000,
        isClosable: true,
      });
    }
  }, [
    block,
    blockIsDone,
    blockIsFailed,
    blockUserDeniedError,
    onCloseModal,
    t,
    toast,
  ]);

  useEffect(() => {
    if (unblockIsDone && !unblockIsFailed) {
      unblock.reset();
      onCloseModal();
      toast({
        position: 'bottom-right',
        status: 'info',
        title: t('intent-resumed'),
        description: t('intent-was-resumed-on-chain'),
        duration: 9000,
        isClosable: true,
      });
    }
    if (unblockIsFailed && !unblockUserDeniedError) {
      unblock.reset();
      toast({
        position: 'bottom-right',
        status: 'error',
        title: t('error-0'),
        description: t('error-occurred-while-pausing-0'),
        duration: 9000,
        isClosable: true,
      });
    }
  }, [
    onCloseModal,
    t,
    toast,
    unblock,
    unblockIsDone,
    unblockIsFailed,
    unblockUserDeniedError,
  ]);

  if (state === 'blockable') {
    return (
      <PauseButton
        title={t('regular-pause-1')}
        description={t('on-chain-pause-paid-gas-2')}
        leftIcon={pauseIcon}
        isLoading={blockRunning}
        isDisabled={unblockRunning}
        onClick={(e) => {
          e.preventDefault();
          block.execute({});
        }}
      />
    );
  }
  if (state === 'selfBlocked') {
    return (
      <PauseButton
        title={t('regular-resume')}
        description={t('on-chain-pause-paid-gas-2')}
        leftIcon={playIcon}
        isLoading={unblockRunning}
        isDisabled={blockRunning}
        onClick={(e) => {
          e.preventDefault();
          unblock.execute({});
        }}
      />
    );
  }
  if (state === 'blocked') {
    return (
      <PauseButton
        title={t('regular-pause-1')}
        description={t('on-chain-pause-paid-gas-2')}
        leftIcon={pauseIcon}
        isDisabled
      />
    );
  }
  if (state === 'disabled') {
    return (
      <PauseButton
        title={t('regular-pause-1')}
        description={t('on-chain-pause-paid-gas-2')}
        leftIcon={pauseIcon}
        isDisabled
      />
    );
  }

  return null;
};
