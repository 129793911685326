import {
  Button,
  Flex,
  HStack,
  Icon,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  Tooltip,
  useClipboard,
} from '@chakra-ui/react';
import { useActiveFlowItem, useProviders } from '@kiroboio/fct-sdk';
import { useTranslations } from 'next-intl';
import { useCallback, useEffect, useState, type FC } from 'react';
import { CheckCircle, Copy } from 'react-feather';
import { useSendFeedbackStateModal } from '~/lib/hooks/useModals/useComplexModals';

// import { mixpanelAnalytics } from 'src/kirobo/utils/mixpanelAnalytics';

export const SendFeedbackModal: FC = () => {
  const t = useTranslations();
  const { isOpen, id: flowId, close } = useSendFeedbackStateModal();
  const { feedback } = useProviders();
  const {
    onCopy,
    hasCopied,
    value: ticket,
    setValue: setTicket,
  } = useClipboard('');

  const handleClose = useCallback(() => {
    close({ clear: true });
  }, [close]);

  // const {
  //   connectCmd,
  //   termsAndServices,
  //   modals: { feedbackModal },
  //   fctError,
  // } = useAccount();

  const { item: flowItem } = useActiveFlowItem({ id: flowId });
  // console.log('yui flowItem', flowItem);

  // const useFeedbackQuery = useFeedback();

  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [status, setStatus] = useState('');
  const { isRunning } = feedback.state;

  const giveFeedback = useCallback(async () => {
    const returnedData = await feedback.execute(async () => {
      return {
        params: {
          data: flowItem?.raw,
          service: 'publish',
          key: 'fct-beta-active', // TODO: Tal: get from server on login
        },
      };
    });
    // console.log('yui returnedData', returnedData);
    if (returnedData.error) {
      // TODO: Tal: returnedData.error struct with code, name, message
      setMessage(
        `Error code: ${(returnedData.error as any).code}, ${
          (returnedData.error as any).name || ''
        }.`
      );
      setErrorMessage(`Error message: ${(returnedData.error as any).message}.`);
      setStatus('error');
    } else {
      setMessage(t('thank-you-your-report-sent-successfully'));
      setTicket(returnedData.results?.id || t('no-id'));
      setStatus('success');
    }
  }, [feedback, flowItem?.raw, setTicket, t]);

  const handleSend = useCallback(() => {
    // console.log('yui handleSend');
    giveFeedback();
    // mixpanelAnalytics.track({
    //   label: 'Report_Bug_Send',
    //   payload: {
    //     fctId: fctError.fctId,
    //   },
    // });
    // useFeedbackQuery.mutate(
    //   {
    //     data: rawFctItem,
    //   },
    //   {
    //     onSuccess: (data) => {
    //       setMessage('Thank you, your report sent successfully.');
    //       setTicket(data._id);
    //       setStatus('success');
    //     },
    //     onError: (error: any) => {
    //       setMessage(`Error code: ${error.code}, ${error.name}.`);
    //       setErrorMessage(`Error message: ${error.message}.`);
    //       setStatus('error');
    //     },
    //   }
    // );
    // termsAndServices.accept(true);
  }, [giveFeedback]);

  useEffect(() => {
    if (isOpen) {
      setMessage('');
      setTicket('');
      setErrorMessage('');
      setStatus('');
    }
  }, [isOpen, setTicket]);

  return (
    <Modal size="2xl" isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay backdropFilter="blur(4px)" bg="whiteAlpha.200" />
      <ModalContent
        rounded="32px"
        bg="#F9FAFB"
        _dark={{ bg: '#0F151A' }}
        p="55px"
      >
        <ModalHeader>{t('report-a-bug')}</ModalHeader>
        <ModalCloseButton m="16" />
        <ModalBody fontSize="sm" px="0">
          <Stack spacing="14">
            <HStack align="flex-start">
              <Stack spacing="1">
                <Text as="span" fontWeight="normal">
                  {message ? (
                    <>
                      <Text fontWeight="normal">{message}</Text>
                      <Text as="span" fontWeight="normal">
                        {ticket ? (
                          <HStack>
                            <Text as="span" fontWeight="normal">
                              {t('ticket-number')}:
                            </Text>
                            <Tooltip
                              placement="bottom-start"
                              label="Copy Ticket"
                              aria-label="A tooltip"
                            >
                              <HStack
                                onClick={onCopy}
                                cursor="pointer"
                                spacing="0"
                                gap="11px"
                              >
                                <Text
                                  color="gray.500"
                                  fontSize="14px"
                                  fontWeight="500"
                                >
                                  {ticket}
                                </Text>
                                <Icon
                                  as={hasCopied ? CheckCircle : Copy}
                                  color={hasCopied ? 'green.500' : 'gray.500'}
                                  boxSize="16px"
                                />
                              </HStack>
                            </Tooltip>
                          </HStack>
                        ) : (
                          errorMessage
                        )}
                      </Text>
                    </>
                  ) : (
                    <>
                      {' '}
                      {t(
                        'you-are-about-to-provide-personal-info-by-sending-a-bug-report-to-us-you-are-agree-to-our'
                      )}{' '}
                      <Link
                        isExternal
                        href="/legal/FCT-Terms.pdf"
                        color="primary.500"
                      >
                        {t('terms-of-service')}
                      </Link>{' '}
                      {t('and')}{' '}
                      <Link
                        isExternal
                        href="/legal/FCT-Privacy.pdf"
                        color="primary.500"
                      >
                        {t('privacy-policy-0')}.
                      </Link>
                    </>
                  )}
                </Text>
              </Stack>
              <Spacer />
            </HStack>
            <HStack align="flex-start" w="full">
              <Stack w="full" spacing="4">
                <Flex justify="center" gap="6" flexWrap="wrap">
                  <Button
                    rounded="2xl"
                    colorScheme="gray"
                    onClick={handleClose}
                  >
                    {status === 'success' ? t('leave') : t('cancel')}
                  </Button>
                  {status !== 'success' && (
                    <Button
                      isLoading={isRunning}
                      _hover={{
                        bg: 'primary.400',
                      }}
                      rounded="2xl"
                      bg="primary.500"
                      variant="solid"
                      color="white"
                      onClick={handleSend}
                    >
                      {status === 'error'
                        ? t('send-again')
                        : t('confirm-and-send')}
                    </Button>
                  )}
                </Flex>
              </Stack>
            </HStack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
