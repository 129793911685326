'use client';

import { Text } from '@chakra-ui/react';
import { useFlowAlertActions } from '@kiroboio/fct-sdk';
import { useTranslations } from 'next-intl';
import type { FC } from 'react';
import { useCallback } from 'react';

import { DeleteAlert } from '~/lib/components/general/DeleteAlert';
import { useDeleteAlertStateModal } from '~/lib/hooks/useModals/useComplexModals';

export const DeleteAlertModal: FC = () => {
  const t = useTranslations();
  const { isOpen, id, close } = useDeleteAlertStateModal();

  const { remove } = useFlowAlertActions({
    id,
  });

  const handleRemove = useCallback(() => {
    remove.execute({});
  }, [remove]);

  const handleClose = useCallback(() => {
    close({ clear: true });
  }, [close]);

  return (
    <DeleteAlert
      isOpen={isOpen}
      onClose={handleClose}
      header={t('delete-intent-alert')}
      body={<Text>{t('are-you-sure-you-want-to-delete-this-alert')}</Text>}
      footer={{
        confirm: {
          onClick: handleRemove,
        },
      }}
    />
  );
};
