'use client';

import { IconButton, Link } from '@chakra-ui/react';
import type { FC, JSXElementConstructor, ReactElement } from 'react';

interface ISocialButton {
  name: string;
  link: string;
  icon: ReactElement<any, string | JSXElementConstructor<any>>;
  boxSize?: string;
  size?: string;
  colorScheme?: string;
  variant?: string;
}

export const SocialButton: FC<ISocialButton> = ({
  name,
  link,
  icon,
  boxSize,
  size,
  colorScheme,
  variant,
}) => {
  return (
    <Link href={link || undefined} isExternal>
      <IconButton
        rounded="8px"
        boxSize={boxSize || '48px'}
        size={size}
        icon={icon}
        aria-label={name}
        colorScheme={colorScheme}
        variant={variant}
      />
    </Link>
  );
};
