import { CacheProvider } from '@chakra-ui/next-js';
import {
  ChakraProvider,
  ColorModeScript,
  cookieStorageManager,
} from '@chakra-ui/react';

import customTheme from '~/lib/styles/theme/index';

interface ChakraProps {
  children: React.ReactNode;
}

export const Chakra = ({ children }: ChakraProps) => {
  return (
    <>
      <ColorModeScript
        initialColorMode={customTheme.config?.initialColorMode}
        type="cookie"
      />
      <CacheProvider>
        <ChakraProvider
          colorModeManager={cookieStorageManager}
          theme={customTheme}
        >
          {children}
        </ChakraProvider>
      </CacheProvider>
    </>
  );
};
