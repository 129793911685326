import { Box, Text, useColorMode } from '@chakra-ui/react';
import Image from 'next/image';
import type { FC } from 'react';

interface SplashScreenProps {
  narrowMode?: boolean;
}

export const SplashScreen: FC<SplashScreenProps> = ({ narrowMode }) => {
  const { colorMode } = useColorMode();

  const narrowLogo = '/icons/intentable_icon.png';

  const fullLogo =
    colorMode === 'light'
      ? '/logos/intentable_light_logo.png'
      : '/logos/intentable_dark_logo.png';

  return (
    <Box
      visibility="visible"
      height="45px"
      width={narrowMode ? '45px' : '154px'}
      transition="all 0.4s ease-out"
      position="relative"
    >
      <Image
        height="45"
        width={narrowMode ? '45' : '154'}
        priority
        alt="logo"
        src={narrowMode ? narrowLogo : fullLogo}
      />
      <Text
        position="absolute"
        right="0"
        bottom="-8px"
        fontSize="12px"
        fontWeight="600"
        style={{
          color: '#63b3ed',
        }}
      >
        BETA
      </Text>
    </Box>
  );
};
