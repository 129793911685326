import {
  Button,
  Collapse,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFlowDraftActions, useFlowDraftItem } from '@kiroboio/fct-sdk';
import { Additem } from 'iconsax-react';
import { useTranslations } from 'next-intl';
import { useCallback, useMemo, type FC } from 'react';
import { useForm } from 'react-hook-form';
import { object as yupObject, string as yupString } from 'yup';

import { useDuplicateDraftStateModal } from '~/lib/hooks/useModals/useComplexModals';

const generateDupDraftName = (name: string) => {
  const reg = /#(\d+)$/;
  const match = reg.exec(name);
  if (match) {
    const num = parseInt(match[1], 10);
    return `${name.replace(reg, '')}#${num + 1}`;
  }
  return `${name}#1`;
};

export const DuplicateDraftModal: FC = () => {
  const t = useTranslations();
  // const { currentDraftId } = useCurrentDraftId();
  // const {
  //   snapshot: { draft, isOpen },
  //   onClose,
  //   clear,
  // } = useDuplicateDraftModal({ draftId: currentDraftId });

  const { isOpen, id, close } = useDuplicateDraftStateModal();
  const { item: draft } = useFlowDraftItem({ id });

  const { create } = useFlowDraftActions({
    id,
  });

  const { isRunning, stage } = create.state;
  const isDone = stage === 'done';

  const {
    register,
    watch,
    reset,
    setValue,
    formState: {
      errors: { name: nameError },
    },
    handleSubmit,
  } = useForm({
    defaultValues: {
      name: generateDupDraftName(draft?.fmt.name || ''),
    },
    resolver: yupResolver(
      yupObject().shape({
        name: yupString().required(),
      })
    ),
  });

  useMemo(() => {
    if (isOpen) {
      setValue('name', generateDupDraftName(draft?.fmt.name || ''));
    }
  }, [isOpen, setValue, draft?.fmt.name]);

  useMemo(() => {
    if (isDone) {
      create.reset();
      close({ clear: true });
    }
  }, [isDone, close, create]);

  const cleanup = !isOpen;
  useMemo(() => {
    if (cleanup) {
      reset();
    }
  }, [cleanup, reset]);

  const name = watch('name');

  // const handleDuplicate = useCallback(async () => {
  //   const { name } = draft.raw;
  //   const altName = name.includes('-Copy-') ? name.split('-Copy-')[0] : name;
  //   create.execute({
  //     data: draft.raw.data,
  //     name: `${altName}-Copy-${Math.floor(Math.random() * 1000000)}`,
  //   });
  // }, [create, draft.raw]);

  const handleDuplicate = useCallback(async () => {
    create.execute({
      data: draft?.raw.data,
      name,
    });
  }, [name, draft?.raw, create]);

  const nameInput = register('name');

  const handleClose = useCallback(() => {
    close({ clear: true });
  }, [close]);

  return (
    <Modal
      id="change-draft-name"
      motionPreset="slideInBottom"
      size="md"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <ModalOverlay bg="rgba(51,57,66,0.5)" opacity="0.5" />

      <ModalContent
        rounded="3xl"
        bg="#F9FAFB"
        _dark={{ bg: '#0F151A' }}
        p="45px"
      >
        <ModalHeader p="0">
          <HStack w="full" justify="flex-end">
            <ModalCloseButton pos="initial" />
          </HStack>
        </ModalHeader>
        <ModalBody px="0" py="4">
          <Stack
            as="form"
            onSubmit={handleSubmit(handleDuplicate)}
            align="center"
            spacing="10"
          >
            <Stack align="center" textAlign="center">
              <Icon
                as={Additem}
                variant="Bulk"
                color="primary.500"
                boxSize="68px"
                transition="all 120ms"
              />
              <Stack>
                <Text fontSize="md">
                  {t('duplicate-0')}{' '}
                  <Text as="span" fontWeight="semibold">
                    {' '}
                    {draft?.fmt.name}
                  </Text>
                </Text>
                <Stack spacing="1" color="gray.400">
                  <Text noOfLines={1} fontSize="xs">
                    {t('updated')}: {draft?.fmt.updatedAt}
                  </Text>
                </Stack>
              </Stack>
            </Stack>
            <Stack spacing={4}>
              <FormControl>
                <FormLabel fontSize="sm" fontWeight="normal" color="gray.400">
                  {t('new-name')}
                </FormLabel>
                <Input
                  variant="flushed"
                  size="lg"
                  fontWeight="bold"
                  fontSize="2xl"
                  {...nameInput}
                />
                <Collapse in={Boolean(nameError)}>
                  <FormHelperText color="orange.500" fontSize="sm" mb="4">
                    {nameError?.message}
                  </FormHelperText>
                </Collapse>
              </FormControl>

              <Button
                size="lg"
                isLoading={isRunning}
                leftIcon={<Additem />}
                type="submit"
                isDisabled={Boolean(nameError)}
                bg="blue.500"
                colorScheme="blue"
                color="white"
                rounded="18px"
              >
                {nameError ? t('fix-errors') : t('duplicate-0')}
              </Button>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
