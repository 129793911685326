import type { AlertDialogProps } from '@chakra-ui/react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import type { FC } from 'react';
import React, { memo } from 'react';

interface DeleteAlertProps {
  header?: React.ReactNode;
  body?: React.ReactNode;
  footer: {
    cancel?: {
      label?: string;
      onClick?: () => void;
    };
    confirm: {
      label?: string;
      onClick?: () => void;
    };
  };
}
// opposite of #0F151A is #F9FAFB
const DeleteAlert2: FC<
  Omit<AlertDialogProps, 'leastDestructiveRef' | 'children'> & DeleteAlertProps
> = ({ header, body, footer, ...props }) => {
  const t = useTranslations();
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      // isCentered
      {...props}
    >
      <AlertDialogOverlay bg="rgba(51,57,66,0.5)" />

      <AlertDialogContent
        rounded="3xl"
        p="5"
        bg="#F9FAFB"
        _dark={{ bg: '#0F151A' }}
      >
        <AlertDialogHeader>{header}</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>{body}</AlertDialogBody>
        <AlertDialogFooter>
          <Button
            ref={cancelRef}
            colorScheme="gray"
            onClick={() => {
              footer.cancel?.onClick?.();
              props.onClose();
            }}
          >
            {footer.cancel?.label ?? t('no')}
          </Button>
          <Button
            colorScheme="red"
            ml={3}
            onClick={() => {
              footer.confirm.onClick?.();
              props.onClose();
            }}
          >
            {footer.confirm.label ?? t('yes')}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export const DeleteAlert = memo(DeleteAlert2);
