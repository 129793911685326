/* eslint-disable react/prop-types */

import {
  Box,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import Lottie from 'lottie-react';
import { useTranslations } from 'next-intl';
import { useCallback, type FC } from 'react';

import successAnimationData from '~/assets/lottie/error-animation.json';
import { useFlowErrorsStateModal } from '~/lib/hooks/useModals/useComplexModals';

export const FlowErrorsModal: FC = () => {
  const t = useTranslations();
  const { isOpen: isOpenFlowErrors, close, props } = useFlowErrorsStateModal();
  const handleClose = useCallback(() => {
    close({});
  }, [close]);

  return (
    <Modal size="lg" isOpen={isOpenFlowErrors} onClose={handleClose}>
      <ModalOverlay bg="rgba(51,57,66,0.5)" />
      <ModalContent rounded="3xl" color="white" bg="#0F151A" p="42px">
        <ModalBody p="0">
          <HStack align="center" spacing="6">
            <Stack align="center" justify="center" h="60px" w="60px">
              <Box
                as={Lottie}
                w="80px"
                animationData={successAnimationData}
                loop={false}
              />
            </Stack>
            <Stack>
              <Heading size="md">
                {t('looks-like-you-have-some-errors')}
              </Heading>
              <Text fontWeight="normal" fontSize="sm" color="gray.400">
                {props.error
                  ? props.error
                  : t('go-back-and-fix-all-the-errors')}
              </Text>
            </Stack>
            <ModalCloseButton pos="initial" />
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
