'use client';

/* eslint-disable sonarjs/no-unused-collection */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-nested-ternary */

import type { StackProps } from '@chakra-ui/react';
import { Button, Collapse, HStack, Stack, Text } from '@chakra-ui/react';
import { useState, type FC } from 'react';
import { v4 as genUniqueId } from 'uuid';

const LINES_LIMIT = 3;

export const VersionWhatsNewCard: FC<
  StackProps & { releaseNotes: string[] }
> = ({ releaseNotes }) => {
  const [show, setShow] = useState(false);

  if (releaseNotes.length === 0) return null;

  const lessNotes = [];
  const restNotes = [];

  for (let i = 0; i < LINES_LIMIT && i < releaseNotes.length; i++) {
    lessNotes.push(releaseNotes[i]);
  }

  for (let i = lessNotes.length; i < releaseNotes.length; i++) {
    restNotes.push(releaseNotes[i]);
  }

  return (
    <Stack
      rounded="20px"
      padding="32px"
      gap="32px"
      align="center"
      bg="white"
      w="full"
      minH="295px"
      _dark={{
        border: '1px',
        bg: 'gray.900',
        borderColor: 'gray.800',
      }}
    >
      <HStack justify="space-between" w="full">
        <Text fontSize="24px" fontWeight="semibold">
          What's New
        </Text>
      </HStack>

      <Stack gap="16px" w="full">
        {lessNotes.map((note) => (
          <Text key={genUniqueId()}>{note}</Text>
        ))}
        <Collapse in={show}>
          <Stack gap="16px" w="full">
            {restNotes.map((note) => (
              <Text key={genUniqueId()}>{note}</Text>
            ))}
          </Stack>
        </Collapse>
        {releaseNotes.length > LINES_LIMIT && (
          <Button
            variant="link"
            color="blue.400"
            size="sm"
            onClick={() => setShow((prev) => !prev)}
          >
            Show {show ? 'Less' : 'More'}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
