/* eslint-disable sonarjs/no-duplicate-string */
import { useToast } from '@chakra-ui/react';
import { useActiveFlowActions } from '@kiroboio/fct-sdk';
import { useTranslations } from 'next-intl';
import { useEffect } from 'react';
import type { PauseButtonType } from './FCTPauseButton';
import { PauseButton } from './PauseButton';

export const FCTVirtualPauseButton = ({
  id,
  state,
  pauseIcon,
  playIcon,
  onCloseModal,
}: PauseButtonType) => {
  const toast = useToast();
  // const { fct } = useAccount();
  const { pause, resume } = useActiveFlowActions({ id });

  const pauseRunning = pause.state.isRunning;
  const pauseIsDone = pause.state.stage === 'done';
  const pauseIsFailed = pause.state.status === 'failed';
  // const pauseIsError = pause.state.error;

  const resumeRunning = resume.state.isRunning;
  const resumeIsDone = resume.state.stage === 'done';
  const resumeIsFailed = resume.state.status === 'failed';
  // const resumeIsError = resume.state.error;

  const t = useTranslations();

  useEffect(() => {
    if (pauseIsDone && !pauseIsFailed) {
      pause.reset();
      onCloseModal();
      toast({
        position: 'bottom-right',
        status: 'info',
        title: t('intent-paused'),
        description: t('intent-was-paused-virtually'),
        duration: 9000,
        isClosable: true,
      });
    }
    if (pauseIsFailed) {
      pause.reset();
      toast({
        position: 'bottom-right',
        status: 'error',
        title: t('error-0'),
        description: t('error-occurred-while-pausing-0'),
        duration: 9000,
        isClosable: true,
      });
    }
  }, [pauseIsDone, pauseIsFailed, onCloseModal, toast, pause, t]);

  useEffect(() => {
    if (resumeIsDone && !resumeIsFailed) {
      resume.reset();
      onCloseModal();
      toast({
        position: 'bottom-right',
        status: 'info',
        title: t('intent-resumed'),
        description: t('intent-was-resumed-virtually'),
        duration: 9000,
        isClosable: true,
      });
    }
    if (resumeIsFailed) {
      resume.reset();
      toast({
        position: 'bottom-right',
        status: 'error',
        title: t('error-0'),
        description: t('error-occurred-while-pausing-0'),
        duration: 9000,
        isClosable: true,
      });
    }
  }, [onCloseModal, resume, resumeIsDone, resumeIsFailed, t, toast]);

  if (state === 'blockable') {
    return (
      <PauseButton
        title={t('virtual-pause')}
        description={t('virtual-pause-gas-free')}
        leftIcon={pauseIcon}
        isLoading={pauseRunning}
        isDisabled={false}
        onClick={(e) => {
          e.preventDefault();
          pause.execute({});
          // mixpanelAnalytics.track({
          //   label: 'Virtual_Pause',
          //   payload: {
          //     fctId: id,
          //   },
          // });
        }}
      />
    );
  }
  if (state === 'selfBlocked') {
    return (
      <PauseButton
        title={t('virtual-resume')}
        description={t('virtual-pause-gas-free')}
        leftIcon={playIcon}
        isLoading={resumeRunning}
        isDisabled={false}
        onClick={(e) => {
          e.preventDefault();
          resume.execute({});
          // mixpanelAnalytics.track({
          //   label: 'Virtual_Resume',
          //   payload: {
          //     fctId: id,
          //   },
          // });
        }}
      />
    );
  }
  if (state === 'blocked') {
    return (
      <PauseButton
        title={t('virtual-pause')}
        description={t('virtual-pause-gas-free')}
        leftIcon={pauseIcon}
        isDisabled
      />
    );
  }
  if (state === 'disabled') {
    return (
      <PauseButton
        title={t('virtual-pause')}
        description={t('virtual-pause-gas-free')}
        leftIcon={pauseIcon}
        isDisabled
      />
    );
  }

  return null;
};
