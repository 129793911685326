/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useSession } from '@kiroboio/fct-sdk';
import { useTranslations } from 'next-intl';
import { useCallback, useEffect, type FC } from 'react';
import { useConnectHandler } from '~/lib/hooks/general/useConnectHandler';
import { useConnectWalletStateModal } from '~/lib/hooks/useModals/useComplexModals';
import { useUser } from '~/lib/hooks/useUser/useUser';

export const ConnectWalletModal: FC = () => {
  const { isOpen, close } = useConnectWalletStateModal();
  const t = useTranslations();
  const { connectHandler } = useConnectHandler();
  const user = useUser();

  const handleConnect = useCallback(() => {
    connectHandler();
  }, [connectHandler]);

  const handleClose = useCallback(() => {
    close({});
  }, [close]);

  // const { status } = useSession();

  const { isSigningIn } = useUser();

  useEffect(() => {
    if (user.isSignedIn) {
      handleClose();
    }
  }, [user.isSignedIn, handleClose]);

  return (
    <Modal size="md" isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay backdropFilter="blur(4px)" bg="whiteAlpha.200" />
      <ModalContent
        rounded="20px"
        bg="#F9FAFB"
        _dark={{ bg: '#0F151A' }}
        p="54px"
        zIndex="0"
      >
        <ModalCloseButton m="2" />
        <ModalBody px="0">
          <Stack alignItems="center" spacing="0" gap="32px">
            <Stack alignItems="center" h="87px" spacing="0" gap="32px">
              <Text fontWeight="400" fontSize="14px">
                {t('please-connect-your-wallet')}
              </Text>
              <Button
                isLoading={isSigningIn}
                onClick={handleConnect}
                size="lg"
                colorScheme="blue"
                bg="blue.400"
                color="white"
                rounded="full"
                p="8px 24px"
                fontSize="18px"
                fontWeight="600"
                textTransform="capitalize"
                type="submit"
              >
                {t('connect-wallet')}
              </Button>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
