import type { ButtonProps } from '@chakra-ui/react';
import {
  Button,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import type { AppsDefiDataItemType } from '@kiroboio/fct-sdk';
import { useTranslations } from 'next-intl';
import { useCallback, useEffect, useState } from 'react';
import { useRouter } from '~/lib/hooks/general/useRouter';
import { useYieldsAppsStateModal } from '~/lib/hooks/useModals/useComplexModals';
// import { CustomSwitchButtons } from '../general/CustomSwitchButtons';

// const New = () => (
//   <Stack
//     fontSize="9px"
//     fontWeight="700"
//     spacing="0"
//     transform="rotate(-25deg)"
//     position="absolute"
//     right="8px"
//   >
//     <Text px="3px" rounded="3px" bg="green.300" color="black">
//       NEW
//     </Text>
//   </Stack>
// );

const ComingSoon = () => (
  <Stack
    fontSize="9px"
    fontWeight="700"
    spacing="0"
    transform="rotate(-25deg)"
    position="absolute"
    right="8px"
  >
    <Text>COMING</Text>
    <Text rounded="3px" bg="blue.300" color="black">
      SOON
    </Text>
  </Stack>
);

const ComingSoonButton = ({ ...props }: ButtonProps) => (
  <Button
    isDisabled
    _disabled={{
      opacity: 0.6,
      cursor: 'not-allowed',
    }}
    iconSpacing="10px"
    {...props}
  >
    <Text>{props.children}</Text>
    <ComingSoon />
  </Button>
);

export const YieldsAppsModal = () => {
  const t = useTranslations();

  // const searchParams = useSearchParams();
  // const basedParams = searchParams.get('based');
  const basedParams = 'apy';

  const {
    isOpen,
    close,
    props: { dataItem, yieldsAppsRowId },
  } = useYieldsAppsStateModal();
  const [index, setIndex] = useState(0);
  // const [tradingCategories, setTradingCategories] = useState<
  //   {
  //     name: string;
  //   }[]
  // >();

  useEffect(() => {
    if (basedParams === 'apy') {
      setIndex(2);
      // setTradingCategories([{ name: 'Yield Based Trading' }]);
    } else if (basedParams === 'indicator') {
      setIndex(1);
      // setTradingCategories([{ name: 'Indicator Based Trading' }]);
    } else {
      setIndex(0);
      // setTradingCategories([{ name: 'Orders Based Trading' }]);
    }
  }, [basedParams]);

  const navigation = useRouter();

  const handleClose = useCallback(() => {
    close({});
  }, [close]);

  const quoteTokenAddress = dataItem?.raw.pool.quoteToken;
  const quoteTokenSymbol = dataItem?.raw.underlyingTokens.find(
    (token: AppsDefiDataItemType['raw']['underlyingTokens'][0]) =>
      token.address === quoteTokenAddress
  )?.symbol;

  const investedInToken = dataItem?.fmt?.underlyingTokens?.find(
    (token: AppsDefiDataItemType['raw']['underlyingTokens'][0]) =>
      token?.symbol !== quoteTokenSymbol
  );

  return (
    <Modal size="4xl" isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay backdropFilter="blur(4px)" bg="whiteAlpha.200" />
      <ModalContent
        rounded="24px"
        bg="#F9FAFB"
        _dark={{ bg: '#0F151A' }}
        p="60px"
        gap="32px"
      >
        <ModalHeader p="0">
          <Stack spacing="0">
            <Text
              color="pink.400"
              textAlign="center"
              fontSize="130%"
              fontWeight="600"
            >
              {t('yield-based-trading')}
            </Text>
            <HStack justify="center">
              <HStack spacing="4px">
                <Image
                  src={investedInToken?.thumbnail || ''}
                  width="24px"
                  height="24px"
                  rounded="full"
                  alt={`${investedInToken?.symbol} / ${quoteTokenSymbol}`}
                />
                <Text>{`${investedInToken?.symbol} / ${quoteTokenSymbol}`}</Text>
              </HStack>
              {/* <Text color="blue.300" _dark={{ color: 'blue.100' }}> */}
              <HStack spacing="0">
                <Text>{t('intents')}</Text>
                <Text color="orange.400">*</Text>
              </HStack>
            </HStack>
          </Stack>
        </ModalHeader>
        <ModalCloseButton m="16px" />
        <ModalBody justifyContent="center" fontSize="sm" px="0">
          <Stack spacing="28px">
            {/* <CustomSwitchButtons
              index={index}
              // setIndex={setIndex} // Add back when you need to switch between tabs
              forceIsActive // Remove when you need to switch between tabs
              alignSelf="center"
              items={tradingCategories || []}
            /> */}
            {index === 0 && (
              <HStack alignItems="flex-start" justify="center" gap="90px">
                <Stack gap="12px" w="full">
                  <Text
                    textAlign="center"
                    fontSize="18px"
                    fontWeight="700"
                    // textTransform="uppercase"
                    color="green.400"
                  >
                    BUY {investedInToken?.symbol}{' '}
                    {/* (with {quoteTokenSymbol}) */}
                  </Text>
                  <Stack>
                    {dataItem?.fmt.apps
                      ?.filter(
                        (app: AppsDefiDataItemType['raw']['apps'][0]) =>
                          app?.order?.offchainTrigger === false &&
                          app?.order?.type === 'buy' &&
                          app?.appType === 'yield'
                      )
                      .map((app: AppsDefiDataItemType['raw']['apps'][0]) => {
                        if (app?.disabled)
                          return (
                            <ComingSoonButton
                              key={String(app?.id) + String(app?.mode)}
                            >
                              {app?.title}
                            </ComingSoonButton>
                          );
                        return (
                          <Button
                            key={String(app?.id) + String(app?.mode)}
                            onClick={() => {
                              navigation.push(
                                `/app/yields/${yieldsAppsRowId}?aid=${app?.id}&m=${app?.mode}`
                              );
                              handleClose();
                            }}
                          >
                            {app?.title}
                          </Button>
                        );
                      })}
                    {/* <ComingSoonButton>Stop-Limit</ComingSoonButton>
                    <ComingSoonButton>Limit Order</ComingSoonButton>
                    <ComingSoonButton>Trailing Stop-Loss</ComingSoonButton> */}
                  </Stack>
                </Stack>
                <Stack gap="12px" w="full">
                  <Text
                    textAlign="center"
                    fontSize="18px"
                    fontWeight="700"
                    // textTransform="uppercase"
                    color="red.400"
                  >
                    SELL {investedInToken?.symbol}{' '}
                    {/* (for {quoteTokenSymbol}) */}
                  </Text>
                  <Stack>
                    {dataItem?.fmt.apps
                      ?.filter(
                        (app: AppsDefiDataItemType['raw']['apps'][0]) =>
                          app?.order?.offchainTrigger === false &&
                          app?.order?.type === 'sell' &&
                          app?.appType === 'yield'
                      )
                      .map((app: AppsDefiDataItemType['raw']['apps'][0]) => {
                        if (app?.disabled)
                          return (
                            <ComingSoonButton
                              key={String(app?.id) + String(app?.mode)}
                            >
                              {app?.title}
                            </ComingSoonButton>
                          );
                        return (
                          <Button
                            key={String(app?.id) + String(app?.mode)}
                            onClick={() => {
                              navigation.push(
                                `/app/yields/${yieldsAppsRowId}?aid=${app?.id}&m=${app?.mode}`
                              );
                              handleClose();
                            }}
                          >
                            {app?.title}
                          </Button>
                        );
                      })}
                    {/* <ComingSoonButton>Stop-Limit</ComingSoonButton>
                    <ComingSoonButton>Limit Order</ComingSoonButton>
                    <ComingSoonButton>Trailing Stop-Loss</ComingSoonButton> */}
                  </Stack>
                </Stack>
              </HStack>
            )}
            {index === 1 && (
              <HStack alignItems="flex-start" justify="center" gap="90px">
                <Stack gap="12px" w="full">
                  <Text
                    textAlign="center"
                    fontSize="18px"
                    fontWeight="700"
                    // textTransform="uppercase"
                    color="green.400"
                  >
                    BUY {investedInToken?.symbol}{' '}
                    {/* (with {quoteTokenSymbol}) */}
                  </Text>
                  <Stack>
                    {dataItem?.fmt.apps
                      ?.filter(
                        (app: AppsDefiDataItemType['raw']['apps'][0]) =>
                          app?.order?.offchainTrigger === true &&
                          app?.order?.type === 'buy' &&
                          app?.appType === 'yield'
                      )
                      .map((app: AppsDefiDataItemType['raw']['apps'][0]) => {
                        if (app?.disabled)
                          return (
                            <ComingSoonButton
                              key={String(app?.id) + String(app?.mode)}
                            >
                              {app?.title}
                            </ComingSoonButton>
                          );
                        return (
                          <Button
                            key={String(app?.id) + String(app?.mode)}
                            onClick={() => {
                              navigation.push(
                                `/app/yields/${yieldsAppsRowId}?aid=${app?.id}&m=${app?.mode}`
                              );
                              handleClose();
                            }}
                          >
                            {app?.title}
                          </Button>
                        );
                      })}
                    {/* <ComingSoonButton>Moving Average</ComingSoonButton>
                    <ComingSoonButton>RSI</ComingSoonButton>
                    <ComingSoonButton>MACD</ComingSoonButton> */}
                  </Stack>
                </Stack>
                <Stack gap="12px" w="full">
                  <Text
                    textAlign="center"
                    fontSize="18px"
                    fontWeight="700"
                    // textTransform="uppercase"
                    color="red.400"
                  >
                    SELL {investedInToken?.symbol}{' '}
                    {/* (for {quoteTokenSymbol}) */}
                  </Text>
                  <Stack>
                    {dataItem?.fmt.apps
                      ?.filter(
                        (app: AppsDefiDataItemType['raw']['apps'][0]) =>
                          app?.order?.offchainTrigger === true &&
                          app?.order?.type === 'sell' &&
                          app?.appType === 'yield'
                      )
                      .map((app: AppsDefiDataItemType['raw']['apps'][0]) => {
                        if (app?.disabled)
                          return (
                            <ComingSoonButton
                              key={String(app?.id) + String(app?.mode)}
                            >
                              {app?.title}
                            </ComingSoonButton>
                          );
                        return (
                          <Button
                            key={String(app?.id) + String(app?.mode)}
                            onClick={() => {
                              navigation.push(
                                `/app/yields/${yieldsAppsRowId}?aid=${app?.id}&m=${app?.mode}`
                              );
                              handleClose();
                            }}
                          >
                            {app?.title}
                          </Button>
                        );
                      })}
                    {/* <ComingSoonButton>Moving Average</ComingSoonButton>
                    <ComingSoonButton>RSI</ComingSoonButton>
                    <ComingSoonButton>MACD</ComingSoonButton> */}
                  </Stack>
                </Stack>
              </HStack>
            )}
            {index === 2 && (
              <HStack alignItems="flex-start" justify="center" gap="90px">
                <Stack gap="12px" w="full">
                  <Text
                    textAlign="center"
                    fontSize="18px"
                    fontWeight="700"
                    // textTransform="uppercase"
                    color="green.400"
                  >
                    {t('buy-lp-token')}
                  </Text>
                  <Stack>
                    {dataItem?.fmt.apps
                      ?.filter(
                        (app: AppsDefiDataItemType['raw']['apps'][0]) =>
                          app?.order?.offchainTrigger === false &&
                          app?.order?.type === 'buy' &&
                          app?.appType === 'yield'
                      )
                      .map((app: AppsDefiDataItemType['raw']['apps'][0]) => {
                        if (app?.disabled)
                          return (
                            <ComingSoonButton
                              key={String(app?.id) + String(app?.mode)}
                            >
                              {app?.title}
                            </ComingSoonButton>
                          );
                        return (
                          <Button
                            key={String(app?.id) + String(app?.mode)}
                            onClick={() => {
                              navigation.push(
                                `/app/yields/${yieldsAppsRowId}?aid=${app?.id}&m=${app?.mode}`
                              );
                              handleClose();
                            }}
                          >
                            {app?.title}
                          </Button>
                        );
                      })}
                    {/* <ComingSoonButton>Stop-Limit</ComingSoonButton>
                    <ComingSoonButton>Limit Order</ComingSoonButton>
                    <ComingSoonButton>Trailing Stop-Loss</ComingSoonButton> */}
                  </Stack>
                </Stack>
                <Stack gap="12px" w="full">
                  <Text
                    textAlign="center"
                    fontSize="18px"
                    fontWeight="700"
                    // textTransform="uppercase"
                    color="red.400"
                  >
                    {t('sell-lp-token')}
                  </Text>
                  <Stack>
                    {dataItem?.fmt.apps
                      ?.filter(
                        (app: AppsDefiDataItemType['raw']['apps'][0]) =>
                          app?.order?.offchainTrigger === false &&
                          app?.order?.type === 'sell' &&
                          app?.appType === 'yield'
                      )
                      .map((app: AppsDefiDataItemType['raw']['apps'][0]) => {
                        if (app?.disabled)
                          return (
                            <ComingSoonButton
                              key={String(app?.id) + String(app?.mode)}
                            >
                              {app?.title}
                            </ComingSoonButton>
                          );
                        return (
                          <Button
                            key={String(app?.id) + String(app?.mode)}
                            onClick={() => {
                              navigation.push(
                                `/app/yields/${yieldsAppsRowId}?aid=${app?.id}&m=${app?.mode}`
                              );
                              handleClose();
                            }}
                          >
                            {app?.title}
                          </Button>
                        );
                      })}
                    {/* <ComingSoonButton>Stop-Limit</ComingSoonButton>
                    <ComingSoonButton>Limit Order</ComingSoonButton>
                    <ComingSoonButton>Trailing Stop-Loss</ComingSoonButton> */}
                  </Stack>
                </Stack>
              </HStack>
            )}
          </Stack>
        </ModalBody>
        <ModalFooter justifyContent="center" p="0">
          <Text fontSize="17px" fontWeight="600" color="orange.400">
            *{t('yield-intents-are-mev-protected')}.
          </Text>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
