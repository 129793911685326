/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable complexity */
/* eslint-disable react/prop-types */
import {
  Button,
  Checkbox,
  Collapse,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import type { NFTItemType } from '@kiroboio/fct-sdk';
import {
  service,
  useNFTActions,
  useNFTItem,
  useVault,
  useWallet,
} from '@kiroboio/fct-sdk';
import { Box as BoxIcon, EmptyWallet } from 'iconsax-react';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { object, string as yupString } from 'yup';

import { useTranslations } from 'next-intl';
import { useSendNftStateModal } from '~/lib/hooks/useModals/useComplexModals';
import { NFTImage } from '../flow/Nfts/NFTImage';
import { TextOrShortened } from '../general/TextOrShortened';

interface FormInputs {
  to: string;
  amount: string;
  nft: NFTItemType;
}

// eslint-disable-next-line no-unused-vars
export const SendNftModal = () => {
  const t = useTranslations();
  const VAULT_NAME = t('runner');
  const WALLET_NAME = t('wallet');

  const {
    id,
    mutate,
    isOpen,
    close,
    props: {
      account,
      max,
      betweenWallets,
      showDetails,
      to: toProp,
      amount: amountProp,
    },
  } = useSendNftStateModal();

  const toggleMax = useCallback(() => {
    mutate((prev) => ({ ...prev, max: !prev.max }));
  }, [mutate]);

  const toggleBetweenWallets = useCallback(() => {
    mutate((prev) => ({ ...prev, betweenWallets: !prev.betweenWallets }));
  }, [mutate]);

  const toggleShowDetails = useCallback(() => {
    mutate((prev) => ({ ...prev, showDetails: !prev.showDetails }));
  }, [mutate]);

  const updateInputs = useCallback(
    ({ amount, to }: { amount: string; to: string }) =>
      mutate((prev) => ({ ...prev, amount, to })),
    [mutate]
  );

  const {
    data: {
      fmt: { address: vaultAddress },
    },
  } = useVault();

  const {
    data: {
      fmt: { address: walletAddress },
    },
  } = useWallet();

  const { transfer } = useNFTActions({ account, id });
  // const NFTList = useNFTList({ account });
  // const nft = NFTList.map[id];
  const { item: nft } = useNFTItem({ account, id });
  const { isRunning, stage } = transfer.state;

  const {
    contract_type: protocol,
    amount: nftAmount,
    symbol,
    name,
  } = nft?.fmt || {
    protocol: '',
    nftAmount: '',
    symbol: '',
    name: '',
  };

  const { token_address: addressRaw, token_id: tokenIdRaw } = nft?.raw || {
    addressRaw: '',
    tokenIdRaw: '',
  };

  const toast = useToast();
  const color = useColorModeValue('black', 'white');
  const bgColor = useColorModeValue('#F7FAFC', '#080B0E');

  const validationSchema = object().shape({
    to: yupString()
      .trim()
      .test('required', t('address-is-a-required-field'), (value) => {
        if (betweenWallets) return true;
        return value !== undefined && value !== null && value !== '';
      })
      .test(
        'address-wrong',
        t('wrong-address'),
        function validateAddress(value) {
          if (betweenWallets) return true;
          try {
            const transformed = service.formatting.prebuild.formatAddress({
              address: value || '',
              service: '',
              name: '',
            });
            return transformed !== '-';
          } catch {
            return false;
          }
        }
      ),
    amount: yupString()
      .test('required', t('amount-is-a-required-field'), (value) => {
        if (max) return true;
        return value !== undefined && value !== null && value !== '';
      })
      .test(
        'is greater than zero',
        t('amount-must-be-greater-than-zero'),
        (value) => {
          if (max) return true;
          return Number(value) > 0;
        }
      )
      .test('is whole number', t('amount-must-be-whole-number'), (value) => {
        if (max) return true;
        return Number.isInteger(Number(value));
      })
      .test(
        'less than or equal to amount',
        t('must-be-less-than-or-equal-to-amount'),
        (value) => {
          if (max) return true;
          return Number(value) <= Number(nftAmount);
        }
      ),
  });

  const {
    handleSubmit,
    setValue,
    reset,
    formState,
    watch,
    clearErrors,
    register,
  } = useForm<FormInputs>({
    defaultValues: {
      to: toProp,
      amount: amountProp,
    },
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (isOpen) {
      reset();
      setValue('amount', amountProp);
      setValue('to', toProp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, reset, setValue]);

  const isConfirming = stage === 'confirming';
  useMemo(() => {
    if (isConfirming) {
      close({ clear: true });
    }
  }, [isConfirming, close]);

  const amount = watch('amount');
  const to = watch('to');

  useEffect(() => {
    updateInputs({ amount, to });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, to]);

  const { errors } = formState;

  const isValid = Object.keys(errors).length === 0;

  const toastTransaction = ({
    title,
    message,
    error,
  }: {
    title: string;
    message: string;
    error: any;
  }) => {
    let errorMessage = error?.message?.split("'{")[1];
    try {
      // console.log(errorMessage);
      errorMessage = JSON.parse(`{${errorMessage.slice(0, -1)}`);
      errorMessage = errorMessage?.message;
    } catch {
      errorMessage = error?.message;
    }
    toast({
      title,
      description: error ? error.reason || errorMessage : message,
      status: error ? 'error' : 'success',
      duration: 9000,
      isClosable: true,
    });
  };

  const transferNfts = async () => {
    const res = await transfer.execute(async () => {
      return {
        params: {
          amount: max ? 'max' : amount,
          to: betweenWallets ? undefined : to,
        },
      };
    });
    toastTransaction({
      title: `${symbol} ${t('transfer')}`,
      message: t('transfer-completed-successfully'),
      error: res.error,
    });
  };

  function handleSend() {
    transferNfts();
  }

  if (isOpen === false) return null;

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        close();
      }}
      isCentered
      motionPreset="slideInBottom"
    >
      <ModalOverlay backdropFilter="blur(4px)" bg="whiteAlpha.200" />
      <ModalContent w="auto" rounded="24px" p="60px" maxW="full" bg={bgColor}>
        <ModalBody p="0">
          <Stack p="16px" alignItems="center" w="400px">
            <HStack justify="space-between" alignItems="center" w="full" mb="4">
              <HStack>
                {account === 'wallet' ? (
                  <Icon
                    mb="2px"
                    as={EmptyWallet}
                    variant="Bold"
                    boxSize="24px"
                    color="blue.500"
                  />
                ) : (
                  <Icon
                    mb="2px"
                    as={BoxIcon}
                    variant="Bold"
                    boxSize="24px"
                    color="purple.500"
                  />
                )}
                <Text fontWeight="semibold" color={color} fontSize="l">
                  {t('from')} {account === 'vault' ? VAULT_NAME : WALLET_NAME}
                </Text>
                <Text fontWeight="semibold" color="gray.500" fontSize="l">
                  {account === 'vault' ? vaultAddress : walletAddress}
                </Text>
              </HStack>
              <ModalCloseButton m="16px" />
            </HStack>

            <Stack
              position="relative"
              gap={4}
              p={2}
              overflow="hidden"
              align="center"
              rounded={['30px']}
              transition="all 0.2s"
              boxShadow="lg"
              bg="whiteAlpha.200"
              _hover={{ borderColor: 'gray.600' }}
              spacing={0}
            >
              <NFTImage
                nft={nft}
                w="200px"
                h="200px"
                objectFit="cover"
                rounded={['25px']}
                transform={isRunning ? 'rotateY(320deg)' : 'rotateY(0deg)'}
              />
              <Stack
                align="flex-start"
                maxW="full"
                overflowX="hidden"
                w="full"
                px={4}
                whiteSpace="nowrap"
              >
                <HStack w="full" maxW="full" gap={1} justify="space-between">
                  <TextOrShortened
                    text={symbol || 'No Symbol'}
                    maxLength={10}
                    fontSize="md"
                    maxW="full"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    fontWeight={500}
                  />
                  <Text
                    display="block"
                    rounded="4px"
                    fontSize="xs"
                    color="gray.500"
                    maxW="full"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    fontWeight={500}
                  >
                    x{nftAmount}
                  </Text>
                </HStack>

                <HStack w="full" maxW="full" gap={1}>
                  <TextOrShortened
                    text={name || 'No Name'}
                    maxLength={10}
                    fontSize="xs"
                    flex="1"
                    color="gray.500"
                  />
                  <TextOrShortened
                    text={`#${tokenIdRaw}`}
                    maxLength={6}
                    textOverflow="ellipsis"
                    overflow="hidden"
                    fontSize="xs"
                    color="gray.500"
                  />
                </HStack>
              </Stack>
            </Stack>

            <Stack alignItems="center" w="300px">
              <Text
                px="15px"
                cursor="pointer"
                onClick={toggleShowDetails}
                fontSize="sm"
              >
                {showDetails ? t('hide') : t('show')} {t('details')}
              </Text>
            </Stack>
            <Stack w="300px">
              <Collapse in={showDetails}>
                <Stack mb="10px">
                  <HStack justify="space-between" w="full">
                    <Text color="gray.500" fontSize="sm">
                      {t('contract-address-0')}
                    </Text>
                    <TextOrShortened
                      text={addressRaw || ''}
                      maxLength={10}
                      fontSize="sm"
                    />
                  </HStack>
                  <HStack justify="space-between" w="full">
                    <Text color="gray.500" fontSize="sm">
                      {t('token-id')}
                    </Text>
                    <TextOrShortened
                      text={tokenIdRaw || ''}
                      maxLength={10}
                      fontSize="sm"
                    />
                  </HStack>
                  <HStack justify="space-between" w="full">
                    <Text color="gray.500" fontSize="sm">
                      {t('protocol')}
                    </Text>
                    <TextOrShortened
                      text={protocol || ''}
                      maxLength={10}
                      fontSize="sm"
                    />
                  </HStack>
                  <HStack justify="space-between" w="full">
                    <Text color="gray.500" fontSize="sm">
                      {t('amount')}
                    </Text>
                    <TextOrShortened
                      text={nftAmount || ''}
                      maxLength={10}
                      fontSize="sm"
                    />
                  </HStack>
                </Stack>
              </Collapse>
            </Stack>

            <form onSubmit={handleSubmit(() => handleSend())}>
              <Stack w="300px" alignItems="left">
                <HStack alignItems="center">
                  <Text>{t('amount')}</Text>
                  <Checkbox
                    data-cy="move-nft-to"
                    isChecked={max}
                    isDisabled={isRunning}
                    onChange={() => {
                      setValue('amount', '');
                      clearErrors('amount');
                      toggleMax();
                    }}
                  >
                    <Text color={max ? '' : 'gray.500'}>{t('max-0')}</Text>
                  </Checkbox>
                </HStack>
                <Stack justify="center" w="full">
                  {max ? (
                    <HStack
                      justify="space-between"
                      alignItems="center"
                      cursor="not-allowed"
                      color="gray.500"
                      rounded="18px"
                      h="55px"
                      px="20px"
                    >
                      <Text>{nftAmount}</Text>
                    </HStack>
                  ) : (
                    <Input
                      {...register('amount')}
                      isDisabled={isRunning}
                      placeholder="Amount"
                      _placeholder={{ color: 'gray.500' }}
                      variant="filled"
                      rounded="18px"
                      h="55px"
                    />
                  )}
                </Stack>
                <Stack w="full">
                  <Collapse in={Boolean(formState.errors?.amount)}>
                    <Stack>
                      <Text color="orange.500" fontSize="sm">
                        {formState.errors?.amount?.message}
                      </Text>
                    </Stack>
                  </Collapse>
                </Stack>
                <Stack w="full">
                  <HStack alignItems="center">
                    <Text>{t('to')}</Text>
                    <Checkbox
                      data-cy="move-nft-to"
                      isChecked={betweenWallets}
                      isDisabled={isRunning}
                      onChange={() => {
                        setValue('to', '');
                        clearErrors('to');
                        toggleBetweenWallets();
                      }}
                    >
                      <Text
                        alignItems="left"
                        color={betweenWallets ? '' : 'gray.500'}
                      >
                        {account === 'vault' ? WALLET_NAME : VAULT_NAME}
                      </Text>
                    </Checkbox>
                  </HStack>
                  <Stack justify="center" w="full">
                    {betweenWallets ? (
                      <HStack
                        w="253.667px"
                        justify="space-between"
                        alignItems="center"
                        cursor="not-allowed"
                        color="gray.500"
                        rounded="18px"
                        h="55px"
                        px="20px"
                      >
                        <Text>
                          {account === 'vault' ? walletAddress : vaultAddress}
                        </Text>
                      </HStack>
                    ) : (
                      <Input
                        {...register('to')}
                        isDisabled={isRunning}
                        placeholder="Ethereum Address"
                        _placeholder={{ color: 'gray.500' }}
                        variant="filled"
                        rounded="18px"
                        h="55px"
                        w="full"
                      />
                    )}
                  </Stack>
                </Stack>
                <Stack w="full">
                  <Collapse in={Boolean(formState.errors?.to)}>
                    <Stack>
                      <Text color="orange.500" fontSize="sm">
                        {formState.errors?.to?.message}
                      </Text>
                    </Stack>
                  </Collapse>
                </Stack>

                <Button
                  data-cy="submit-nft-button"
                  rounded="18px"
                  isDisabled={!isValid}
                  isLoading={isRunning}
                  type="submit"
                  h="48px"
                  w="full"
                  color="white"
                  bg="blue.500"
                  colorScheme="blue"
                  fontSize="18px"
                >
                  {!isValid ? t('fix-errors') : t('send')}
                </Button>
              </Stack>
            </form>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
