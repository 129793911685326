'use client';

/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable consistent-return */

import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import type { FC } from 'react';

import { useTranslations } from 'next-intl';
import { usePublishMarketplaceModal } from '~/lib/stores/modalStore';
import { MarketplaceAppBuilder } from '../MarketplaceAppBuilder/MarketplaceAppBuilder';

export const PublishMarketplaceAppModal: FC = () => {
  const t = useTranslations();
  const { meta, ...modal } = usePublishMarketplaceModal();
  return (
    <Modal size="full" {...modal}>
      <ModalOverlay />
      <ModalContent
        _dark={{
          bg: '#0D1117',
        }}
      >
        <ModalCloseButton />
        <ModalHeader>{t('review')}</ModalHeader>
        <ModalBody>
          <Flex align="stretch" justify="center" gap="10">
            <Box
              w="1px"
              borderRight="1px"
              _dark={{
                borderColor: 'gray.800',
              }}
            />
            {meta.group && <MarketplaceAppBuilder maxW="800px" flex="1" />}
          </Flex>
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};
