'use client';

import { Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { NextIntlClientProvider, type AbstractIntlMessages } from 'next-intl';
import type { ReactNode } from 'react';
import en from '../../messages/en.json';

const locales = ['en', 'de'];

const spanMdBold: (chunks: ReactNode) => JSX.Element = (chunks) => (
  <Text fontSize="md" as="span" fontWeight="semibold">
    {chunks}
  </Text>
);

export const IntlProvider = ({ children }: { children: React.ReactNode }) => {
  const locale = 'en';

  const { data: messages } = useQuery({
    queryKey: ['intl-messages', locale],
    queryFn: async () => {
      if (!locales.includes(locale as any)) return en;
      return (await import(`../../messages/${locale}.json`))
        .default as AbstractIntlMessages;
    },
    placeholderData: en,
  });

  return (
    <NextIntlClientProvider
      locale={locale}
      messages={messages}
      defaultTranslationValues={{
        nativeToken: 'ETH',
        'span-md-bold': spanMdBold,
      }}
    >
      {children}
    </NextIntlClientProvider>
  );
};
