import type { ImageProps } from '@chakra-ui/react';
import { Image } from '@chakra-ui/react';

// import kiroboHandBlackSrc from '../../../assets/svg/kirobo-hand-black.svg';
import kiroboHandBlackSrc from '~/assets/svg/kirobo-hand-black.svg';

const ethLogo = 'https://cryptologos.cc/logos/ethereum-eth-logo.png';
interface ITokenIcon extends ImageProps {
  symbol?: string;
  src?: string;
}

export const TokenIcon = ({ symbol = '', src = '', ...props }: ITokenIcon) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle
  const _src = symbol.toLowerCase() === 'kiro' ? kiroboHandBlackSrc : src;
  return (
    <Image rounded="md" src={_src} {...props} alt="" fallbackSrc={ethLogo} />
  );
};

export default TokenIcon;
