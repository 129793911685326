import type { ButtonProps } from '@chakra-ui/react';
import {
  Button,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import { usePathname, useRouter } from 'next/navigation';
import { useCallback } from 'react';
// import { useRouter } from '~/lib/hooks/general/useRouter';
import { useTempIndicatorDefiAppsStateModal } from '~/lib/hooks/useModals/useComplexModals';

// const New = () => (
//   <Stack
//     fontSize="9px"
//     fontWeight="700"
//     spacing="0"
//     transform="rotate(-25deg)"
//     position="absolute"
//     right="8px"
//   >
//     <Text px="3px" rounded="3px" bg="green.300" color="black">
//       NEW
//     </Text>
//   </Stack>
// );

const ComingSoon = () => (
  <Stack
    fontSize="9px"
    fontWeight="700"
    spacing="0"
    transform="rotate(-25deg)"
    position="absolute"
    right="8px"
  >
    <Text>COMING</Text>
    <Text rounded="3px" bg="blue.300" color="black">
      SOON
    </Text>
  </Stack>
);

const ComingSoonButton = ({ ...props }: ButtonProps) => (
  <Button
    isDisabled
    _disabled={{
      opacity: 0.6,
      cursor: 'not-allowed',
    }}
    iconSpacing="10px"
    {...props}
  >
    <Text>{props.children}</Text>
    <ComingSoon />
  </Button>
);

export const TempIndicatorDefiAppsModal = () => {
  const t = useTranslations();

  const pathname = usePathname();
  // const searchParams = useSearchParams();
  // const basedParams = searchParams.get('based');

  const { isOpen, close } = useTempIndicatorDefiAppsStateModal();
  // const [index, setIndex] = useState(0);
  // const [tradingCategories, setTradingCategories] = useState<
  //   {
  //     name: string;
  //   }[]
  // >();

  // useEffect(() => {
  //   if (basedParams === 'apy') {
  //     setIndex(2);
  //     setTradingCategories([{ name: 'APY Based Trading' }]);
  //   } else if (basedParams === 'indicator') {
  //     setIndex(1);
  //     setTradingCategories([{ name: 'Indicator Based Trading' }]);
  //   } else {
  //     setIndex(0);
  //     setTradingCategories([{ name: 'Orders Based Trading' }]);
  //   }
  // }, [basedParams]);

  const navigation = useRouter();

  const handleClose = useCallback(() => {
    navigation.replace(pathname);
    close({});
  }, [close, navigation, pathname]);

  return (
    <Modal size="4xl" isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay backdropFilter="blur(4px)" bg="whiteAlpha.200" />
      <ModalContent
        rounded="24px"
        bg="#F9FAFB"
        _dark={{ bg: '#0F151A' }}
        p="60px"
        gap="32px"
      >
        <ModalHeader p="0">
          <Stack spacing="0">
            <Text
              color="purple.400"
              textAlign="center"
              fontSize="130%"
              fontWeight="600"
            >
              {t('indicator-based-trading')}
            </Text>
            <HStack justify="center">
              <HStack spacing="4px">
                <Image
                  src="/icons/wbtc.png"
                  width="24px"
                  height="24px"
                  rounded="full"
                  alt="WBTC / WETH"
                />
                <Text>WBTC / WETH</Text>
              </HStack>
              {/* <Text color="blue.300" _dark={{ color: 'blue.100' }}> */}
              <HStack spacing="0">
                <Text>{t('intents')}</Text>
                <Text color="orange.400">*</Text>
              </HStack>
            </HStack>
          </Stack>
        </ModalHeader>
        <ModalCloseButton m="16px" />
        <ModalBody justifyContent="center" fontSize="sm" px="0">
          <Stack spacing="28px">
            {/* <CustomSwitchButtons
              index={index}
              // setIndex={setIndex} // Add back when you need to switch between tabs
              forceIsActive // Remove when you need to switch between tabs
              alignSelf="center"
              items={tradingCategories || []}
            /> */}
            <HStack alignItems="flex-start" justify="center" gap="90px">
              <Stack gap="12px" w="full">
                <Text
                  textAlign="center"
                  fontSize="18px"
                  fontWeight="700"
                  textTransform="uppercase"
                  color="green.400"
                >
                  BUY WBTC
                </Text>
                <Stack>
                  <ComingSoonButton>Moving Average</ComingSoonButton>
                  <ComingSoonButton>RSI</ComingSoonButton>
                  <ComingSoonButton>MACD</ComingSoonButton>
                </Stack>
              </Stack>
              <Stack gap="12px" w="full">
                <Text
                  textAlign="center"
                  fontSize="18px"
                  fontWeight="700"
                  textTransform="uppercase"
                  color="red.400"
                >
                  SELL WBTC
                </Text>
                <Stack>
                  <ComingSoonButton>Moving Average</ComingSoonButton>
                  <ComingSoonButton>RSI</ComingSoonButton>
                  <ComingSoonButton>MACD</ComingSoonButton>
                </Stack>
              </Stack>
            </HStack>
          </Stack>
        </ModalBody>
        <ModalFooter justifyContent="center" p="0">
          <Text fontSize="17px" fontWeight="600" color="orange.400">
            *Intent Orders are MEV-Protected.
          </Text>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
