/* eslint-disable import/no-cycle */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-promise-executor-return */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable new-cap */
/* eslint-disable @typescript-eslint/default-param-last */
/* eslint-disable no-param-reassign */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isFunction = (value: unknown): value is (...args: any[]) => any =>
  typeof value === 'function';

export function runIfFn<T, U>(
  valueOrFn: T | ((...fnArgs: U[]) => T),
  ...args: U[]
): T {
  return isFunction(valueOrFn) ? valueOrFn(...args) : valueOrFn;
}

const generateRandomHex = (length: number): string => {
  const possible = 'abcdef0123456789';
  return Array.from({ length }, () =>
    possible.charAt(Math.floor(Math.random() * possible.length))
  ).join('');
};

export const generateRandomEthAddress = (): string => {
  return `0x${generateRandomHex(40)}`;
};
