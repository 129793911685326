/* eslint-disable react/no-unescaped-entities */
import {
  Box,
  Button,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { useWallet } from '@kiroboio/fct-sdk';
import Lottie from 'lottie-react';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import type { FC } from 'react';
import { useCallback, useRef } from 'react';
import noAccessAnimationData from '~/assets/lottie/no-access.json';
import discord from '~/assets/svg/discord.svg';
import { useNotWhitelistedStateModal } from '~/lib/hooks/useModals/useComplexModals';

export const NotWhitelistedModal: FC = () => {
  const t = useTranslations();
  const { isOpen, close } = useNotWhitelistedStateModal();
  const wallet = useWallet();
  const { isRegistered: isWhitelisted } = wallet?.data?.raw || {
    isRegistered: false,
  };
  const registerBtnRef = useRef<HTMLButtonElement>(null);

  const handleClose = useCallback(() => {
    close({});
  }, [close]);

  return (
    <Modal
      size="3xl"
      isOpen={isOpen && !isWhitelisted}
      onClose={handleClose}
      initialFocusRef={registerBtnRef}
    >
      <ModalOverlay backdropFilter="blur(4px)" bg="whiteAlpha.200" />
      <ModalContent
        rounded="32px"
        bg="#F9FAFB"
        _dark={{ bg: '#0F151A' }}
        p="55px"
      >
        <ModalHeader p="0">
          <ModalCloseButton m="10px" />
        </ModalHeader>
        <ModalBody fontSize="sm" p="0">
          <HStack align="center" spacing="10">
            <Stack spacing="4" flex="1">
              <Heading fontSize="2xl">{t('you-dont-have-access-0')}</Heading>
              <HStack w="full" justify="center" spacing="6" pt="5">
                <Button
                  as="a"
                  href="https://share-eu1.hsforms.com/1-re7S-m-TAOV0mRaoduvEQfc8j3"
                  colorScheme="gray"
                >
                  {t('register-for-beta')}
                </Button>
                <Button
                  as="a"
                  href="https://discord.com/invite/NrQTjjwwvq"
                  colorScheme="gray"
                  target="_blank"
                  leftIcon={<Image alt="discord" src={discord} width={24} />}
                >
                  {t('join-discord')}
                </Button>
              </HStack>
            </Stack>
            <Box flex="1" as={Lottie} animationData={noAccessAnimationData} />
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
