import {
  Box,
  Button,
  Checkbox,
  HStack,
  Icon,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useProviders, useSession, useVault } from '@kiroboio/fct-sdk';
import { Warning2 } from 'iconsax-react';
import { useTranslations } from 'next-intl';
import { useCallback, useEffect, useState, type FC } from 'react';
import { useForm } from 'react-hook-form';
import { RiDiscordFill, RiTwitterXFill } from 'react-icons/ri';
import { proxy, useSnapshot } from 'valtio';
import {
  boolean as yupBoolean,
  object as yupObject,
  string as yupString,
} from 'yup';
import { useConnectHandler } from '~/lib/hooks/general/useConnectHandler';
import { useRegisterForOnChainWalletStateModal } from '~/lib/hooks/useModals/useComplexModals';
import { useUser } from '~/lib/hooks/useUser/useUser';
import { SocialButton } from '../Marketplace/SocialButton/SocialButton';

const emailManager = proxy<{ email: string; code: string; sent: boolean }>({
  email: '',
  code: '',
  sent: false,
});

const setEmail = (email: string) => {
  emailManager.email = email;
};
const setCode = (code: string) => {
  emailManager.code = code;
};
const setSent = (sent: boolean) => {
  emailManager.sent = sent;
};

interface IBuilderSteps {
  isSigningIn: boolean;
}

const BuilderStep0 = ({ isSigningIn }: IBuilderSteps) => {
  const t = useTranslations();
  const { connectHandler } = useConnectHandler();

  const handleConnect = useCallback(() => {
    connectHandler();
  }, [connectHandler]);

  return (
    <Stack alignItems="center" h="157px" spacing="0" gap="32px">
      <HStack spacing="0" gap="5px" justify="center">
        <Icon as={Warning2} variant="Linear" boxSize="16px" color="#ED8936" />
        <Text fontSize="18px" color="#ED8936">
          {t('you-have-to-connect-your-wallet-to-begin')}
        </Text>
      </HStack>
      <Button
        isLoading={isSigningIn}
        onClick={handleConnect}
        size="lg"
        colorScheme="blue"
        bg="blue.400"
        color="white"
        rounded="full"
        p="8px 24px"
        fontSize="18px"
        fontWeight="600"
        textTransform="capitalize"
        type="submit"
      >
        {t('connect-wallet')}
      </Button>
    </Stack>
  );
};

const BuilderStep1 = () => {
  const t = useTranslations();
  const { vaultAirDropSubscription } = useProviders();
  const toast = useToast();

  const validationSchema = yupObject().shape({
    email: yupString().required().email(),
    acceptLegal: yupBoolean().isTrue(t('you-must-accept-the-rules')),
  });

  const {
    register,
    reset,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      email: '',
      acceptLegal: false,
    },
  });

  const disabledSubmit = Object.keys(errors).length > 0;

  // console.log('errors', errors);

  const newsletterRegister = useCallback(
    async (email: string) => {
      const returnedData = await vaultAirDropSubscription.execute(async () => {
        return {
          params: {
            email,
          },
        };
      });
      // console.log('yui returnedData', returnedData);
      if (returnedData.error) {
        // console.log('returnedData.error', returnedData.error);
        toast({
          position: 'bottom',
          status: 'error',
          title: t('error'),
          description: t('something-went-wrong-please-try-again-later'),
          duration: 9000,
          isClosable: true,
        });
      } else {
        // console.log('success');
        // if (returnedData.results?.sent) {
        setSent(true);
        toast({
          position: 'bottom',
          status: 'success',
          title: t('success'),
          description: t('please-check-your-email-for-the-access-code'),
          duration: 9000,
          isClosable: true,
        });
        // }
      }
    },
    [vaultAirDropSubscription, toast, t]
  );

  const handleRegistration = useCallback(() => {
    const { email } = getValues();
    setEmail(email);
    newsletterRegister(email);
    // console.log('handleRegistration', getValues());
  }, [newsletterRegister, getValues]);

  return (
    <form onSubmit={handleSubmit(handleRegistration)}>
      <Stack h="157px" spacing="0" gap="32px">
        <Stack>
          <Input
            type="text"
            variant="filled"
            rounded="10px"
            placeholder="Email Address"
            _placeholder={{ color: 'gray.400' }}
            {...register('email')}
          />
          {errors.email && (
            <Text color="orange.500" fontSize="sm">
              {errors.email?.message}
            </Text>
          )}
        </Stack>
        <Stack spacing="0" gap="16px">
          <Stack>
            <HStack gap="10px">
              <Checkbox {...register('acceptLegal')} />
              <Text color="gray.400" fontWeight="400" fontSize="14px">
                {t('i-accept-to')}{' '}
                <Button
                  as={Link}
                  isExternal
                  href="/legal/FCT-Terms.pdf"
                  variant="link"
                  color="blue.400"
                  fontSize="14px"
                  fontWeight="400"
                >
                  {t('terms-and-conditions')}
                </Button>{' '}
                {t('and')}{' '}
                <Button
                  as={Link}
                  isExternal
                  href="/legal/FCT-Privacy.pdf"
                  variant="link"
                  color="blue.400"
                  fontSize="14px"
                  fontWeight="400"
                >
                  {t('privacy-policy')}
                </Button>
              </Text>
            </HStack>
            {errors.acceptLegal && (
              <Text color="orange.500" fontSize="sm">
                {errors.acceptLegal?.message}
              </Text>
            )}
          </Stack>

          <HStack w="full">
            <Button
              size="lg"
              rounded="full"
              p="8px 24px"
              fontSize="16px"
              fontWeight="600"
              variant="ghost"
              w="full"
              onClick={() => reset()}
            >
              {t('clear')}
            </Button>
            <Button
              isDisabled={disabledSubmit}
              size="lg"
              colorScheme="blue"
              bg="blue.400"
              color="white"
              rounded="full"
              p="8px 24px"
              fontSize="18px"
              fontWeight="600"
              textTransform="capitalize"
              type="submit"
              w="full"
            >
              {t('submit')}
            </Button>
          </HStack>
        </Stack>
      </Stack>
    </form>
  );
};

const BuilderStep2 = () => {
  const t = useTranslations();
  const { vaultAirDropConfirmation } = useProviders();
  const toast = useToast();
  const { email } = useSnapshot(emailManager);

  const validationSchema = yupObject().shape({
    code: yupString().required('Access Code is required'),
  });

  const {
    register,
    reset,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      code: '',
    },
  });

  const disabledSubmit = Object.keys(errors).length > 0;

  // console.log('errors', errors);

  const newsletterConfirm = useCallback(
    async (code: string) => {
      const returnedData = await vaultAirDropConfirmation.execute(async () => {
        return {
          params: {
            code,
          },
        };
      });
      // console.log('yui returnedData', returnedData);
      if (returnedData.error) {
        // console.log('returnedData.error', returnedData.error);
        toast({
          position: 'bottom',
          status: 'error',
          title: t('error'),
          description: t('something-went-wrong-please-try-again-later'),
          duration: 9000,
          isClosable: true,
        });
      } else {
        // console.log('success', returnedData.results?.success);
        // if (returnedData.results?.success) {
        toast({
          position: 'bottom',
          status: 'success',
          title: t('success'),
          description: t('you-are-now-a-subscriber'),
          duration: 9000,
          isClosable: true,
        });
        // }
      }
    },
    [vaultAirDropConfirmation, toast, t]
  );

  const handleConfirmation = useCallback(() => {
    const { code } = getValues();
    setCode(code);
    newsletterConfirm(code);
    // console.log('handleConfirmation', getValues());
  }, [newsletterConfirm, getValues]);

  return (
    <form onSubmit={handleSubmit(handleConfirmation)}>
      <Stack h="157px" spacing="0" gap="32px">
        <Stack>
          <HStack justifyContent="space-between">
            <Text color="blue.500" fontSize="sm">
              {t('sent-to')}: {email}
            </Text>
            <Button
              onClick={() => setSent(false)}
              alignItems="flex-start"
              variant="link"
              color="gray.500"
              fontSize="sm"
              w="fit-content"
            >
              {t('step-beck')}
            </Button>
          </HStack>

          <Input
            type="text"
            variant="filled"
            rounded="10px"
            placeholder="Access Code"
            _placeholder={{ color: 'gray.400' }}
            {...register('code')}
          />
          {errors.code && (
            <Text color="orange.500" fontSize="sm">
              {errors.code?.message}
            </Text>
          )}
        </Stack>
        <Stack spacing="0" gap="16px">
          <HStack w="full">
            <Button
              size="lg"
              rounded="full"
              p="8px 24px"
              fontSize="16px"
              fontWeight="600"
              variant="ghost"
              w="full"
              onClick={() => reset()}
            >
              {t('clear')}
            </Button>
            <Button
              isDisabled={disabledSubmit}
              size="lg"
              colorScheme="blue"
              bg="blue.400"
              color="white"
              rounded="full"
              p="8px 24px"
              fontSize="18px"
              fontWeight="600"
              textTransform="capitalize"
              type="submit"
              w="full"
            >
              {t('submit')}
            </Button>
          </HStack>
        </Stack>
      </Stack>
    </form>
  );
};

const BuilderStep3 = () => {
  const t = useTranslations();
  return (
    <Stack alignItems="center" h="157px" spacing="0" gap="32px">
      {/* <HStack spacing="0" gap="5px" justify="center">
        <Text fontSize="18px">Thanks for subscribing!</Text>
      </HStack> */}
      <Stack alignItems="center" spacing="0" gap="16px">
        <Text fontWeight="600" fontSize="16px">
          {t('check-out-our-social-media-channels-for-the-latest-updates')}
        </Text>
        <HStack spacing="16px">
          <SocialButton
            icon={<Icon as={RiDiscordFill} boxSize="18px" />}
            name="Discord"
            link="https://discord.com/invite/NrQTjjwwvq"
          />
          <SocialButton
            icon={<Icon as={RiTwitterXFill} boxSize="18px" />}
            name="TwitterX"
            link="https://twitter.com/Intentable_io"
          />
        </HStack>
      </Stack>
    </Stack>
  );
};

interface IBuilderStepper {
  step: number;
  isSigningIn: boolean;
}

const BuilderStepper = ({ step, isSigningIn }: IBuilderStepper) => {
  switch (step) {
    case 0:
      return <BuilderStep0 isSigningIn={isSigningIn} />;
    case 1:
      return <BuilderStep1 />;
    case 2:
      return <BuilderStep2 />;
    case 3:
      return <BuilderStep3 />;
    default:
      return null;
  }
};

export const RegisterForOnChainWalletModal: FC = () => {
  const t = useTranslations();
  const { isOpen, close } = useRegisterForOnChainWalletStateModal();

  const {
    // status,
    account: { subscribedToVaultAirDrop },
  } = useSession();
  const vault = useVault();
  const { sent } = useSnapshot(emailManager);
  const [step, setStep] = useState(0);

  const { hasVault } = vault?.data?.raw || {
    hasVault: false,
  };
  const { isNotConnected, isSigningIn, isSignedIn } = useUser();

  const handleClose = useCallback(() => {
    close({});
  }, [close]);

  // console.log('subscribedToVaultAirDrop', subscribedToVaultAirDrop);

  useEffect(() => {
    if (subscribedToVaultAirDrop) {
      setStep(3);
      return;
    }

    if (isNotConnected) {
      setStep(0);
    } else if (sent) {
      setStep(2);
    } else {
      setStep(1);
    }
  }, [
    handleClose,
    subscribedToVaultAirDrop,
    hasVault,
    isSignedIn,
    isNotConnected,
    sent,
  ]);

  return (
    <Modal size="6xl" isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay backdropFilter="blur(4px)" bg="whiteAlpha.200" />
      <ModalContent
        rounded="20px"
        bg="#F9FAFB"
        _dark={{ bg: '#0F151A' }}
        p="54px"
        zIndex="0"
      >
        <ModalCloseButton m="2" />
        <ModalBody px="0">
          <HStack justifyContent="space-between">
            <Stack w="400px" spacing="0" gap="32px">
              <Stack spacing="0" gap="16px">
                <Text fontWeight="700" fontSize="36px">
                  {t('runner-waitlist')}
                </Text>
                <Text fontWeight="400" fontSize="14px">
                  {subscribedToVaultAirDrop
                    ? t('you-are-already-registered-to-the-runner')
                    : t('get-a-runner-for-free')}
                  {}
                </Text>
              </Stack>

              <BuilderStepper step={step} isSigningIn={isSigningIn} />
            </Stack>

            <Box w="540px" h="440px" p="10px" rounded="20px" bg="gray.900">
              <Stack
                w="520px"
                h="420px"
                rounded="15px"
                backgroundImage={`url("/images/subscribe1.png")`}
                backgroundSize="cover"
                backgroundPosition="center center"
                backgroundRepeat="no-repeat"
                justify="flex-end"
                alignItems="center"
                alignSelf="stretch"
                p="40px"
              >
                {/* <Stack spacing="0" gap="4px">
                  <Text textAlign="center" fontWeight="700" fontSize="21px">
                    Build an amazing app and earn money
                  </Text>
                  <Text textAlign="center" fontWeight="400" fontSize="14px">
                    Lorem ipsum dolor sit amet consectetur. Dignissim eleifend
                    nulla id tincidunt imperdiet pellentesque maecenas.
                  </Text>
                </Stack> */}
              </Stack>
            </Box>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
