import {
  Button,
  Collapse,
  FormControl,
  FormHelperText,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useVersionDraftsActions } from '@kiroboio/fct-sdk';
import { useTranslations } from 'next-intl';
import { useCallback, useEffect, useMemo, type FC } from 'react';
import { useForm } from 'react-hook-form';
import { object as yupObject, string as yupString } from 'yup';

import { useChangeVersionModeStateModal } from '~/lib/hooks/useModals/useComplexModals';

export const ChangeVersionModeModal: FC = () => {
  const t = useTranslations();
  const {
    isOpen,
    id,
    close,
    props: modalState,
  } = useChangeVersionModeStateModal();

  const { updateMode } = useVersionDraftsActions({
    id,
  });
  const toast = useToast();

  const { isRunning } = updateMode.state;
  // const { isRunning, stage } = updateMode.state;
  // const isDone = stage === 'done';

  const {
    register,
    reset,
    getValues,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      name: '',
    },
    resolver: yupResolver(
      yupObject().shape({
        name: yupString().required(),
      })
    ),
  });

  const disabledSubmit = Object.keys(errors).length > 0;

  // useMemo(() => {
  //   if (isDone) {
  //     updateMode.reset();
  //     close({ clear: true });
  //   }
  // }, [isDone, close, updateMode]);

  const cleanup = !isOpen;
  useMemo(() => {
    if (cleanup) {
      reset();
    }
  }, [cleanup, reset]);

  const handleClose = useCallback(() => {
    close({ clear: true });
    reset();
  }, [close, reset]);

  const changeVersionMode = useCallback(
    async (index: number, name: string) => {
      const returnedData = await updateMode.execute({
        index,
        mode: {
          name,
          description: modalState.description,
          draft: modalState.draft,
        },
      });
      // console.log(' modalState:', modalState);
      // console.log('returnedData.error:', returnedData.error);
      if (returnedData.error) {
        toast({
          position: 'bottom',
          status: 'error',
          title: 'Error',
          description: t('something-went-wrong-please-try-again-later'),
          duration: 9000,
          isClosable: true,
        });
      } else {
        // console.log('success');
        toast({
          position: 'bottom',
          status: 'success',
          title: t('success'),
          description: t('mode-edited-successfully'),
          duration: 9000,
          isClosable: true,
        });
        handleClose();
      }
    },
    [
      updateMode,
      modalState.description,
      modalState.draft,
      toast,
      t,
      handleClose,
    ]
  );

  const handleChangeVersionMode = useCallback(async () => {
    const { name } = getValues();
    changeVersionMode(modalState.index, name);
  }, [changeVersionMode, getValues, modalState.index]);

  useEffect(() => {
    if (modalState.name) {
      setValue('name', modalState.name);
    }
  }, [setValue, modalState.name]);

  return (
    <Modal
      id="create-version-mode"
      size="sm"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <ModalOverlay bg="rgba(51,57,66,0.5)" opacity="0.5" />

      <ModalContent
        rounded="3xl"
        bg="#F9FAFB"
        _dark={{ bg: '#0F151A' }}
        p="45px"
      >
        <ModalHeader p="0">
          <HStack mb="8" w="full" justify="space-between">
            <Text fontSize="xl">{t('rename-mode')}</Text>
            <ModalCloseButton pos="initial" />
          </HStack>
        </ModalHeader>
        <ModalBody py="0" px="0">
          <Stack
            as="form"
            onSubmit={handleSubmit(handleChangeVersionMode)}
            align="center"
            spacing="10"
          >
            <Stack w="full" spacing={6}>
              <FormControl>
                <Input
                  variant="flushed"
                  fontWeight="bold"
                  fontSize="2xl"
                  {...register('name')}
                />
                <Collapse in={Boolean(errors?.name)}>
                  <FormHelperText color="orange.500" fontSize="sm" mb="4">
                    {errors?.name?.message}
                  </FormHelperText>
                </Collapse>
              </FormControl>

              <HStack w="full" pt="8">
                <Button
                  flex="1"
                  size="lg"
                  variant="ghost"
                  onClick={handleClose}
                >
                  {t('cancel')}
                </Button>
                <Button
                  flex="1"
                  size="lg"
                  isLoading={isRunning}
                  type="submit"
                  isDisabled={disabledSubmit}
                  bg="blue.500"
                  colorScheme="blue"
                  color="white"
                  minW="140px"
                >
                  {disabledSubmit ? t('fix-errors') : t('save')}
                </Button>
              </HStack>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
