import { Button } from '~/lib/styles/theme/components/ChakraButton';
import { Dialog } from '~/lib/styles/theme/components/Dialog';
import { Text } from '~/lib/styles/theme/components/Text';
import { Tooltip } from '~/lib/styles/theme/components/Tooltip';
import { Switch } from './Switch';

export const components = {
  Button,
  Dialog,
  Text,
  Tooltip,
  Switch,
};
