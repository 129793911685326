/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable complexity */
import {
  Button,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Tag,
  Text,
  Tooltip,
  useMergeRefs,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  BigIntOr0,
  useFlowPower,
  useTokenItem,
  useVaultActions,
} from '@kiroboio/fct-sdk';
import { BoxAdd, EmptyWalletAdd } from 'iconsax-react';
import { useCallback, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useTranslations } from 'next-intl';
import { FaGasPump } from 'react-icons/fa6';
import {
  flowPowerId,
  useAddCombinedFlowPowerStateModal,
  useFlowPowerModalActions,
} from '~/lib/hooks/useModals/useComplexModals';
import { etherToWei, weiToEther } from '~/lib/utils/number';

export const AddCombinedFlowPowerModal = () => {
  const t = useTranslations();
  const { isOpen, close } = useAddCombinedFlowPowerStateModal();
  const { close: closeFlowPowerModal } = useFlowPowerModalActions(flowPowerId);
  const {
    raw: {
      missing: { native: powerMissingWei },
    },
    fmt: {
      missing: { native: powerMissingEtherFmt },
    },
  } = useFlowPower();
  const {
    flow: { addPower },
  } = useVaultActions();
  const { item: walletNative } = useTokenItem({ account: 'wallet' });
  const { item: vaultNative } = useTokenItem({ account: 'vault' });

  const symbol = walletNative?.raw.symbol || 'ETH';

  const walletBalanceWei = walletNative?.raw.balance || '0';
  const vaultBalanceWei = vaultNative?.raw.balance || '0';
  const walletBalanceEther = weiToEther(walletBalanceWei || '0');
  const vaultBalanceEther = weiToEther(vaultBalanceWei || '0');
  const walletBalanceEtherFmt = walletNative?.fmt.balance || '0';
  const vaultBalanceEtherFmt = vaultNative?.fmt.balance || '0';

  const {
    register,
    setValue,
    clearErrors,
    reset,
    watch,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: {
      obj: {
        vaultAmount: '',
        walletAmount: '',
      },
    },
    resolver: yupResolver(
      yup.object({
        obj: yup
          .object({
            vaultAmount: yup
              .string()
              .test(
                'is greater than zero',
                t('amount-must-be-a-number-greater-than-zero'),
                (value) => {
                  if (!value) return true;
                  return Number(value) > 0;
                }
              )
              .test(
                'is less than or equal vault balance',
                t('amount-cant-be-greater-than-balance'),
                (value) => {
                  if (!value) return true;
                  return Number(vaultBalanceEther) >= Number(value);
                }
              ),
            walletAmount: yup
              .string()
              .test(
                'is greater than zero',
                t('amount-must-be-a-number-greater-than-zero-0'),
                (value) => {
                  if (!value) return true;
                  return Number(value) > 0;
                }
              )
              .test(
                'is less than or equal wallet balance',
                t('amount-cant-be-greater-than-balance-0'),
                (value) => {
                  if (!value) return true;
                  return Number(walletBalanceEther) >= Number(value);
                }
              ),
          })
          .test(
            'either-or',
            t('you-must-provide-at-least-one-input-field'),
            (data) => {
              return Boolean(data.vaultAmount || data.walletAmount);
            }
          ),
      })
    ),
  });

  const vaultAmount = watch('obj.vaultAmount');
  const walletAmount = watch('obj.walletAmount');

  const disabledSubmit = Object.keys(errors).length > 0;

  const handleClose = useCallback(() => {
    close({});
  }, [close]);

  const handleCloseFlowPowerModal = useCallback(() => {
    closeFlowPowerModal({});
  }, [closeFlowPowerModal]);

  function handleAddPower() {
    const vaultAmountInWei = BigIntOr0(etherToWei(vaultAmount || '0', 18));
    const walletAmountInWei = BigIntOr0(etherToWei(walletAmount || '0', 18));
    const totalValue = vaultAmountInWei + walletAmountInWei;
    addPower.execute([
      { valueIn: walletAmountInWei, value: totalValue, inputs: {} },
    ]);
    setValue('obj.vaultAmount', '');
    setValue('obj.walletAmount', '');
    handleClose();
    handleCloseFlowPowerModal();
  }

  const { ref: inputRef, ...registeredVaultAmount } =
    register('obj.vaultAmount');
  const initialFocusRef = useRef(null);
  const mergedRefs = useMergeRefs(initialFocusRef, inputRef);

  useEffect(() => {
    reset();
  }, [reset, isOpen]);

  useEffect(() => {
    trigger();
  }, [vaultAmount, walletAmount, trigger]);

  const isTwoInputsMissingError: any = errors.obj;

  const showNeededTokenAlert = BigIntOr0(powerMissingWei) > BigIntOr0(0);

  return (
    <Modal
      initialFocusRef={initialFocusRef}
      size="2xl"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <ModalOverlay backdropFilter="blur(4px)" bg="whiteAlpha.200" />
      <ModalContent
        rounded="24px"
        bg="#F9FAFB"
        _dark={{ bg: '#0F151A' }}
        p="60px"
        gap="24px"
        w="495px"
      >
        <ModalHeader p="0">
          <HStack justify="center">
            <Text fontSize="16px">{t('adding-gas')}</Text>
          </HStack>
        </ModalHeader>
        <ModalCloseButton m="16px" />
        <ModalBody fontSize="sm" px="0" color="gray.500">
          <form onSubmit={handleSubmit(handleAddPower)}>
            <Stack gap="24px">
              {showNeededTokenAlert && (
                <Stack gap="0" spacing="0" align="center">
                  <Text fontSize="14px">{t('you-need')}</Text>
                  <HStack gap="3px" spacing="0">
                    <Icon
                      as={FaGasPump}
                      color="black"
                      _dark={{ color: 'white' }}
                      boxSize="25px"
                    />
                    <Text color="red.400" fontSize="24px" fontWeight="700">
                      +{powerMissingEtherFmt}
                    </Text>
                    <Text
                      color="black"
                      _dark={{ color: 'white' }}
                      fontSize="24px"
                      fontWeight="700"
                    >
                      {symbol}
                    </Text>
                  </HStack>
                </Stack>
              )}

              <Stack gap="10px">
                <Stack>
                  <HStack justify="flex-end" spacing="5px">
                    <Icon
                      as={BoxAdd}
                      variant="Linear"
                      color="purple.500"
                      boxSize="20px"
                    />
                    <Text color="gray.500" fontSize="14px">
                      {t('from-runner')}
                    </Text>
                    <Spacer />
                    <Tooltip
                      label={`${t('unrounded-value-is')} ${vaultBalanceEther || '0'}`}
                    >
                      <Text
                        color="black"
                        _dark={{ color: 'white' }}
                        fontSize="14px"
                      >
                        {vaultBalanceEtherFmt || '0'} {symbol}
                      </Text>
                    </Tooltip>
                  </HStack>
                  <InputGroup>
                    <Input
                      data-cy="vault-power-input"
                      ref={mergedRefs}
                      {...registeredVaultAmount}
                      variant="filled"
                      bg="#E2E8F0"
                      color="black"
                      _dark={{ bg: '#161C23', color: 'white' }}
                      rounded="18px"
                      h="48px"
                    />
                    <InputRightElement h="full" minW="136px">
                      <HStack rounded="full" ml="12">
                        <Tag
                          ml="2"
                          rounded="full"
                          onClick={() => {
                            setValue(
                              'obj.vaultAmount',
                              vaultBalanceEther || '0'
                            );
                            clearErrors('obj.vaultAmount');
                          }}
                          cursor="pointer"
                        >
                          {t('max-0')}
                        </Tag>
                      </HStack>
                    </InputRightElement>
                  </InputGroup>
                  {errors.obj?.vaultAmount && (
                    <Text color="orange.500" fontSize="sm">
                      {errors.obj?.vaultAmount?.message}
                    </Text>
                  )}
                </Stack>
                {isTwoInputsMissingError && (
                  <Text color="orange.500">
                    {isTwoInputsMissingError?.message}
                  </Text>
                )}
                <Stack>
                  <HStack justify="flex-end" spacing="5px">
                    <Icon
                      as={EmptyWalletAdd}
                      variant="Linear"
                      color="blue.500"
                      boxSize="20px"
                    />
                    <Text color="gray.500" fontSize="14px">
                      {t('from-wallet')}
                    </Text>
                    <Spacer />
                    <Tooltip
                      label={`${t('unrounded-value-is')} ${walletBalanceEther || '0'}`}
                    >
                      <Text
                        color="black"
                        _dark={{ color: 'white' }}
                        fontSize="14px"
                      >
                        {walletBalanceEtherFmt || '0'} {symbol}
                      </Text>
                    </Tooltip>
                  </HStack>
                  <InputGroup>
                    <Input
                      data-cy="wallet-power-input"
                      {...register('obj.walletAmount')}
                      variant="filled"
                      bg="#E2E8F0"
                      color="black"
                      _dark={{ bg: '#161C23', color: 'white' }}
                      rounded="18px"
                      h="48px"
                    />
                    <InputRightElement h="full" minW="136px">
                      <HStack rounded="full" ml="12">
                        <Tag
                          ml="2"
                          rounded="full"
                          onClick={() => {
                            setValue(
                              'obj.walletAmount',
                              walletBalanceEther || '0'
                            );
                            clearErrors('obj.walletAmount');
                          }}
                          cursor="pointer"
                        >
                          {t('max-0')}
                        </Tag>
                      </HStack>
                    </InputRightElement>
                  </InputGroup>
                  {errors.obj?.walletAmount && (
                    <Text color="orange.500" fontSize="sm">
                      {errors.obj?.walletAmount?.message}
                    </Text>
                  )}
                </Stack>
              </Stack>

              <Button
                data-cy="add-power-submit"
                isDisabled={disabledSubmit}
                type="submit"
                h="48px"
                w="full"
                color="white"
                bg="primary.500"
                fontSize="18px"
              >
                {t('add')}
              </Button>
            </Stack>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
