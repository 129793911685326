/* eslint-disable react/prop-types */
import {
  Button,
  Checkbox,
  Circle,
  Collapse,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import type { NFTItemType } from '@kiroboio/fct-sdk';
import {
  service,
  useTokenActions,
  useTokenItem,
  useVault,
  useWallet,
} from '@kiroboio/fct-sdk';
import { Box as BoxIcon, EmptyWallet } from 'iconsax-react';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { object, string as yupString } from 'yup';

import { useTranslations } from 'next-intl';
import { useSendTokenStateModal } from '~/lib/hooks/useModals/useComplexModals';
import { TokenIcon } from '../flow/Tokens/TokenIcon';
import { getIsLP, getSymbolWithoutEnding } from '../lp/utils';
import { LP } from '../lp/LP';
// import {
//   useCurrentTokenId,
//   useSendTokenModal,
// } from '~/lib/hooks/useModals/useSendTokenModal';

interface FormInputs {
  to: string;
  amount: string;
  amountUsd: string;
  token: NFTItemType;
}

// eslint-disable-next-line no-unused-vars
export const SendTokenModal = () => {
  const t = useTranslations();
  const VAULT_NAME = t('runner');
  const WALLET_NAME = t('wallet');
  // const { currentTokenId } = useCurrentTokenId();
  const {
    id,
    mutate,
    isOpen,
    close,
    props: { account, max, betweenWallets, to: toProp, amount: amountProp },
    // openById,
  } = useSendTokenStateModal();

  const toggleMax = useCallback(() => {
    mutate((prev) => ({ ...prev, max: !prev.max }));
  }, [mutate]);

  const toggleBetweenWallets = useCallback(() => {
    mutate((prev) => ({ ...prev, betweenWallets: !prev.betweenWallets }));
  }, [mutate]);

  const updateInputs = useCallback(
    ({ amount, to }: { amount: string; to: string }) =>
      mutate((prev) => ({ ...prev, amount, to })),
    [mutate]
  );
  const {
    data: {
      fmt: { address: vaultAddress },
    },
  } = useVault();
  const {
    data: {
      fmt: { address: walletAddress },
    },
  } = useWallet();
  const { transfer } = useTokenActions({ account, id });
  const { item: token } = useTokenItem({ account, id });
  const { isRunning, stage } = transfer.state;

  const { balance, symbol, logo } = token?.fmt || {
    balance: '',
    symbol: '',
    logo: '',
  };
  const { balance: rawBalance, decimals: rawDecimals } = token?.raw || {
    balance: '',
    decimals: '',
  };

  const toast = useToast();
  const color = useColorModeValue('black', 'white');
  const bgColor = useColorModeValue('#F7FAFC', '#080B0E');

  const validationSchema = object().shape({
    to: yupString()
      .trim()
      .test('required', t('address-is-a-required-field'), (value) => {
        if (betweenWallets) return true;
        return value !== undefined && value !== null && value !== '';
      })
      .test(
        'address-wrong',
        t('wrong-address'),
        function validateAddress(value) {
          if (betweenWallets) return true;
          try {
            const transformed = service.formatting.prebuild.formatAddress({
              address: value || '',
              service: '',
              name: '',
            });
            return transformed !== '-';
          } catch {
            return false;
          }
        }
      ),
    amount: yupString()
      .test('required', t('amount-is-a-required-field'), (value) => {
        if (max) return true;
        return value !== undefined && value !== null && value !== '';
      })
      .test(
        'is greater than zero',
        t('amount-must-be-greater-than-zero'),
        (value) => {
          if (max) return true;
          return Number(value) > 0;
        }
      )
      .test(
        'less than or equal to amount',
        t('must-be-less-than-or-equal-to-amount'),
        (value) => {
          if (max) return true;
          return (
            Number(value) <= Number(rawBalance) / 10 ** Number(rawDecimals)
          );
        }
      ),
  });

  const {
    handleSubmit,
    setValue,
    reset,
    formState,
    watch,
    clearErrors,
    register,
  } = useForm<FormInputs>({
    defaultValues: {
      to: toProp,
      amount: amountProp,
    },
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (isOpen) {
      reset();
      setValue('amount', amountProp);
      setValue('to', toProp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, reset, setValue]);

  const isConfirming = stage === 'confirming';
  useMemo(() => {
    if (isConfirming) {
      close({ clear: true });
    }
  }, [isConfirming, close]);

  const amount = watch('amount');
  const to = watch('to');

  useEffect(() => {
    updateInputs({ amount, to });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, to]);

  const { errors } = formState;

  const isValid = Object.keys(errors).length === 0;

  const isLP = getIsLP(symbol || '');
  const symbolDisplay = getSymbolWithoutEnding(symbol || '');

  const toastTransaction = ({
    title,
    message,
    error,
  }: {
    title: string;
    message: string;
    error: any;
  }) => {
    let errorMessage = error?.message?.split("'{")[1];
    try {
      // console.log(errorMessage);
      errorMessage = JSON.parse(`{${errorMessage.slice(0, -1)}`);
      errorMessage = errorMessage?.message;
    } catch {
      errorMessage = error?.message;
    }
    toast({
      title,
      description: error ? error.reason || errorMessage : message,
      status: error ? 'error' : 'success',
      duration: 9000,
      isClosable: true,
    });
  };

  const transferTokens = async () => {
    const res = await transfer.execute(async () => {
      return {
        params: {
          value: max ? 'max' : amount,
          to: betweenWallets ? undefined : to,
        },
      };
    });
    toastTransaction({
      title: `${symbolDisplay} ${isLP ? t('lp') : ''} ${t('transfer')}`,
      message: t('transfer-completed-successfully'),
      error: res.error,
    });
  };

  function handleSend() {
    transferTokens();
  }

  if (isOpen === false) return null;

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        close();
      }}
      isCentered
      motionPreset="slideInBottom"
    >
      <ModalOverlay backdropFilter="blur(4px)" bg="whiteAlpha.200" />
      <ModalContent w="auto" rounded="24px" p="60px" maxW="full" bg={bgColor}>
        <ModalBody p="0">
          <Stack p="16px" alignItems="center" w="400px">
            <HStack justify="space-between" alignItems="center" w="full" mb="4">
              <HStack>
                {account === 'wallet' ? (
                  <Icon
                    mb="2px"
                    as={EmptyWallet}
                    variant="Bold"
                    boxSize="24px"
                    color="blue.500"
                  />
                ) : (
                  <Icon
                    mb="2px"
                    as={BoxIcon}
                    variant="Bold"
                    boxSize="24px"
                    color="purple.500"
                  />
                )}
                <Text fontWeight="semibold" color={color} fontSize="l">
                  {t('from')} {account === 'vault' ? VAULT_NAME : WALLET_NAME}
                </Text>
                <Text fontWeight="semibold" color="gray.500" fontSize="l">
                  {account === 'vault' ? vaultAddress : walletAddress}
                </Text>
              </HStack>
              <ModalCloseButton m="16px" />
            </HStack>
            <HStack pr="16px">
              <Circle size="38px" bg="blackAlpha.400" p="6px">
                <TokenIcon src={logo?.toString()} symbol={symbol} />
              </Circle>
              <Stack align="flex-start" spacing="0">
                <HStack spacing="1">
                  <Text fontSize="16px" fontWeight="semibold">
                    {symbolDisplay}
                  </Text>
                  {isLP && <LP />}
                </HStack>
              </Stack>
            </HStack>
            <Stack spacing="3px" alignItems="center" mb="4">
              <Text fontSize="md" color="gray.500">
                {t('balance')}
              </Text>
              <Text fontSize="md" color={color}>
                {balance}
              </Text>
            </Stack>

            <form onSubmit={handleSubmit(() => handleSend())}>
              <Stack w="300px" alignItems="left">
                <HStack alignItems="center">
                  <Text>{t('amount')}</Text>
                  <Checkbox
                    data-cy="move-token-to"
                    isChecked={max}
                    isDisabled={isRunning}
                    onChange={() => {
                      setValue('amount', '');
                      clearErrors('amount');
                      toggleMax();
                    }}
                  >
                    <Text color={max ? '' : 'gray.500'}>{t('max-0')}</Text>
                  </Checkbox>
                </HStack>
                <Stack justify="center" w="full">
                  {max ? (
                    <HStack
                      justify="space-between"
                      alignItems="center"
                      cursor="not-allowed"
                      color="gray.500"
                      rounded="18px"
                      h="55px"
                      px="20px"
                    >
                      <Text>{balance}</Text>
                    </HStack>
                  ) : (
                    <Input
                      data-cy="token-amount-input"
                      {...register('amount')}
                      isDisabled={isRunning}
                      placeholder="Number"
                      _placeholder={{ color: 'gray.500' }}
                      variant="filled"
                      rounded="18px"
                      h="55px"
                    />
                  )}
                </Stack>
                <Stack w="full">
                  <Collapse in={Boolean(formState.errors?.amount)}>
                    <Stack>
                      <Text color="orange.500" fontSize="sm">
                        {formState.errors?.amount?.message}
                      </Text>
                    </Stack>
                  </Collapse>
                </Stack>
                <Stack w="full">
                  <HStack alignItems="center">
                    <Text>{t('to')}</Text>
                    <Checkbox
                      data-cy="move-token-to"
                      isChecked={betweenWallets}
                      isDisabled={isRunning}
                      onChange={() => {
                        setValue('to', '');
                        clearErrors('to');
                        toggleBetweenWallets();
                      }}
                    >
                      <Text
                        alignItems="left"
                        color={betweenWallets ? '' : 'gray.500'}
                      >
                        {account === 'vault' ? WALLET_NAME : VAULT_NAME}
                      </Text>
                    </Checkbox>
                  </HStack>
                  <Stack justify="center" w="full">
                    {betweenWallets ? (
                      <HStack
                        w="253.667px"
                        justify="space-between"
                        alignItems="center"
                        cursor="not-allowed"
                        color="gray.500"
                        rounded="18px"
                        h="55px"
                        px="20px"
                      >
                        <Text>
                          {account === 'vault' ? walletAddress : vaultAddress}
                        </Text>
                      </HStack>
                    ) : (
                      <Input
                        {...register('to')}
                        isDisabled={isRunning}
                        placeholder="Ethereum Address"
                        _placeholder={{ color: 'gray.500' }}
                        variant="filled"
                        rounded="18px"
                        h="55px"
                        w="full"
                      />
                    )}
                  </Stack>
                </Stack>
                <Stack w="full">
                  <Collapse in={Boolean(formState.errors?.to)}>
                    <Stack>
                      <Text color="orange.500" fontSize="sm">
                        {formState.errors?.to?.message}
                      </Text>
                    </Stack>
                  </Collapse>
                </Stack>

                <Button
                  data-cy="submit-token-button"
                  rounded="18px"
                  isDisabled={!isValid}
                  isLoading={isRunning}
                  type="submit"
                  h="48px"
                  w="full"
                  color="white"
                  bg="blue.500"
                  colorScheme="blue"
                  fontSize="18px"
                >
                  {!isValid ? t('fix-errors') : t('send')}
                </Button>
              </Stack>
            </form>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
