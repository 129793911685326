'use client';

import { Text } from '@chakra-ui/react';
import { useImagesActions } from '@kiroboio/fct-sdk';
import { useTranslations } from 'next-intl';
import type { FC } from 'react';
import { memo, useCallback } from 'react';

import { DeleteAlert } from '~/lib/components/general/DeleteAlert';
import { useDeleteImageStateModal } from '~/lib/hooks/useModals/useComplexModals';

const DeleteImageModal2: FC = () => {
  const t = useTranslations();
  const {
    isOpen,
    id,
    close,
    props: { name },
  } = useDeleteImageStateModal();
  const { remove } = useImagesActions({ id });

  const handleRemove = useCallback(() => {
    remove.execute({});
  }, [remove]);

  const handleClose = useCallback(() => {
    close({ clear: true });
  }, [close]);

  return (
    <DeleteAlert
      isOpen={isOpen}
      onClose={handleClose}
      header={t('delete-image')}
      isCentered
      body={
        <Text>
          {t('are-you-sure-you-want-to-delete')}{' '}
          <Text as="span" fontWeight="semibold">
            {name}
          </Text>
        </Text>
      }
      footer={{
        confirm: {
          onClick: handleRemove,
        },
      }}
    />
  );
};

export const DeleteImageModal = memo(DeleteImageModal2);
