import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';

import { useTestGeneratorStateModal } from '~/lib/hooks/useModals/useComplexModals';

// import { useTestGeneratorModal } from '~/lib/hooks/useModals/useSimpleModals';

export const TestGeneratorModal = () => {
  // const {
  //   onClose,
  //   snapshot: { isOpen },
  // } = useTestGeneratorModal();
  const { isOpen, close } = useTestGeneratorStateModal();

  return (
    <Modal
      isOpen={isOpen}
      onClose={close}
      isCentered
      motionPreset="slideInBottom"
    >
      <ModalOverlay backdropFilter="blur(4px)" bg="whiteAlpha.200" />
      <ModalContent w="auto" rounded="24px" p="60px" maxW="full" bg="blue.500">
        <ModalBody p="0">
          <ModalCloseButton m="16px" />
          <Stack>fdgfdgdfg</Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
