/* eslint-disable react/prop-types */
import {
  Box,
  Button,
  Circle,
  HStack,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useColorMode,
} from '@chakra-ui/react';

import { useVault, useWalletActions } from '@kiroboio/fct-sdk';
import { User } from 'iconsax-react';
import { useTranslations } from 'next-intl';
import type { FC } from 'react';
import { useCallback, useRef } from 'react';
import { FaArrowRight } from 'react-icons/fa6';
import { useMissingOnChainWalletStateModal } from '~/lib/hooks/useModals/useComplexModals';

export const MissingOnChainWalletModal: FC = () => {
  const t = useTranslations();
  const {
    isOpen,
    close,
    props: { title, subtitle },
  } = useMissingOnChainWalletStateModal();

  const vault = useVault();
  const { createVault } = useWalletActions();

  const { isRunning } = createVault.state;

  const { colorMode } = useColorMode();

  const imageSrc =
    colorMode === 'light'
      ? '/images/create_runner_light.png'
      : '/images/create_runner_dark.png';

  const { hasVault } = vault?.data?.raw || {
    hasVault: false,
  };

  const handleClose = useCallback(() => {
    close({});
  }, [close]);

  const handleCreateVault = useCallback(async () => {
    const returnedData = await createVault.execute(async () => {
      return {
        params: {
          inputs: [],
        },
      };
    });
    if (returnedData.error) {
      console.error('yui createVault error', returnedData.error);
    } else {
      handleClose();
    }
  }, [createVault, handleClose]);

  const handleCreateOnChainWallet = () => {
    handleCreateVault();
  };

  const registerBtnRef = useRef<HTMLButtonElement>(null);

  return (
    <Modal
      size="4xl"
      isOpen={isOpen && !hasVault}
      onClose={handleClose}
      initialFocusRef={registerBtnRef}
    >
      <ModalOverlay backdropFilter="blur(4px)" bg="whiteAlpha.200" />
      <ModalContent
        rounded="32px"
        bg="#F9FAFB"
        boxShadow="3px 3px 10px rgba(0, 0, 0, 0.3)"
        border="2px solid rgba(0, 0, 0, 0.35)"
        _dark={{
          bg: '#0F151A',
          boxShadow: '3px 3px 10px rgba(255, 255, 255, 0.2)',
          border: '2px solid rgba(255, 255, 255, 0.35)',
        }}
      >
        <ModalHeader p="0">
          <ModalCloseButton m="10px" />
        </ModalHeader>
        <ModalBody fontSize="sm" p="0">
          <HStack w="full" h="550px" justifyContent="space-between">
            <Stack
              flex="1.5"
              p="48px"
              alignItems="center"
              spacing="0"
              gap="40px"
            >
              <Box visibility="visible" w="350px" h="250px">
                <Image w="350px" h="250px" src={imageSrc} />
              </Box>
              <Stack spacing="0" gap="16px">
                <Text fontWeight="700" fontSize="20px">
                  {title ||
                    t('set-up-your-private-intents-runner-to-start-trading')}
                </Text>
                <Text fontWeight="400" fontSize="14px" color="gray.400">
                  {subtitle ||
                    t(
                      'the-runner-is-a-smart-contract-that-securely-manages-your-trades-operating-automatically-to-execute-your-strategy-as-intended-it-can-also-hold-funds-and-act-as-a-wallet-fully-owned-and-accessible-only-by-you'
                    )}
                </Text>
              </Stack>
            </Stack>

            <Stack
              flex="1"
              p="48px"
              h="full"
              rounded="30px"
              bg="gray.100"
              borderLeft="1px solid rgba(0, 0, 0, 0.3)"
              _dark={{
                bg: 'gray.800',
                borderLeft: '1px solid rgba(255, 255, 255, 0.15)',
              }}
              justify="center"
              alignItems="center"
              gap="32px"
            >
              <Circle
                borderStyle="inset"
                borderWidth="2px"
                borderColor="blue.400"
                size="80px"
              >
                <Circle size="60px" bg="#F9FAFB" _dark={{ bg: '#0F151A' }}>
                  <Icon
                    as={User}
                    boxSize="32px"
                    color="blue.400"
                    margin="5px"
                  />
                </Circle>
              </Circle>

              <Button
                size="lg"
                colorScheme="blue"
                bg="blue.400"
                color="white"
                rounded="full"
                p="8px 24px"
                fontSize="18px"
                fontWeight="600"
                textTransform="capitalize"
                type="submit"
                w="full"
                rightIcon={<Icon as={FaArrowRight} />}
                onClick={handleCreateOnChainWallet}
                isLoading={isRunning}
              >
                {t('create-runner')}
              </Button>
            </Stack>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
