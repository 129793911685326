import {
  Button,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Tag,
  Text,
  Tooltip,
  useMergeRefs,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  BigIntOr0,
  useFlowPower,
  useSession,
  useTokenItem,
  useVaultActions,
} from '@kiroboio/fct-sdk';
import { FaGasPump } from 'react-icons/fa6';

import type { FC, FormEvent } from 'react';
import { useCallback, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { object as yupObject, string as yupString } from 'yup';

import { useTranslations } from 'next-intl';
import {
  flowPowerId,
  useFlowPowerModalActions,
  useRemoveFlowPowerStateModal,
} from '~/lib/hooks/useModals/useComplexModals';
import { etherToWei, weiToEther } from '~/lib/utils/number';
import { TextOrShortened } from '../general/TextOrShortened';
// import { mixpanelAnalytics } from 'src/kirobo/utils/mixpanelAnalytics';

export const RemoveFlowPowerModal: FC = () => {
  const t = useTranslations();
  const { isOpen, close } = useRemoveFlowPowerStateModal();
  const { close: closeFlowPowerModal } = useFlowPowerModalActions(flowPowerId);
  const {
    account: {
      gasCredit: { native: nativeGasCredit },
    },
  } = useSession();

  const showCantWithdrawAlert = BigIntOr0(nativeGasCredit) > BigIntOr0(0);
  const nativeGasCreditEther = weiToEther(nativeGasCredit || '0');

  const {
    raw: {
      balance: { native: powerBalanceWei },
    },
    fmt: {
      balance: { native: powerBalanceEtherFmt },
    },
  } = useFlowPower();
  const {
    flow: { removePower },
  } = useVaultActions();
  const { item: walletNative } = useTokenItem({ account: 'wallet' });

  const symbol = walletNative?.raw.symbol || 'ETH';

  const powerBalanceEther = weiToEther(powerBalanceWei || '0');

  const validationSchema = yupObject().shape({
    amount: yupString()
      .required()
      .test(
        'is greater than zero',
        t('amount-must-be-a-number-greater-than-zero'),
        (value) => {
          return Number(value) > 0;
        }
      )
      .test(
        'is less than or equal power balance',
        t('amount-cant-be-greater-than-power-balance'),
        (value) => {
          return Number(powerBalanceEther) >= Number(value);
        }
      ),
  });

  const {
    register,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      amount: '',
    },
  });

  const amount = watch('amount');

  const { ref: inputRef, ...registeredAmount } = register('amount');
  const initialFocusRef = useRef(null);
  const mergedRefs = useMergeRefs(initialFocusRef, inputRef);

  const handleClose = useCallback(() => {
    close({});
  }, [close]);

  const handleCloseFlowPowerModal = useCallback(() => {
    closeFlowPowerModal({});
  }, [closeFlowPowerModal]);

  function handleRemovePower(e: FormEvent<HTMLFormElement>) {
    // mixpanelAnalytics.track({
    //   label: 'Remove_FCT_Power',
    //   payload: {
    //     token_amount: `$${weiToUSD(amountInWei)}`,
    //     to_wallet: 'vault',
    //   },
    // });
    e.preventDefault();
    const amountInWei = BigIntOr0(etherToWei(amount, 18));
    removePower.execute([{ inputs: { value: amountInWei } }]);
    setValue('amount', '');
    handleClose();
    handleCloseFlowPowerModal();
  }

  const disabledSubmit = Boolean(errors && errors.amount) || amount === '';

  useEffect(() => {
    setValue('amount', '');
    clearErrors('amount');
  }, [clearErrors, isOpen, setValue]);

  return (
    <Modal
      initialFocusRef={initialFocusRef}
      size="2xl"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <ModalOverlay backdropFilter="blur(4px)" bg="whiteAlpha.200" />
      <ModalContent
        rounded="24px"
        bg="#F9FAFB"
        _dark={{ bg: '#0F151A' }}
        p="60px"
        gap="24px"
        w="495px"
      >
        <ModalHeader p="0">
          <HStack justify="center">
            <Text fontSize="16px">{t('withdraw-gas')}</Text>
          </HStack>
        </ModalHeader>
        <ModalCloseButton m="16px" />
        <ModalBody fontSize="sm" px="0" color="gray.500">
          <form onSubmit={handleRemovePower}>
            <Stack gap="24px">
              {showCantWithdrawAlert && (
                <HStack
                  spacing="3px"
                  fontWeight="400"
                  fontSize="14px"
                  color="blue.500"
                >
                  <Text>Your</Text>
                  <TextOrShortened
                    text={nativeGasCreditEther || ''}
                    maxLength={6}
                  />
                  <Text>
                    {symbol} {t('gas-credit-can-not-be-withdrawn')}
                  </Text>
                </HStack>
              )}
              <Stack gap="5px">
                <HStack justify="flex-end" spacing="2px">
                  <Icon
                    as={FaGasPump}
                    color="black"
                    _dark={{ color: 'white' }}
                    boxSize="20px"
                  />
                  <Text color="gray.500" fontSize="14px">
                    {t('my-gas-not-including-credit')}
                  </Text>
                  <Spacer />
                  <Tooltip
                    label={`${t('unrounded-value-is')} ${powerBalanceEther}`}
                  >
                    <Text
                      color="black"
                      _dark={{ color: 'white' }}
                      fontSize="14px"
                    >
                      {powerBalanceEtherFmt || '0'} {symbol}
                    </Text>
                  </Tooltip>
                </HStack>
                <InputGroup>
                  <Input
                    data-cy="remove-power-input"
                    ref={mergedRefs}
                    {...registeredAmount}
                    variant="filled"
                    bg="#E2E8F0"
                    color="black"
                    _dark={{ bg: '#161C23', color: 'white' }}
                    rounded="18px"
                    h="48px"
                  />
                  <InputRightElement h="full" minW="136px">
                    <HStack rounded="full" ml="12">
                      <Tag
                        ml="2"
                        rounded="full"
                        onClick={() => {
                          setValue('amount', powerBalanceEther || '');
                          clearErrors('amount');
                        }}
                        cursor="pointer"
                      >
                        {t('max-0')}
                      </Tag>
                    </HStack>
                  </InputRightElement>
                </InputGroup>
                {errors.amount && (
                  <Text color="orange.500" fontSize="sm">
                    {errors.amount.message}
                  </Text>
                )}
              </Stack>

              <Button
                data-cy="remove-power-submit"
                isDisabled={disabledSubmit}
                type="submit"
                h="48px"
                w="full"
                color="white"
                bg="primary.500"
                fontSize="18px"
              >
                {t('withdraw')}
              </Button>
            </Stack>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
