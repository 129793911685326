'use client';

import { Text } from '@chakra-ui/react';
import { useFlowDraftActions, useFlowDraftItem } from '@kiroboio/fct-sdk';
import { useTranslations } from 'next-intl';
import type { FC } from 'react';
import { memo, useCallback } from 'react';

import { DeleteAlert } from '~/lib/components/general/DeleteAlert';
import { useDeleteDraftStateModal } from '~/lib/hooks/useModals/useComplexModals';

const DeleteDraftModal2: FC = () => {
  const t = useTranslations();
  // const { currentDraftId } = useCurrentDraftId();
  // const { snapshot, onClose } = useDeleteDraftModal({
  //   draftId: currentDraftId,
  // });
  // const { isOpen, name: draftName } = snapshot;
  const { isOpen, id, close } = useDeleteDraftStateModal();
  const { item: draft } = useFlowDraftItem({ id });
  const { name: draftName } = draft?.raw || { name: '' };

  const { remove } = useFlowDraftActions({
    id,
  });

  const handleRemove = useCallback(() => {
    remove.execute({});
  }, [remove]);

  const handleClose = useCallback(() => {
    close({ clear: true });
  }, [close]);

  return (
    <DeleteAlert
      isOpen={isOpen}
      onClose={handleClose}
      header={t('delete-project')}
      body={
        <Text>
          {t('are-you-sure-you-want-to-delete')}{' '}
          <Text as="span" fontWeight="semibold">
            {draftName}
          </Text>
        </Text>
      }
      footer={{
        confirm: {
          onClick: handleRemove,
        },
      }}
    />
  );
};

export const DeleteDraftModal = memo(DeleteDraftModal2);
