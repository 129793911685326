/* eslint-disable react/no-unescaped-entities */
import {
  Button,
  Collapse,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useVersionDraftsActions } from '@kiroboio/fct-sdk';
import { useTranslations } from 'next-intl';
import { useCallback, useEffect, useMemo, type FC } from 'react';
import { useForm } from 'react-hook-form';
import { object as yupObject, string as yupString } from 'yup';
import { VersionWhatsNewCard } from '~/app/(dashboard)/marketplace/[id]/components/VersionWhatsNewCard';

import { useChangeVersionReleaseNotesStateModal } from '~/lib/hooks/useModals/useComplexModals';

export const ChangeVersionReleaseNotesModal: FC = () => {
  const t = useTranslations();
  const {
    isOpen,
    id,
    close,
    props: { version, releaseNotes: releaseNotesArr },
  } = useChangeVersionReleaseNotesStateModal();

  const { updateReleaseNotes } = useVersionDraftsActions({
    id,
  });
  const toast = useToast();

  const { isRunning, stage } = updateReleaseNotes.state;
  const isDone = stage === 'done';

  const {
    register,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      releaseNotes: '',
    },
    resolver: yupResolver(
      yupObject().shape({
        releaseNotes: yupString().required(t('whats-new-is-a-required-field')),
      })
    ),
  });

  const releaseNotes = watch('releaseNotes');

  const disabledSubmit = Object.keys(errors).length > 0;

  useMemo(() => {
    if (isDone) {
      updateReleaseNotes.reset();
      close({ clear: true });
    }
  }, [isDone, close, updateReleaseNotes]);

  const cleanup = !isOpen;
  useMemo(() => {
    if (cleanup) {
      reset();
    }
  }, [cleanup, reset]);

  const handleClose = useCallback(() => {
    close({ clear: true });
  }, [close]);

  const update = useCallback(
    async (releaseNotes: string[]) => {
      const returnedData = await updateReleaseNotes.execute({
        releaseNotes,
      });
      // console.log('yui returnedData', returnedData);
      if (returnedData.error) {
        // console.log('returnedData.error', returnedData.error);
        toast({
          position: 'bottom',
          status: 'error',
          title: t('error'),
          description: t('something-went-wrong-please-try-again-later'),
          duration: 9000,
          isClosable: true,
        });
      } else {
        // console.log('success');
        toast({
          position: 'bottom',
          status: 'success',
          title: t('success'),
          description: t('release-note-updated-successfully'),
          duration: 9000,
          isClosable: true,
        });
        handleClose();
      }
    },
    [updateReleaseNotes, toast, t, handleClose]
  );

  const handleUpdate = useCallback(async () => {
    const { releaseNotes } = getValues();
    const releaseNotesToArr = releaseNotes.split('\n');
    update(releaseNotesToArr);
  }, [update, getValues]);

  useEffect(() => {
    if (releaseNotesArr) {
      const releaseNotesString = releaseNotesArr.join('\n');
      setValue('releaseNotes', releaseNotesString);
    }
  }, [releaseNotesArr, setValue]);

  return (
    <Modal
      id="create-version-mode"
      size="6xl"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <ModalOverlay bg="rgba(51,57,66,0.5)" opacity="0.5" />

      <ModalContent
        rounded="3xl"
        bg="#F9FAFB"
        _dark={{ bg: '#0F151A' }}
        p="45px"
      >
        <ModalHeader p="0">
          <HStack w="full" justify="flex-end">
            <ModalCloseButton pos="initial" />
          </HStack>
          <Stack spacing="0" align="center" textAlign="center">
            <Text fontSize="xl">{t('version-whats-new')}</Text>
            {/* <Text fontSize="sm" fontWeight="normal" color="gray.400">
              Every line break will be a bullet point.
            </Text> */}
          </Stack>
        </ModalHeader>
        <ModalBody px="0" py="4">
          <Stack
            as="form"
            onSubmit={handleSubmit(handleUpdate)}
            align="center"
            spacing="10"
          >
            <Stack w="full" spacing={6}>
              <FormControl>
                <FormLabel fontSize="sm" fontWeight="normal" color="gray.400">
                  {t('whats-new-of-version')} {version}
                </FormLabel>
                <HStack gap="30px" alignItems="flex-start">
                  <Textarea
                    fontSize="16px"
                    h="295px"
                    padding="32px"
                    rounded="20px"
                    lineHeight="2"
                    whiteSpace="pre-wrap"
                    overflowWrap="break-word"
                    _dark={{
                      border: '1px',
                      bg: 'gray.900',
                      borderColor: 'gray.800',
                    }}
                    {...register('releaseNotes')}
                  />
                  <VersionWhatsNewCard
                    releaseNotes={releaseNotes.split('\n')}
                  />
                </HStack>
                <Collapse in={Boolean(errors?.releaseNotes)}>
                  <FormHelperText color="orange.500" fontSize="sm" mb="4">
                    {errors?.releaseNotes?.message}
                  </FormHelperText>
                </Collapse>
              </FormControl>

              <Button
                size="lg"
                isLoading={isRunning}
                type="submit"
                isDisabled={disabledSubmit}
                bg="blue.500"
                colorScheme="blue"
                color="white"
                rounded="18px"
                w="max-content"
                alignSelf="center"
              >
                {disabledSubmit ? t('fix-errors') : t('update')}
              </Button>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
