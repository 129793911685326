'use client';

import { useSession } from '@kiroboio/fct-sdk';
import {
  useWeb3Modal,
  useWeb3ModalState,
  useWeb3ModalEvents,
} from '@web3modal/wagmi/react';
import { useCallback } from 'react';
import { useAccount, useDisconnect } from 'wagmi';

import { useUser } from '~/lib/hooks/useUser/useUser';

export const useConnectHandler = () => {
  const { disconnect } = useDisconnect();
  const { open } = useWeb3Modal();
  const { selectedNetworkId, open: isOpen } = useWeb3ModalState();
  const {
    data: { event },
  } = useWeb3ModalEvents();
  const { isConnected } = useAccount();
  const user = useUser();
  const { login } = useSession();

  const connectHandler = useCallback(async () => {
    if (!isConnected) {
      await open({ view: 'Connect' });
    } else if (user.isSignedIn) {
      open({ view: 'Account' });
    } else {
      await login();
      if (user.isSignedIn === false) {
        disconnect();
      }
    }
  }, [isConnected, user.isSignedIn, open, login, disconnect]);
  return {
    connectHandler,
    isConnectModalOpen: isOpen,
    selectedNetworkId,
    connectModalEvent: event,
  };
};
