/* eslint-disable no-nested-ternary */
import {
  Button,
  Collapse,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useVersionDraftsActions } from '@kiroboio/fct-sdk';
import { AddSquare, Additem } from 'iconsax-react';
import { useCallback, useMemo, type FC } from 'react';
import { useForm } from 'react-hook-form';
import { object as yupObject, string as yupString } from 'yup';

import { useTranslations } from 'next-intl';
import { useCreateAppVersionStateModal } from '~/lib/hooks/useModals/useComplexModals';
import { VersionSectionMenu } from '../publisher/VersionSectionMenu';

// const numbersArr = Array.from({ length: 100 }, (_, i) => (i + 1).toString());
const numbersArr = Array.from({ length: 101 }, (_, i) => i.toString());

export const CreateAppVersionModal: FC = () => {
  const t = useTranslations();
  const {
    isOpen,
    id,
    props: { modes, releaseNotes, actAs },
    close,
  } = useCreateAppVersionStateModal();
  const toast = useToast();

  const { create } = useVersionDraftsActions({
    id,
  });

  const { isRunning, stage } = create.state;
  const isDone = stage === 'done';

  const {
    reset,
    getValues,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      major: '',
      minor: '',
      patch: '',
    },
    resolver: yupResolver(
      yupObject().shape({
        major: yupString().required(),
        minor: yupString().required(),
        patch: yupString().required(),
      })
    ),
  });

  const major = watch('major');
  const minor = watch('minor');
  const patch = watch('patch');

  const disabledSubmit = Object.keys(errors).length > 0;

  useMemo(() => {
    if (isDone) {
      create.reset();
      close({ clear: true });
    }
  }, [isDone, close, create]);

  const cleanup = !isOpen;
  useMemo(() => {
    if (cleanup) {
      reset();
    }
  }, [cleanup, reset]);

  const isModesExist = Boolean(modes && modes.length);
  const isReleaseNotesExist = Boolean(releaseNotes && releaseNotes.length);

  const createVersion = useCallback(
    async (version: string) => {
      // console.log('createVersion createVersion', version);
      // console.log('createVersion id', id);
      const returnedData = await create.execute({
        appDraftId: id,
        version,
        modes: isModesExist && modes ? [...modes] : undefined,
        releaseNotes:
          isReleaseNotesExist && releaseNotes ? [...releaseNotes] : undefined,
      });
      // console.log('yui returnedData', returnedData);
      if (returnedData.error) {
        // console.log('returnedData.error', returnedData.error);
        if ((returnedData.error as any).code === 11000) {
          toast({
            position: 'bottom',
            status: 'error',
            title: t('error'),
            description: t('intent-version-already-exists'),
            duration: 9000,
            isClosable: true,
          });
        } else {
          toast({
            position: 'bottom',
            status: 'error',
            title: t('error'),
            description: t('something-went-wrong-please-try-again-later'),
            duration: 9000,
            isClosable: true,
          });
        }
      } else {
        // console.log('success');
        // if (returnedData.results?.id) {
        toast({
          position: 'bottom',
          status: 'success',
          title: t('success'),
          description: t('version-added-successfully'),
          duration: 9000,
          isClosable: true,
        });
        // }
      }
    },
    [
      create,
      id,
      isModesExist,
      modes,
      isReleaseNotesExist,
      releaseNotes,
      toast,
      t,
    ]
  );

  const handleAddVersion = useCallback(async () => {
    const { major, minor, patch } = getValues();
    const version = `${major}.${minor}.${patch}`;
    createVersion(version);
  }, [createVersion, getValues]);

  const handleClose = useCallback(() => {
    close({ clear: true });
  }, [close]);

  return (
    <Modal
      id="change-draft-version"
      motionPreset="slideInBottom"
      size="md"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <ModalOverlay bg="rgba(51,57,66,0.5)" opacity="0.5" />

      <ModalContent
        rounded="3xl"
        bg="#F9FAFB"
        _dark={{ bg: '#0F151A' }}
        p="45px"
      >
        <ModalHeader p="0">
          <HStack w="full" justify="flex-end">
            <ModalCloseButton pos="initial" />
          </HStack>
        </ModalHeader>
        <ModalBody px="0" py="4">
          <Stack
            as="form"
            onSubmit={handleSubmit(handleAddVersion)}
            align="center"
            spacing="10"
          >
            <Stack align="center" textAlign="center">
              <Icon
                as={actAs === 'duplicate' ? Additem : AddSquare}
                variant="Bulk"
                color="primary.500"
                boxSize="68px"
                transition="all 120ms"
              />
              <Stack>
                <Text textTransform="capitalize" fontSize="md">
                  {/* {actAs === 'add' ? t('add-0') : t('duplicate')}{' '}
                  {t('version-0')} */}
                  {actAs === 'duplicate'
                    ? t('duplicate-version')
                    : t('add-version')}
                </Text>
              </Stack>
            </Stack>
            <Stack spacing={4}>
              <FormControl>
                <FormLabel fontSize="sm" fontWeight="normal" color="gray.400">
                  <HStack spacing="5px">
                    <Text>{t('set-version-number')}</Text>
                    <Text fontWeight="700">
                      ({major || 'x'}.{minor || 'y'}.{patch || 'z'})
                    </Text>
                  </HStack>
                </FormLabel>
                <HStack>
                  {/* <Input
                    variant="flushed"
                    size="lg"
                    fontWeight="bold"
                    fontSize="2xl"
                    {...register('major')}
                  /> */}
                  <VersionSectionMenu
                    section={t('major')}
                    title={t('features')}
                    currentNumber={major}
                    numbersArr={numbersArr}
                    onNumberChange={(number: string) => {
                      setValue('major', number);
                      clearErrors('major');
                    }}
                  />
                  <Text fontSize="50px" mt="25px">
                    .
                  </Text>
                  <VersionSectionMenu
                    section={t('minor')}
                    title={t('improvements')}
                    currentNumber={minor}
                    numbersArr={numbersArr}
                    onNumberChange={(number: string) => {
                      setValue('minor', number);
                      clearErrors('minor');
                    }}
                  />
                  <Text fontSize="50px" mt="25px">
                    .
                  </Text>
                  <VersionSectionMenu
                    section={t('patch')}
                    title={t('bug-fixes')}
                    currentNumber={patch}
                    numbersArr={numbersArr}
                    onNumberChange={(number: string) => {
                      setValue('patch', number);
                      clearErrors('patch');
                    }}
                  />
                </HStack>
                <Collapse in={disabledSubmit}>
                  <FormHelperText color="orange.500" fontSize="sm" mb="4">
                    {t('major-minor-and-patch-are-required-fields')}
                  </FormHelperText>
                </Collapse>
              </FormControl>

              <Button
                size="lg"
                isLoading={isRunning}
                leftIcon={actAs === 'duplicate' ? <Additem /> : <AddSquare />}
                type="submit"
                isDisabled={disabledSubmit}
                bg="blue.500"
                colorScheme="blue"
                color="white"
                rounded="18px"
                textTransform="capitalize"
              >
                {disabledSubmit
                  ? t('fix-errors')
                  : actAs === 'duplicate'
                    ? t('duplicate-version')
                    : t('add-version')}
              </Button>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
