import { arbitrum, base, mainnet, optimism, sepolia } from 'wagmi/chains';
import { supportedNetworkIds } from './helpers/flags';

export const walletConnectProjectId = '37be6ada8696369506e50fae146ab957';
const wagmiChains = [mainnet, optimism, base, arbitrum, sepolia];

const networkIds: number[] = JSON.parse(
  supportedNetworkIds || '[1, 10, 8453, 42161, 11155111]'
);
const networkIdsSet = new Set(networkIds);

const chains = wagmiChains.filter((wChain) => networkIdsSet.has(wChain.id));
export type ExcitingChainIds = `${(typeof chains)[number]['id']}`;
export type ChainIds = ExcitingChainIds | '0';

export { chains };
