import {
  Tooltip as ChakraTooltip,
  HStack,
  Square,
  Stack,
  Text,
} from '@chakra-ui/react';
import type { ChartData, ChartOptions } from 'chart.js';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import type { FC } from 'react';
import { useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export type Segment = { label: string; color: string; value: number };
export type Segments = Segment[];

interface IPieChart {
  segments: Segments;
}

const setTextPlugin = (segments: Segments) => ({
  id: 'centerText',
  beforeDraw(chart: any) {
    const { width, height, ctx } = chart;
    ctx.restore();

    // const fontSize = Math.min(height / 16, 12);
    ctx.font = `${18}px sans-serif`;
    ctx.textBaseline = 'middle';

    const largestSegment = segments.reduce(
      (max, segment) => (segment.value > max.value ? segment : max),
      { label: '', color: '', value: -Infinity }
    );
    const text = largestSegment.value === 0 ? '' : largestSegment.label;
    const textWidth = ctx.measureText(text).width;
    const textX = (width - textWidth) / 2;
    const textY = height / 2;

    ctx.fillStyle = largestSegment.color;
    ctx.fillText(text, textX, textY);
    ctx.save();
  },
});

const PieChart: FC<IPieChart> = ({ segments }) => {
  useEffect(() => {
    ChartJS.register(setTextPlugin(segments));
    return () => {
      ChartJS.unregister(setTextPlugin(segments));
    };
  }, [segments]);

  const data: ChartData<'doughnut'> = {
    labels: segments.map((l) => l.label),
    datasets: [
      {
        label: '',
        data: segments.map((v) => v.value),
        backgroundColor: segments.map((c) => c.color),
        hoverOffset: -5,
        borderWidth: 0,
      },
    ],
  };

  const options: ChartOptions<'doughnut'> = {
    cutout: '75%',
    responsive: true,
    layout: {
      autoPadding: true,
    },
    plugins: {
      legend: {
        display: false,
        position: 'right',
        labels: {
          boxWidth: 5,
          padding: 10,
          font: {
            size: 12,
          },
          generateLabels: (chart) => {
            const labels = chart.data.labels as string[];
            const { datasets } = chart.data as ChartData<'doughnut'>;

            if (datasets && datasets.length > 0) {
              return labels.map((label, i) => {
                const total = datasets[0].data.reduce((a, b) => a + b, 0);
                const value = datasets[0].data[i];
                const percentage = ((value / total) * 100).toFixed(0);

                return {
                  text: `${label}: ${value} (${percentage}%)`,
                  fillStyle: Array.isArray(datasets[0]?.backgroundColor)
                    ? datasets[0]?.backgroundColor[i]
                    : undefined,
                  fontColor: 'white',
                };
              });
            }
            return [];
          },
        },
      },
    },
  };

  const total = segments.reduce((acc, { value }) => acc + value, 0);
  const totalSafe = total === 0 ? 1 : total;
  const segmentsWithPercentage = segments.map((segment) => ({
    ...segment,
    value: +((segment.value / totalSafe) * 100).toFixed(1),
  }));

  if (total === 0) return null;

  return (
    <HStack
      h="240px"
      w="305px"
      justifyContent="center"
      alignItems="center"
      spacing="12px"
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '160px',
          margin: 'auto',
        }}
      >
        <Doughnut data={data} options={options} />
      </div>
      <Stack spacing="9px">
        {segments.map((segment, i) => (
          <ChakraTooltip
            key={segment.label}
            label={
              <HStack>
                <Square size="10px" bg={segments[i].color} />
                <Text color={segments[i].color}>{segments[i].label}</Text>
              </HStack>
            }
            placement="bottom-start"
            aria-label="A tooltip"
            bg="blackAlpha.900"
            hasArrow
          >
            <Stack spacing="0">
              <HStack color={segments[i].color}>
                <Text>{segments[i].value.toLocaleString()}</Text>
                <Text fontSize="80%">({segmentsWithPercentage[i].value}%)</Text>
              </HStack>
            </Stack>
          </ChakraTooltip>
        ))}
      </Stack>
    </HStack>
  );
};

export default PieChart;
