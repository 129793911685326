'use client';

import { Suspense } from 'react';
// import GoogleAnalytics from './GoogleAnalytics';
import HotjarAnalytics from './HotjarAnalytics';
import GTMAnalytics from './GTMAnalytics';
import { GTM_ID, HOTJAR_SITE_ID } from '../helpers/flags';

export default function AnalyticsLayout() {
  return (
    <>
      {GTM_ID && (
        <Suspense>
          <GTMAnalytics />
        </Suspense>
      )}
      {HOTJAR_SITE_ID && (
        <Suspense>
          <HotjarAnalytics />
        </Suspense>
      )}
    </>
  );
}
