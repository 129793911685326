import { HStack, Icon, Progress, Stack, Text, Tooltip } from '@chakra-ui/react';
import type { FC, ReactNode } from 'react';
import {
  FaNewspaper,
  FaReddit,
  FaTiktok,
  FaXTwitter,
  FaYoutube,
} from 'react-icons/fa6';
import type { Segments } from './PieChart';

interface IStackedBarChart {
  network: string;
  icon: ReactNode;
  segments: Segments;
}

const StackedBarChart: FC<IStackedBarChart> = ({ network, icon, segments }) => {
  const sortedSegments = segments.sort((a, b) => b.value - a.value);
  const total = sortedSegments.reduce((acc, { value }) => acc + value, 0);
  const totalSafe = total === 0 ? 1 : total;
  const sortedSegmentsWithPercentage = sortedSegments.map((segment) => ({
    ...segment,
    value: +((segment.value / totalSafe) * 100).toFixed(1),
  }));
  return (
    <Tooltip
      label={
        <Stack>
          <Text color="black" _dark={{ color: 'white' }}>
            {network} Sentiment
          </Text>
          {sortedSegmentsWithPercentage.map((segment) => (
            <Text key={segment.label} color={segment.color}>
              {segment.label}: {segment.value}%
            </Text>
          ))}
        </Stack>
      }
      placement="bottom-start"
      aria-label="A tooltip"
      bg="blackAlpha.900"
      hasArrow
    >
      <HStack>
        {icon}

        <Stack spacing="3px" w="full">
          {sortedSegments.map((segment, i) => (
            <Progress
              key={segment.label}
              colorScheme="green"
              sx={{
                '& > div': {
                  background: segment.color,
                },
              }}
              size="xs"
              value={sortedSegmentsWithPercentage[i].value}
              w="200px"
            />
          ))}
        </Stack>

        <Text w="full" color={sortedSegments[0].color}>
          {sortedSegments[0].value.toLocaleString()}
        </Text>
      </HStack>
    </Tooltip>
  );
};

interface IListStackedBarChart {
  networks: Record<string, Segments | undefined>;
}

const ListStackedBarChart: FC<IListStackedBarChart> = ({ networks }) => {
  return (
    <Stack justify="center" h="240px" spacing="12px">
      {networks.twitter && (
        <StackedBarChart
          network="X"
          icon={<Icon as={FaXTwitter} boxSize="20px" />}
          segments={networks.twitter}
        />
      )}
      {networks.youtube && (
        <StackedBarChart
          network="Youtube"
          icon={<Icon as={FaYoutube} color="rgb(255, 0, 0)" boxSize="20px" />}
          segments={networks.youtube}
        />
      )}
      {networks.reddit && (
        <StackedBarChart
          network="Reddit"
          icon={<Icon color="#ff4500" as={FaReddit} boxSize="20px" />}
          segments={networks.reddit}
        />
      )}
      {networks.tiktok && (
        <StackedBarChart
          network="Tiktok"
          icon={<Icon as={FaTiktok} boxSize="20px" />}
          segments={networks.tiktok}
        />
      )}
      {networks.news && (
        <StackedBarChart
          network="News"
          icon={<Icon color="blue.300" as={FaNewspaper} boxSize="20px" />}
          segments={networks.news}
        />
      )}
    </Stack>
  );
};

export default ListStackedBarChart;
