/* eslint-disable no-plusplus */
/* eslint-disable complexity */
import {
  Button,
  Center,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useRouter } from 'next/navigation';

import { useCallback } from 'react';
import { BiExit, BiReceipt } from 'react-icons/bi';

import type { ActiveAppsItemType } from '@kiroboio/fct-sdk';
import {
  BigIntOr0,
  useAppsDefiDataItem,
  useVaultActions,
} from '@kiroboio/fct-sdk';
import { useTranslations } from 'next-intl';
import { useAPYStateModal } from '~/lib/hooks/useModals/useComplexModals';

function getStageIn(run: ActiveAppsItemType['fmt']['run']) {
  // console.log('getStageIn run', run);
  if (!run.length) return undefined;
  if (!run[0].defiData) return undefined;
  return run.find((r) => r.defiData?.stage === 'in');
}

interface APYInfoProps {
  title: string;
  apy: string | number;
  tvlUsd: string | number;
  apy30dAverage: string | number;
  lpPercentage: string | number;
}

export default function APYInfo({
  title,
  apy,
  tvlUsd,
  apy30dAverage,
  lpPercentage,
}: APYInfoProps) {
  const t = useTranslations();
  return (
    <Stack w="full">
      <Text textAlign="center" fontSize="16px" fontWeight="700" ml="16px">
        {title}
      </Text>
      <HStack justifyContent="space-between">
        <Text>{t('total-apy')}</Text>
        <Text fontWeight="700">{apy}%</Text>
      </HStack>
      <HStack justifyContent="space-between">
        <Text>{t('total-value-locked')}</Text>
        <Text fontWeight="700">${tvlUsd}</Text>
      </HStack>
      <HStack justifyContent="space-between">
        <Text>{t('30d-avg-apy')}</Text>
        <Text fontWeight="700">{apy30dAverage}%</Text>
      </HStack>
      <HStack justifyContent="space-between">
        <Text>{t('lp')}</Text>
        <Text fontWeight="700">{lpPercentage}%</Text>
      </HStack>
    </Stack>
  );
}

export const APYModal = () => {
  const t = useTranslations();
  const toast = useToast();
  const navigation = useRouter();

  const {
    isOpen,
    close,
    // props: { enter, appId, exitHandler },
    // props: { activeAppId, appId },
    props: { activeApp, appId },
  } = useAPYStateModal();

  // const { item: app } = useAppItem({ id: appId });
  const { item: defi } = useAppsDefiDataItem({ id: appId });
  // const { item: activeApp } = useActiveAppsItem({ id: activeAppId });

  const lastRunIndex = (activeApp?.raw?.run?.length || 0) - 1;
  const lastRun = lastRunIndex >= 0 ? activeApp.raw.run[lastRunIndex] : null;
  const lastRunTx = lastRun ? lastRun.txhash : null;

  const liveApy = defi?.fmt?.pool.apy24h || 0;
  const liveTvlUsd = defi?.fmt.pool.tvlUsd || 0;
  const liveApy30dAverage = defi?.fmt.pool.apy30d || 0;
  const liveLpPercentage = 0;

  const {
    storage: { writeValue, readValue },
  } = useVaultActions();

  const handleClose = useCallback(() => {
    close({});
  }, [close]);

  const handleGoToExecutionInfo = useCallback(() => {
    handleClose();
    navigation.push(`/app/active/trace/${activeApp.raw.id}?r=${lastRunIndex}`);
  }, [activeApp?.raw?.id, handleClose, lastRunIndex, navigation]);

  const handleExitNow = useCallback(async () => {
    handleClose();
    const returnedRead = await readValue.execute({
      inputs: { key: activeApp.raw.id as `0x${string}` },
    });

    type Read = [
      {
        type: string;
        hex: string;
      },
    ];
    const results = returnedRead?.results as unknown as Read;
    const currentExitNumber = results?.length && results[0]?.hex;

    if (returnedRead.error) {
      console.error('readValue error', returnedRead.error);
      toast({
        position: 'bottom',
        status: 'error',
        title: t('error'),
        description: t('something-went-wrong-please-try-again-later-1'),
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    if (currentExitNumber !== '0x00') {
      console.error('Exit command already sent.');
      toast({
        position: 'bottom',
        status: 'info',
        title: t('status'),
        description: t('exit-command-already-sent'),
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    const returnedWrite = await writeValue.execute([
      {
        inputs: { key: activeApp.raw.id as `0x${string}`, value: BigIntOr0(1) },
      },
    ]);

    if (returnedWrite.error) {
      console.error('writeValue error', returnedWrite.error);
      toast({
        position: 'bottom',
        status: 'error',
        title: t('error'),
        description: t('something-went-wrong-please-try-again-later-1'),
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        position: 'bottom',
        status: 'success',
        title: t('success'),
        description: t('exit-command-is-executing'),
        duration: 9000,
        isClosable: true,
      });
    }
  }, [activeApp?.raw?.id, handleClose, readValue, t, toast, writeValue]);

  const stageIn = getStageIn(activeApp?.fmt?.run || []);

  // console.log('stageIn', stageIn);
  // console.log('activeApp', activeApp);

  const enterApy = stageIn?.defiData?.pool?.apy24h || 0;
  const enterTvlUsd = stageIn?.defiData?.pool?.tvlUsd || 0;
  const enterApy30dAverage = stageIn?.defiData?.pool?.apy30d || 0;
  const enterLpPercentage = 0;

  return (
    <Modal size="4xl" isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay backdropFilter="blur(4px)" bg="whiteAlpha.200" />
      <ModalContent
        rounded="24px"
        bg="#F9FAFB"
        _dark={{ bg: '#0F151A' }}
        p="60px"
        gap="40px"
      >
        <ModalHeader p="0">
          <Center>
            <Text fontSize="24px" fontWeight="700" ml="16px">
              APY Info
            </Text>
          </Center>
        </ModalHeader>

        <ModalCloseButton data-cy="close-power-modal" m="16px" />

        <ModalBody fontSize="sm" px="0" color="gray.500">
          <Stack gap="40px" spacing="0">
            <HStack gap="80px" spacing="0">
              <APYInfo
                title="Live"
                apy={liveApy}
                tvlUsd={liveTvlUsd}
                apy30dAverage={liveApy30dAverage}
                lpPercentage={liveLpPercentage}
              />
              <APYInfo
                title="In"
                apy={enterApy}
                tvlUsd={enterTvlUsd}
                apy30dAverage={enterApy30dAverage}
                lpPercentage={enterLpPercentage}
              />
            </HStack>
            <HStack justifyContent="center">
              <Button
                onClick={handleGoToExecutionInfo}
                colorScheme="blue"
                variant="outline"
                w="180px"
                leftIcon={<Icon as={BiReceipt} boxSize="18px" />}
                isDisabled={!lastRunTx}
              >
                Execution Info
              </Button>
              <Button
                onClick={handleExitNow}
                colorScheme="blue"
                w="180px"
                leftIcon={<Icon as={BiExit} boxSize="18px" />}
                isDisabled={!stageIn}
              >
                Exit Now
              </Button>
            </HStack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
