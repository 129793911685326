/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Button,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useVault } from '@kiroboio/fct-sdk';
import { useTranslations } from 'next-intl';
import { useCallback, useEffect, useState, type FC } from 'react';
import { RiDiscordFill, RiTwitterXFill } from 'react-icons/ri';
import { useConnectHandler } from '~/lib/hooks/general/useConnectHandler';
import {
  missingOnChainWalletId,
  useAirdropStateModal,
  useMissingOnChainWalletModalActions,
} from '~/lib/hooks/useModals/useComplexModals';
import { useUser } from '~/lib/hooks/useUser/useUser';
import { SocialButton } from '../Marketplace/SocialButton/SocialButton';

interface IAirdropSteps {
  isSigningIn: boolean;
}

const AirdropStep0 = ({ isSigningIn }: IAirdropSteps) => {
  const t = useTranslations();

  const { connectHandler } = useConnectHandler();

  const handleConnect = useCallback(() => {
    connectHandler();
  }, [connectHandler]);

  return (
    <Stack alignItems="center" h="87px" spacing="0" gap="32px">
      <Text fontWeight="400" fontSize="14px">
        {t('please-connect-your-wallet')}
      </Text>
      <Button
        isLoading={isSigningIn}
        onClick={handleConnect}
        size="lg"
        colorScheme="blue"
        bg="blue.400"
        color="white"
        rounded="full"
        p="8px 24px"
        fontSize="18px"
        fontWeight="600"
        textTransform="capitalize"
        type="submit"
      >
        {t('connect-wallet')}
      </Button>
    </Stack>
  );
};

const AirdropStep1 = ({ isSigningIn }: IAirdropSteps) => {
  const t = useTranslations();
  const { open: openMissingOnChainWallet } =
    useMissingOnChainWalletModalActions(missingOnChainWalletId);

  const handleOpenMissingOnChainWallet = useCallback(() => {
    openMissingOnChainWallet({});
  }, [openMissingOnChainWallet]);

  return (
    <Stack alignItems="center" h="87px" spacing="0" gap="32px">
      <Text fontWeight="400" fontSize="14px">
        {t('meantime-create-your-runner')}
      </Text>
      <Button
        isLoading={isSigningIn}
        onClick={handleOpenMissingOnChainWallet}
        size="lg"
        colorScheme="blue"
        bg="blue.400"
        color="white"
        rounded="full"
        p="8px 24px"
        fontSize="18px"
        fontWeight="600"
        textTransform="capitalize"
        type="submit"
      >
        {t('create-one')}
      </Button>
    </Stack>
  );
};

const AirdropStep2 = ({ isSigningIn }: IAirdropSteps) => {
  return null;
};

interface IAirdropStepper {
  step: number;
  isSigningIn: boolean;
}

const AirdropStepper = ({ step, isSigningIn }: IAirdropStepper) => {
  switch (step) {
    case 0:
      return <AirdropStep0 isSigningIn={isSigningIn} />;
    case 1:
      return <AirdropStep1 isSigningIn={isSigningIn} />;
    case 2:
      return <AirdropStep2 isSigningIn={isSigningIn} />;
    default:
      return null;
  }
};

export const AirdropModal: FC = () => {
  const t = useTranslations();
  const {
    isOpen,
    close,
    props: { title, subtitle },
  } = useAirdropStateModal();

  const { open: openMissingOnChainWallet } =
    useMissingOnChainWalletModalActions(missingOnChainWalletId);

  const handleOpenMissingOnChainWallet = useCallback(() => {
    openMissingOnChainWallet({
      title,
      subtitle,
    });
  }, [openMissingOnChainWallet, subtitle, title]);

  const vault = useVault();
  const { hasVault } = vault?.data?.raw || {
    hasVault: false,
  };

  const [step, setStep] = useState(0);

  const { isNotConnected, isSigningIn, isSignedIn } = useUser();

  const handleClose = useCallback(() => {
    close({});
  }, [close]);

  useEffect(() => {
    if (isNotConnected) {
      setStep(0);
    } else if (!hasVault) {
      setStep(1);
    } else {
      setStep(2);
    }
  }, [handleClose, hasVault, isSignedIn, isNotConnected]);

  if (isOpen && !isNotConnected && !hasVault) {
    handleOpenMissingOnChainWallet();
    handleClose();
  }

  return (
    <Modal size="xl" isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay backdropFilter="blur(4px)" bg="whiteAlpha.200" />
      <ModalContent
        rounded="20px"
        bg="#F9FAFB"
        _dark={{ bg: '#0F151A' }}
        p="54px"
        zIndex="0"
      >
        <ModalCloseButton m="2" />
        <ModalBody px="0">
          <Stack alignItems="center" spacing="0" gap="32px">
            <Stack spacing="0" gap="16px">
              <Text fontWeight="700" fontSize="36px">
                {title}
              </Text>
            </Stack>

            <AirdropStepper step={step} isSigningIn={isSigningIn} />

            <Stack alignItems="center" spacing="0" gap="16px">
              <Text fontWeight="600" fontSize="16px">
                {t(
                  'check-out-our-social-media-channels-for-the-latest-updates'
                )}
              </Text>
              <HStack spacing="16px">
                <SocialButton
                  icon={<Icon as={RiDiscordFill} boxSize="18px" />}
                  name="Discord"
                  link="https://discord.com/invite/NrQTjjwwvq"
                />
                <SocialButton
                  icon={<Icon as={RiTwitterXFill} boxSize="18px" />}
                  name="TwitterX"
                  link="https://twitter.com/Intentable_io"
                />
              </HStack>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
