import {
  Badge,
  Button,
  DarkMode,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import type { FC } from 'react';
import { useLayoutEffect, useRef, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { useFCTStore } from '~/lib/components/builder/Provider/FCTStore';
import { useFCTCustomizeInputOutputDetailsModal } from '~/lib/stores/modalStore';

export const FCTCustomizeGroupParameterModal: FC = (props) => {
  const t = useTranslations();
  const initialFocusRef = useRef<HTMLInputElement>(null);

  const { meta, ...modal } = useFCTCustomizeInputOutputDetailsModal();
  const { details, setDetails } = useFCTStore(
    (state) => ({
      details: state.getCustomizedGroupParameter(
        meta.groupId,
        meta.nodeId,
        meta.handleId || ''
      ),
      setDetails: state.setCustomizedGroupParameter.bind(
        null,
        meta.groupId,
        meta.nodeId,
        meta.handleId || ''
      ),
    }),
    shallow
  );

  const [label, setLabel] = useState(details?.label);
  const [description, setDescription] = useState(details?.description);

  useLayoutEffect(() => {
    setLabel(details?.label);
    setDescription(details?.description);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta]);

  const parameter = useFCTStore.getState().intentGraph?.getParam({
    id: meta.nodeId,
    handle: meta.handleId || '',
    handleType: meta.type?.toUpperCase() as 'INPUT' | 'OUTPUT',
  })?.value?.jsonParam;

  if (!meta.nodeId || !meta.handleId) return null;
  if (!parameter) return null;

  const handleSubmit = (ev: React.FormEvent) => {
    ev.preventDefault();
    setDetails({
      label,
      description,
    });
    modal.onClose();
  };

  return (
    <DarkMode>
      <Modal
        initialFocusRef={initialFocusRef}
        size="lg"
        {...props}
        {...modal}
        onClose={() => {
          modal.onClose();
          setLabel('');
          setDescription('');
        }}
      >
        <ModalOverlay bg="rgba(51,57,66,0.5)" />
        <ModalContent
          as="form"
          onSubmit={handleSubmit}
          rounded="3xl"
          color="white"
          bg="#0F151A"
          p="24px"
        >
          <ModalHeader>
            <Text>
              {meta.type === 'input'
                ? t('customize-input')
                : t('customize-output')}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack gap="4">
              <HStack
                pos="relative"
                bg="whiteAlpha.100"
                p="2"
                px="4"
                rounded="xl"
                w="fit-content"
              >
                <Text fontSize="14px">{parameter.label}</Text>
                <Badge
                  title={parameter.uiType}
                  maxWidth={64}
                  fontSize="10px"
                  colorScheme={meta.type === 'input' ? 'purple' : 'green'}
                >
                  <Text noOfLines={1}>{parameter.uiType}</Text>
                </Badge>
              </HStack>
              <FormControl>
                <Stack spacing="0">
                  <FormLabel>
                    {t('custom-label')}
                    <Text fontSize="xs" color="gray.500">
                      {t('if-empty-the-default-label-will-be-used')} (
                      {parameter.label})
                    </Text>
                  </FormLabel>
                </Stack>
                <Input
                  ref={initialFocusRef}
                  rounded="xl"
                  value={label}
                  onInput={(ev) => setLabel(ev.currentTarget.value)}
                  placeholder="Enter a label"
                />
              </FormControl>

              <FormControl>
                <Stack spacing="0">
                  <FormLabel>
                    {t('description')}
                    <Text fontSize="xs" color="gray.500">
                      {t('describe-what-this-parameter-is-used-for')}
                    </Text>
                  </FormLabel>
                </Stack>
                <Textarea
                  rounded="xl"
                  rows={5}
                  value={description}
                  onInput={(ev) => setDescription(ev.currentTarget.value)}
                  placeholder={t('enter-a-description')}
                />
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={modal.onClose}>
              {t('cancel')}
            </Button>
            <Button type="submit" mr={3}>
              {t('save')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </DarkMode>
  );
};
