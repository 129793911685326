'use client';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react';
import type React from 'react';
import { useDeviceSelectors } from 'react-device-detect';
import { WagmiConfig } from 'wagmi';

import { Chakra as ChakraProvider } from '~/lib/components/Chakra';
import { Plugins } from '~/lib/components/Plugins';
import { Service } from '~/lib/components/Service';
import { ModalsLayout } from '~/lib/layout/ModalsLayout';
import { chains, walletConnectProjectId } from '~/lib/wagmi';

import { useSession } from '@kiroboio/fct-sdk';
import { Next13ProgressBar } from 'next13-progressbar';
import { useEffect, useRef, useState } from 'react';
import { SplashScreen } from '~/lib/components/general/SplashScreen';
import { noSpalshScreen } from '~/lib/helpers/flags';
import { Initializer } from './initializer';
import { IntlProvider } from './intlProvider';
import { OnBoarding } from '~/lib/layout/OnBoarding';

const metadata = {
  name: 'Intentable',
  description: 'Intentable',
  url: 'https://app.intentable.io',
  icons: ['https://avatars.githubusercontent.com/u/37784886'],
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});

const wagmiConfig = defaultWagmiConfig({
  chains,
  projectId: walletConnectProjectId,
  metadata,
});

createWeb3Modal({
  wagmiConfig,
  projectId: walletConnectProjectId,
  chains,
  themeVariables: {
    '--w3m-z-index': 9999,
  },
});

const Providers = ({ children }: { children: React.ReactNode }) => {
  const [{ isMobile, isTablet }] = useDeviceSelectors(
    window.navigator.userAgent
  );
  const { status, connectionState } = useSession();

  const initLoading = connectionState !== 'authorized' || status === 'idle';
  const [loading, setLoading] = useState(initLoading);

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (initLoading) {
      timeoutRef.current = setTimeout(() => {
        setLoading(false);
      }, 7000);
    } else {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
      setLoading(false);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };
  }, [initLoading]);

  let fullsize = '100%';
  let transform = 'translate(-50%, -50%)';
  if (isMobile) {
    fullsize = isTablet ? '125%' : '167%';
    transform = isTablet ? 'translate(-40%, -40%)' : 'translate(-30%, -30%';
  }

  return (
    <QueryClientProvider client={queryClient}>
      <WagmiConfig config={wagmiConfig}>
        <IntlProvider>
          <Initializer />
          <Service />
          <Plugins />
          {noSpalshScreen || (
            <div
              style={{
                width: fullsize,
                height: fullsize,
                position: 'fixed',
                background: 'black',
                zIndex: 9999,
                opacity: loading ? 1 : 0,
                pointerEvents: loading ? 'all' : 'none',
                transition: 'opacity 0.8s',
                transitionDelay: '1s',
              }}
            >
              <div
                style={{
                  position: 'fixed',
                  top: '50%',
                  left: '50%',
                  scale: '1.5',
                  transform,
                  color: 'white',
                  background: 'transparent',
                }}
              >
                <SplashScreen />
              </div>
            </div>
          )}
          {isMobile ? (
            <div className={isTablet ? 'tablet-view' : 'mobile-view'}>
              <ChakraProvider>
                <ModalsLayout />
                <OnBoarding />
                {children}
                <Next13ProgressBar
                  height="6px"
                  color="#3182CE"
                  options={{ showSpinner: false }}
                  showOnShallow
                />
              </ChakraProvider>
            </div>
          ) : (
            <ChakraProvider>
              <ModalsLayout />
              <OnBoarding />
              {children}
              <Next13ProgressBar
                height="6px"
                color="#3182CE"
                options={{ showSpinner: false }}
                showOnShallow
              />
            </ChakraProvider>
          )}
        </IntlProvider>
      </WagmiConfig>
    </QueryClientProvider>
  );
};

export default Providers;
