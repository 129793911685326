import { useNetwork, useProviders, useWallet } from '@kiroboio/fct-sdk';
import { useMemo } from 'react';
import { SupportedPlugins } from './builder/Provider/SupportedPlugins';
import type { ChainIds } from '../wagmi';
import type { ApiService } from '@kiroboio/fct-service';
import { useCurrentMutableState as useRef } from '../hooks/useCurrentMutableState';

export const Plugins = () => {
  const {
    data: {
      raw: { chainId: chainIdNum },
    },
  } = useNetwork();
  const {
    data: {
      raw: { address: account },
    },
  } = useWallet();
  const chainId: ChainIds = chainIdNum.toString() as ChainIds;
  const { fork: testProvider } = useProviders();
  const testProviderService = {
    create: async (params: any) => {
      const res = await testProvider.execute(params);
      return res.results;
    },
  } as unknown as ApiService;

  const testProviderServiceRef = useRef(testProviderService);
  useMemo(() => {
    if (chainId === '0') return;
    SupportedPlugins.setDefaultPlugins({ chainId });
    SupportedPlugins.start({
      chainId,
      account,
      testProviderService: testProviderServiceRef.current,
    });

    return () => SupportedPlugins.stop();
  }, [chainId, account]);

  return null;
};
