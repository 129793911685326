'use client';

import type { StackProps } from '@chakra-ui/react';
import {
  Button,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
} from '@chakra-ui/react';
import { TickCircle } from 'iconsax-react';
import { type FC } from 'react';
import { ChevronDown } from 'react-feather';
import { TextOrShortened } from '~/lib/components/general/TextOrShortened';

export const VersionSectionMenu: FC<
  StackProps & {
    title: string;
    section: string;
    currentNumber: string;
    numbersArr: string[];
    onNumberChange: (nextOrderBy: string) => void;
  }
> = (props) => {
  const { onNumberChange, section, title, currentNumber, numbersArr } = props;
  return (
    <Stack>
      <TextOrShortened
        text={title}
        maxLength={20}
        textAlign="center"
        color="blue.500"
      />
      <Menu>
        <MenuButton
          as={Button}
          rightIcon={<Icon as={ChevronDown} />}
          size="lg"
          rounded="10px"
          colorScheme="gray"
          // _hover={{ bg: 'gray.900' }}
          // _active={{ bg: 'gray.900' }}
          w="120px"
        >
          {currentNumber || section}
        </MenuButton>
        {/* <MenuList maxHeight="300px" overflowY="scroll" bg="gray.900"> */}
        <MenuList maxHeight="300px" overflowY="scroll">
          {numbersArr.map((number) => (
            <MenuItem
              as={HStack}
              key={number}
              value={number}
              onClick={(e) => {
                e.stopPropagation();
                onNumberChange(number);
              }}
              // bg="gray.900"
              // _hover={{ bg: 'gray.800' }}
              justifyContent="space-between"
            >
              <TextOrShortened text={number} maxLength={3} />
              {number === currentNumber ? (
                <Icon as={TickCircle} variant="Bold" />
              ) : undefined}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Stack>
  );
};
