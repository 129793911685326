/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Button, HStack, Icon, Link, Stack, Text } from '@chakra-ui/react';
import type { ReactNode } from 'react';
import { Plus } from 'react-feather';
// import { mixpanelAnalytics } from 'src/kirobo/utils/mixpanelAnalytics';

interface TokenCardProps {
  disabled?: boolean;
  isRunning: boolean;
  // mode: 'vault' | 'wallet';
  buttonText: string;
  buttonOnClick: () => void;
  info: string;
  token: string;
  amount: string;
  icon: ReactNode;
  iconBtn: ReactNode;
}

export const TokenCard = ({
  disabled,
  isRunning,
  // mode,
  buttonText,
  buttonOnClick,
  info,
  token,
  amount,
  icon,
  iconBtn,
}: TokenCardProps) => {
  return (
    <HStack opacity={disabled ? '0.2' : 'none'} justify="space-between">
      <HStack gap="10px" spacing="0">
        <Box w="25px">{icon}</Box>
        <Stack spacing="0">
          <Text fontWeight="400" fontSize="14px">
            {info}
          </Text>
          <Text
            color="black"
            _dark={{ color: 'white' }}
            fontWeight="600"
            fontSize="16px"
          >
            {amount} {token}
          </Text>
        </Stack>
      </HStack>
      <Button
        data-cy="wallet-power-action"
        isLoading={isRunning}
        isDisabled={disabled}
        _disabled={{ bg: disabled && 'none' }}
        cursor={disabled ? 'not-allowed' : 'pointer'}
        onClick={buttonOnClick}
        colorScheme="gray"
        fontSize="14px"
        fontWeight="600"
        h="32px"
        w="115px"
        gap="8px"
      >
        {iconBtn} {buttonText}
      </Button>
    </HStack>
  );
};

interface CombinedCardProps {
  disabled?: boolean;
  isRunning: boolean;
  buttonText: string;
  buttonOnClick: () => void;
}

export const CombinedCard = ({
  disabled,
  isRunning,
  buttonText,
  buttonOnClick,
}: CombinedCardProps) => {
  return (
    <HStack opacity={disabled ? '0.2' : 'none'} justify="space-between">
      <HStack gap="10px" spacing="0">
        <Stack spacing="0">
          <Text fontWeight="400" fontSize="14px">
            Two wallets availability{' '}
          </Text>
        </Stack>
      </HStack>
      <Button
        data-cy="wallet-power-add"
        isLoading={isRunning}
        isDisabled={disabled}
        _disabled={{ bg: disabled && 'none' }}
        cursor={disabled ? 'not-allowed' : 'pointer'}
        onClick={buttonOnClick}
        colorScheme="gray"
        fontSize="14px"
        fontWeight="600"
        h="32px"
        w="105px"
        gap="8px"
      >
        <Icon as={Plus} boxSize="12px" /> {buttonText}
      </Button>
    </HStack>
  );
};

interface BuyMoreProps {
  disabled?: boolean;
  buttonText: string;
  token: string;
  buyUrl?: string;
  icon: ReactNode;
}

export const BuyMore = ({
  disabled,
  buttonText,
  buyUrl,
  token,
  icon,
}: BuyMoreProps) => {
  return (
    <HStack
      opacity={disabled ? '0.2' : 'none'}
      h="45px"
      justify="space-between"
    >
      <HStack gap="10px" spacing="0">
        {icon}
        <Stack spacing="0">
          <Text color="white" fontWeight="500" fontSize="14px">
            Buy more {token}
          </Text>
        </Stack>
      </HStack>
      <Link href={disabled ? '' : buyUrl} isExternal>
        <Button
          // onClick={() => {
          //   mixpanelAnalytics.track({
          //     label: 'Click Add Buy Token',
          //   });
          // }}
          isDisabled={disabled}
          _disabled={{ bg: disabled && '#161C23', color: disabled && 'white' }}
          _hover={{
            bg: disabled ? 'none' : 'blue.300',
            cursor: disabled ? 'not-allowed' : 'pointer',
          }}
          color="white"
          bg="#161C23"
          fontSize="14px"
          fontWeight="600"
          h="32px"
          w="165px"
          gap="8px"
        >
          <Icon as={Plus} boxSize="12px" /> {buttonText}
        </Button>
      </Link>
    </HStack>
  );
};
