'use client';

import { Text } from '@chakra-ui/react';
import { useExpiredFlowActions, useExpiredFlowItem } from '@kiroboio/fct-sdk';
import { useTranslations } from 'next-intl';
import type { FC } from 'react';
import { memo, useCallback } from 'react';

import { DeleteAlert } from '~/lib/components/general/DeleteAlert';
import { useDeleteExpiredStateModal } from '~/lib/hooks/useModals/useComplexModals';

const DeleteExpiredModal2: FC = () => {
  const t = useTranslations();
  // const { currentExpiredId } = useCurrentExpiredId();
  // const { snapshot, onClose } = useDeleteExpiredModal({
  //   expiredId: currentExpiredId,
  // });
  // const { isOpen, name: expiredName } = snapshot;

  const { isOpen, id, close } = useDeleteExpiredStateModal();

  // const expiredFlowList = useExpiredFlowList();
  // const expiredFlow = expiredFlowList.map[id];
  const { item: expiredFlow } = useExpiredFlowItem({ id });

  const expiredFlowName: string =
    expiredFlow?.raw?.data?.typedData?.message?.meta?.name?.toString() || '';

  const { remove } = useExpiredFlowActions({
    id,
  });

  const handleRemove = useCallback(() => {
    remove.execute({});
  }, [remove]);

  const handleClose = useCallback(() => {
    close({ clear: true });
  }, [close]);

  return (
    <DeleteAlert
      isOpen={isOpen}
      onClose={handleClose}
      header={t('delete-expired-intent')}
      body={
        <Text>
          {t('are-you-sure-you-want-to-delete')}{' '}
          <Text as="span" fontWeight="semibold">
            {expiredFlowName}
          </Text>
        </Text>
      }
      footer={{
        confirm: {
          onClick: handleRemove,
        },
      }}
    />
  );
};

export const DeleteExpiredModal = memo(DeleteExpiredModal2);
