import { extendTheme } from '@chakra-ui/react';

import { colors } from '~/lib/styles/theme/colors';
import { components } from '~/lib/styles/theme/components';
import { config } from '~/lib/styles/theme/config';
import { fonts } from '~/lib/styles/theme/fonts';

const customTheme = extendTheme({
  fonts,
  colors,
  config,
  components,
});

export default customTheme;
