import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { useCallback } from 'react';

import { useTestStateModal } from '~/lib/hooks/useModals/useComplexModals';

export const TestModal = () => {
  // const {
  //   onClose,
  //   snapshot: { isOpen },
  // } = useTestModal();
  const { isOpen: isOpenTest, close } = useTestStateModal();

  const handleClose = useCallback(() => {
    close({});
  }, [close]);

  return (
    <Modal
      isOpen={isOpenTest}
      onClose={handleClose}
      isCentered
      motionPreset="slideInBottom"
    >
      <ModalOverlay backdropFilter="blur(4px)" bg="whiteAlpha.200" />
      <ModalContent w="auto" rounded="24px" p="60px" maxW="full" bg="#080B0E">
        <ModalBody p="0">
          <ModalCloseButton m="16px" />
          <Stack>fdgfdgdfg</Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
