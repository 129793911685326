import {
  Button,
  Collapse,
  HStack,
  Icon,
  Input,
  LightMode,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFlowDraftActions, useFlowDraftItem } from '@kiroboio/fct-sdk';
import { useTranslations } from 'next-intl';
import { memo, useCallback, useMemo, type FC } from 'react';
import { Save } from 'react-feather';
import { useForm } from 'react-hook-form';
import { object as yupObject, string as yupString } from 'yup';

import { FeatherFCTDiagram } from '~/assets/tsx/FeatherFCTDiagram';
import { useRenameDraftStateModal } from '~/lib/hooks/useModals/useComplexModals';

const RenameDraftModal2: FC = () => {
  const t = useTranslations();
  // const { currentDraftId } = useCurrentDraftId();
  // const { snapshot, onClose } = useRenameDraftModal({
  //   draftId: currentDraftId,
  // });
  // const { isOpen, createdAt, updatedAt, name: draftName } = snapshot;

  const { isOpen, id, close } = useRenameDraftStateModal();
  const { item: draft } = useFlowDraftItem({ id });
  const {
    createdAt,
    updatedAt,
    name: draftName,
  } = draft?.raw || { name: '', createdAt: '', updatedAt: '' };

  const { patch } = useFlowDraftActions({ id });

  const { isRunning, stage } = patch.state;
  const isDone = stage === 'done';

  const {
    register,
    setValue,
    watch,
    formState: {
      errors: { name: nameError },
    },
    handleSubmit,
  } = useForm({
    defaultValues: {
      name: draftName,
    },
    resolver: yupResolver(
      yupObject().shape({
        name: yupString().required(),
      })
    ),
  });

  useMemo(() => {
    if (isOpen) {
      setValue('name', draftName);
    }
  }, [isOpen, setValue, draftName]);

  useMemo(() => {
    if (isDone) {
      patch.reset();
      close({ clear: true });
    }
  }, [isDone, close, patch]);

  const name = watch('name');

  const onSubmit = () => {
    patch.execute({ name });
  };

  const handleClose = useCallback(() => {
    close({ clear: true });
  }, [close]);

  return (
    <Modal
      id="change-draft-name"
      motionPreset="slideInBottom"
      size="lg"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <ModalOverlay bg="rgba(51,57,66,0.5)" opacity="0.5" />

      <ModalContent
        rounded="3xl"
        bg="#F9FAFB"
        _dark={{ bg: '#0F151A' }}
        p="45px"
      >
        <ModalHeader p="0">
          <HStack w="full" justify="flex-end">
            {/* <Text fontWeight="semibold" fontSize="md">
                  Change name
                </Text> */}
            <ModalCloseButton pos="initial" />
          </HStack>
        </ModalHeader>
        <ModalBody px="0" py="4">
          <HStack align="flex-start" spacing="10">
            <Stack p="6" align="center" justify="center" rounded="xl">
              <Icon
                as={FeatherFCTDiagram}
                boxSize="54px"
                transition="all 120ms"
              />
            </Stack>
            <Stack>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Input
                  variant="flushed"
                  size="lg"
                  fontWeight="bold"
                  fontSize="2xl"
                  {...register('name')}
                />
              </form>
              <Collapse in={Boolean(nameError)}>
                <Text color="orange.500" fontSize="sm" mb="4">
                  {nameError?.message}
                </Text>
              </Collapse>
              <Stack color="gray.400">
                <Text noOfLines={1} fontSize="xs">
                  {t('updated')}: {updatedAt}
                </Text>
                <Text noOfLines={1} fontSize="xs">
                  {t('created')}: {createdAt}
                </Text>
              </Stack>
            </Stack>
          </HStack>
        </ModalBody>

        <ModalFooter px="0" pb="0">
          <LightMode>
            <Button
              isLoading={isRunning}
              bg="blue.500"
              colorScheme="blue"
              rounded="18px"
              leftIcon={<Save />}
              onClick={handleSubmit(onSubmit)}
            >
              {nameError ? t('fix-errors') : t('save')}
            </Button>
          </LightMode>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export const RenameDraftModal = memo(RenameDraftModal2);
