/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-underscore-dangle */

import { providers, service, useSession } from '@kiroboio/fct-sdk';
import { getWalletClient, switchNetwork, watchWalletClient } from '@wagmi/core';
import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
import { useDisconnect } from 'wagmi';
import { serviceKey, serviceSecret, serviceUrl } from '~/lib/helpers/flags';
import { chains } from '~/lib/wagmi';

type Payload = {
  method: string;
  params: Array<any>;
};

class ServiceProvider extends providers.JsonRpcProvider {
  _action: (payload: Payload) => Promise<any>;

  _chainId: number;

  constructor(chainId: number) {
    super('ipc://service', chainId);
    this._action = async () => {};
    this._chainId = chainId;
  }

  clone() {
    const copy = new ServiceProvider(this._chainId);
    copy.setAction(this._action);
    return copy;
  }

  setAction(action: (payload: Payload) => Promise<any>) {
    this._action = action;
  }

  send(method: string, params: Array<any>): Promise<any> {
    return this._action({ method, params });
  }
}

service.formatting.setValueFormatter((params) => {
  let result = service.formatting.prebuild.formatValue({
    ...params,
    // format: '0,.00',
  });
  if (result.endsWith('.0')) {
    result = result.slice(0, -2);
  }
  return result;
});

service.formatting.setAddressFormatter((params) => {
  if (params.service === 'tokens') {
    service.formatting.prebuild.formatAddress({ ...params, icap: true });
    return params.address.toLowerCase();
  }
  return service.formatting.prebuild.formatAddress(params);
});

service.formatting.setDateTimeFormatter((params) => {
  if (
    (params.service === 'fct/active' && params.name === 'expires_at') ||
    params.name === 'vaild_from'
  ) {
    return service.formatting.prebuild.formatDateTime({
      ...params,
      relative: false,
    });
  }
  if (params.service === 'fct/drafts' && params.name === 'updatedAt') {
    return service.formatting.prebuild.formatDateTime({
      ...params,
      relative: true,
    });
  }
  return service.formatting.prebuild.formatDateTime(params);
});
export const Service = () => {
  const searchParams = useSearchParams();

  const networkId = Number(searchParams.get('n'));
  let defaultChainId: number;
  if (
    networkId &&
    !Number.isNaN(networkId) &&
    chains.some((chain) => networkId === chain.id)
  ) {
    defaultChainId = networkId;
  }

  const url =
    searchParams.get('url-local-test') ||
    serviceUrl ||
    'https://testapi.kirobo.me';
  const forkMain = searchParams.get('fork') === 'main';

  const { status } = useSession();
  const { disconnect } = useDisconnect();

  useEffect(() => {
    if (status === 'loggedOut') {
      disconnect();
    }
  }, [status, disconnect]);

  useEffect(() => {
    const initiate = async () => {
      service.start({
        url,
        key: serviceKey || 'kirobo',
        secret: serviceSecret || 'kirobodev',
        forkMain,
        defaultChainId,
      });

      // service.formatting.setValueFormatter((params) => {
      //   // let result = service.formatting.prebuild.formatValue({
      //   //   ...params,
      //   //   // format: '0,.00',

      //   // });

      //   console.log('formatting value1', params.service, params);
      //   // if (result.endsWith('.0')) {
      //   //   result = result.slice(0, -2);
      //   // }
      //   return '0';
      // });

      // service.formatting.setAddressFormatter((params) => {
      //   // if (params.service === 'apps') {
      //   console.log(params.service, 'formatting address', params);
      //   //   service.formatting.prebuild.formatAddress({ ...params, icap: true });
      //   //   return params.address.toLowerCase();
      //   // }
      //   // return service.formatting.prebuild.formatAddress(params);
      //   return '123';
      // });

      const config = async (
        client: {
          transport: any;
          getChainId: () => any;
          account: { address: any };
        } | null
      ) => {
        const transport = client?.transport;
        const chainId = await client?.getChainId();
        const address = client?.account.address;
        const signer =
          transport && chainId && address
            ? new providers.Web3Provider(transport, chainId).getSigner(address)
            : null;

        const web3ProviderFactory = () => {
          return transport && chainId && address
            ? new providers.Web3Provider(transport, chainId)
            : null;
        };

        try {
          if (signer && defaultChainId && chainId !== defaultChainId) {
            await switchNetwork({ chainId: defaultChainId });
            return;
          }
        } catch (e) {
          console.log('switch network error: ', e);
        }

        service.config({
          signer,
          web3ProviderFactory,
          serviceProviderFactory: new ServiceProvider(chainId),
          autoLogin: true,
        });
        // console.log(
        //   '123123 config',
        //   chainId,
        //   address,
        //   service.session.data.status
        // );
        // if (service.session.data.status === 'idle') {
        // await service.session.login({ useSigner: true });
        // }
      };

      const walletClient = await getWalletClient();
      if (walletClient) {
        await config(walletClient);
      }
      // service.session.login();
      watchWalletClient({}, config);
    };

    initiate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
