import { makeFeatherIcon } from './SVG24X24';

export const FeatherFCTDiagram = makeFeatherIcon(
  <>
    <path
      d="M9.11035 16.2726C12.4016 16.2726 11.8387 8.02393 14.9069 8.02393"
      stroke="#0095FF"
      strokeWidth="0.425015"
      strokeDasharray="0.51 1.11"
    />
    <rect
      x="0.592773"
      y="9.52637"
      width="8.50031"
      height="8.50031"
      rx="1.19004"
      fill="#0095FF"
      stroke="none"
    />
    <rect
      x="1.7832"
      y="10.7163"
      width="6.12022"
      height="0.785519"
      rx="0.392759"
      fill="white"
      stroke="none"
    />
    <rect
      x="1.7832"
      y="12.3521"
      width="3.68126"
      height="0.785519"
      rx="0.392759"
      fill="white"
      stroke="none"
    />
    <rect
      x="6.31445"
      y="12.3521"
      width="1.58893"
      height="0.785519"
      rx="0.392759"
      fill="white"
      stroke="none"
    />
    <rect
      x="1.7832"
      y="13.9873"
      width="6.12022"
      height="0.785519"
      rx="0.392759"
      fill="white"
      stroke="none"
    />
    <rect
      x="1.7832"
      y="15.623"
      width="3.14845"
      height="0.785519"
      rx="0.392759"
      fill="white"
      stroke="none"
    />
    <rect
      x="14.9072"
      y="6.29346"
      width="8.50031"
      height="8.50031"
      rx="1.19004"
      fill="#0095FF"
      stroke="none"
    />
    <rect
      x="16.0977"
      y="7.4834"
      width="6.12022"
      height="0.785519"
      rx="0.392759"
      fill="white"
      stroke="none"
    />
    <rect
      x="16.0977"
      y="9.11914"
      width="3.68126"
      height="0.785519"
      rx="0.392759"
      fill="white"
      stroke="none"
    />
  </>
);
