import { buildApprovedNamespaces } from '@walletconnect/utils';

import { chains } from '~/lib/wagmi';

export const getChainsForWalletConnect = () => {
  return chains.map((chain) => `eip155:${chain.id}`);
};

export const getAccountsForWalletConnect = (account: string) => {
  return chains.map((chain) => `eip155:${chain.id}:${account}`);
};

type Proposal = Parameters<typeof buildApprovedNamespaces>[0]['proposal'];
export const createNamespacesForWalletConnect = (
  account: string,
  params: Proposal
) =>
  buildApprovedNamespaces({
    proposal: params,
    supportedNamespaces: {
      eip155: {
        chains: getChainsForWalletConnect(),
        methods: [
          'eth_sendTransaction',
          'personal_sign',
          'eth_sign',
          'personal_sign',
          'eth_signTypedData',
          'eth_signTypedData_v4',
        ],
        events: ['accountsChanged', 'chainChanged'],
        accounts: getAccountsForWalletConnect(account as string),
      },
    },
  });
