import type { StackProps } from '@chakra-ui/react';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Stack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import MDEditor from '@uiw/react-md-editor';
import { Select } from 'chakra-react-select';
import type { FC } from 'react';
import { useForm } from 'react-hook-form';
// import rehypeSanitize from 'rehype-sanitize';
import * as yup from 'yup';

import { MediaInput, MediaUploader } from './MediaUploader';

const schema = yup.object().shape({
  appName: yup.string().required('Intent name is required'),
  description: yup.string().required('Description is required'),
  categoriesAndTags: yup
    .array()
    .of(yup.string())
    .min(1, 'At least one category or tag is required')
    .required('At least one category or tag is required'),
  featuredVideo: yup.mixed(),
  featuredPictures: yup.array().of(yup.mixed()),
});

export const MarketplaceAppBuilder: FC<StackProps> = (props) => {
  const {
    register,
    // handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const images = (watch('featuredPictures') || []) as (File | null)[];
  const video = watch('featuredVideo') as File | null;
  const description = watch('description');

  return (
    <Stack gap="10" flex="1" h="full" w="800px" maxW="full" {...props}>
      <Heading size="lg">Publish Intent</Heading>
      <Stack as="form" gap="6">
        <FormControl isInvalid={errors.appName}>
          <FormLabel m="0">Intent name</FormLabel>
          <FormHelperText m="0" mb="2">
            This is the name of your intent
          </FormHelperText>
          <Input variant="filled" type="text" {...register('appName')} />
          <FormErrorMessage>{errors.appName?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.description}>
          <FormLabel m="0">Description</FormLabel>
          <FormHelperText m="0" mb="2">
            This is the description of your intent
          </FormHelperText>
          {/* <Textarea rows={10} {...register('description')} /> */}
          <MDEditor
            // previewOptions={{
            //   rehypePlugins: [rehypeSanitize],
            // }}
            value={description}
            style={{
              outline: errors.description
                ? '2px solid var(--chakra-colors-red-300)'
                : '2px solid transparent',
            }}
            onChange={(value) =>
              setValue('description', value, { shouldValidate: true })
            }
          />
          <FormErrorMessage>{errors.description?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.categoriesAndTags}>
          <FormLabel m="0">Categories and tags</FormLabel>
          <FormHelperText m="0" mb="2">
            Select the categories and tags that best describe your intent
          </FormHelperText>
          <Select
            variant="filled"
            tagVariant="solid"
            options={[
              {
                label: 'Trading',
                value: 'i-am-purple',
                colorScheme: 'blue',
              },
              {
                label: 'Automation',
                value: 'Automation',
                colorScheme: 'orange', // The option color scheme overrides the global
              },
              {
                label: 'Gaming',
                value: 'i-am-red',
                colorScheme: 'red', // The option color scheme overrides the global
              },
            ]}
            isMulti
            {...register('categoriesAndTags')}
            onChange={(newValue) => {
              // console.log('newValue:', newValue);
              setValue(
                'categoriesAndTags',
                newValue.map((option) => option.value),
                {
                  shouldValidate: true,
                }
              );
            }}
          />
          <FormErrorMessage>
            {errors.categoriesAndTags?.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.featuredVideo}>
          <FormLabel m="0">Featured video</FormLabel>
          <FormHelperText m="0" mb="2">
            This is the video that will be shown on the marketplace
          </FormHelperText>
          <MediaInput
            type="video"
            file={video}
            onFileChange={(file) => setValue('featuredVideo', file)}
          />
          <FormErrorMessage>{errors.featuredVideo?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.featuredPictures}>
          <FormLabel m="0">Featured images</FormLabel>
          <FormHelperText m="0" mb="2">
            These are the images that will be shown on the marketplace
          </FormHelperText>
          <MediaUploader
            maxUploads={4}
            mediaFiles={images}
            onFilesChange={(files) => setValue('featuredPictures', files)}
          />
          <FormErrorMessage>
            {errors.featuredPictures?.message}
          </FormErrorMessage>
        </FormControl>

        <Button size="lg" type="submit">
          Submit
        </Button>
      </Stack>
    </Stack>
  );
};
