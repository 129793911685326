/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import type { BoxProps } from '@chakra-ui/react';
import {
  Box,
  Center,
  Flex,
  Icon,
  IconButton,
  Image,
  Input,
  Text,
} from '@chakra-ui/react';
import type { FC } from 'react';
import { useMemo, useRef } from 'react';
import { X } from 'react-feather';
import { AiOutlineVideoCameraAdd } from 'react-icons/ai';
import { BsImage } from 'react-icons/bs';
import { FaVideo } from 'react-icons/fa';
import { LuImagePlus } from 'react-icons/lu';

interface MediaInputProps {
  onFileChange: (file: File | null) => void;
  file: File | null;
  type: 'image' | 'video';
}

const MediaPreview: FC<{ file: File } & BoxProps> = ({ file, ...props }) => {
  const isVideo = file?.type.includes('video');
  const url = useMemo(() => URL.createObjectURL(file), [file]);

  return (
    <Box
      {...props}
      pos="relative"
      w="full"
      h="full"
      rounded="xl"
      overflow="hidden"
    >
      <Flex
        gap="2"
        align="center"
        pos="absolute"
        m="5px"
        bg="gray.100"
        _dark={{
          bg: 'gray.900',
        }}
        px="6px"
        py="1px"
        rounded="md"
        bottom={0}
        left={0}
      >
        <Icon as={isVideo ? FaVideo : BsImage} color="cyan.500" boxSize="3" />
        <Text fontSize="xs">{isVideo ? 'Video' : 'Image'}</Text>
      </Flex>
      {isVideo ? (
        <Box boxSize="full" as="video" src={url} objectFit="cover" controls>
          <track kind="captions" />
        </Box>
      ) : (
        <Image
          w="full"
          h="full"
          src={url}
          alt="uploaded media"
          objectFit="cover"
        />
      )}
    </Box>
  );
};

export const MediaInput: React.FC<MediaInputProps> = ({
  onFileChange,
  file,
  type,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onFileChange(e.target.files?.[0] || null);
  };

  const handlePlaceholderClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <Box boxSizing="border-box" position="relative" boxSize="160px">
      {file ? (
        <MediaPreview boxSize="full" rounded="xl" file={file} />
      ) : (
        <Center
          cursor="pointer"
          boxSize="full"
          border="2px dashed"
          borderColor="gray.200"
          rounded="xl"
          onClick={handlePlaceholderClick}
          sx={{
            '&:hover .media-uploader-icon': {
              transform: 'scale(1.1)',
            },
          }}
        >
          <Icon
            className="media-uploader-icon"
            as={type === 'image' ? LuImagePlus : AiOutlineVideoCameraAdd}
            boxSize="40px"
            strokeWidth="1.5px"
            transition="transform 180ms"
          />
        </Center>
      )}
      <Input
        type="file"
        onChange={handleFileChange}
        hidden
        ref={fileInputRef}
        accept={type === 'image' ? 'image/*' : 'video/*'}
      />
      {file && (
        <IconButton
          position="absolute"
          variant="ghost"
          size="xs"
          right="5px"
          top="5px"
          cursor="pointer"
          aria-label="Remove file"
          icon={<Icon as={X} />}
          onClick={() => {
            if (fileInputRef.current) {
              fileInputRef.current.value = '';
            }
            onFileChange(null);
          }}
        />
      )}
    </Box>
  );
};

interface MediaUploaderProps {
  onFilesChange: (files: (File | null)[]) => void;
  mediaFiles: (File | null)[];
  maxUploads: number;
}

export const MediaUploader: React.FC<MediaUploaderProps> = ({
  onFilesChange,
  mediaFiles,
  maxUploads,
}) => {
  const handleFileChange = (file: File | null, index: number) => {
    const newFiles = [...mediaFiles];
    newFiles[index] = file;
    onFilesChange(newFiles.sort((a, b) => (b ? 1 : -1)));
  };

  const maxUploadsArray = new Array(maxUploads).fill(null) as (File | null)[];

  return (
    <Flex gap={4} wrap="wrap">
      {maxUploadsArray.map((file, i) => (
        <MediaInput
          type="image"
          key={i}
          onFileChange={(file) => handleFileChange(file, i)}
          file={mediaFiles[i]}
        />
      ))}
    </Flex>
  );
};
